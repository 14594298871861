<div class="textAlign">
  <a routerLink="/" class="text-dark mb-5">
   <!-- {{'EXPERT.Home'|translate}} -->
   {{homeExpert}}
   <!-- חזרה לדף הבית -->

  </a>

  <div class="row">
    <div class="col-md-12">
      <h4 class="meetexperts-title mt-4"> 
        <!-- {{'EXPERT.MeetOurExperts'|translate}} -->
        הכירו את המומחים שלנו 
      </h4>
      <p class="meetexperts-subtitle my-3">
        <!-- {{'EXPERT.ShowingExperts'|translate}}  -->
        מציג מומחים 
        {{ startFrom }} - {{ endTo }} 
        <!-- {{ totalRecords }} -->
      </p>
      <div class="row">
        <div
          class="col-md-3 my-3 border-light"
          *ngFor="
            let item of expertList
              | paginate
                : {
                    itemsPerPage: pageSize,
                    currentPage: currentPage,
                    totalItems: totalRecords
                  }
          "
        >
          <div class="card card-263 h-100">
            <div class="card-img-top border-0">
              <a [routerLink]="['/meet-experts/details', item.full_name]">
                <img [src]="item.image" class="img-fluid" alt="" height="192" />
                <img
                  *ngIf="!item.image"
                  src="assets/images/no-image.png"
                  class="img-fluid"
                  width="100%"
                  style="height: 216px;"
                />
              </a>
            </div>
            <div class="card-body">
              <h5 class="meetexperts-card-title">{{ item.full_name }}</h5>
              <div class="">
                <p class="mb-1 card-description">{{ item.profession }}</p>
              </div>
            </div>

            <div class="card-footer bg-white border-0 pt-1">
              <button
                class="btn meetexperts-darkblue-btn btn-rounded mb-3"
                [routerLink]="['/expert', item.full_name]"
              >
              <!-- {{'EXPERT.ReadMore'|translate}} -->
              קרא עוד 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="border-top border-bottom mt-4 p-1 text-center"
    *ngIf="expertList?.length > 0"
  >
    <pagination-controls
      (pageChange)="pageChanged($event)"
      previousLabel=""
      nextLabel=""
    ></pagination-controls>
  </div>
  <div class="row" style="height: 100px;"></div>

  <app-why-buy-from-us></app-why-buy-from-us>
</div>
