<div class="toggle">
  <!-- <div class="toggle_heading" (click)="setActive.emit()">  </div> -->
  <h5
    class="mb-0 icon-toggle p-2"
    style="border-bottom: 1px solid rgba(0,0,0,.125)"
  >
    <a class="" (click)="setActive.emit()">
      <strong> {{ title }}</strong>
    </a>
  </h5>
  <div *ngIf="active" class="toggle_content">
    <ng-content></ng-content>
  </div>
</div>
