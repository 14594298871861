<div class="my-cart-dropdown">
<div ngbDropdown [placement]="'bottom-left'" #MyCart="ngbDropdown" class="">
  <div class="row pointer" ngbDropdownToggle>
    <!-- Desktop View -->
    <div class="d-none d-sm-none d-md-block">
      <div class="d-flex p-1">
        <div class="">
          <!-- <i class="fa fa-shopping-cart fa-2x pull-right text-lightblue"></i> -->
          <!-- <img src="assets/images/icons/cart-img.png" alt="" /> -->
          <button class="btn text-uppercase btn-my-cart" > 
            <!-- {{ "CART.MyCart" | translate 11111}} -->
            <img src="assets/svg/002-add-to-cart.svg" alt="" />
            העגלה שלי 
            <span class="item_count_cart"> 
              {{ cartItem?.items?.length > 0 ? cartItem?.items.length : 0 }}
            </span>
          </button>
        </div>

        <!-- <div class="">
          <h6 class="font-montserrat-ExtraBold mb-0 mt-1 mx-2">
            {{ "HEADER.MyCart" | translate }}
          </h6>
          <p class="text-dark font-montserrat-bold mx-2">
            {{ cartItem?.items?.length > 0 ? cartItem?.items.length : 0 }}
            {{ "HEADER.Items" | translate }}
            <span class="font-montserrat-ExtraBold">
              {{ cartItem?.sub_total ? cartItem?.sub_total : 0 }}
              <i class="fa fa-shekel"></i>
            </span>
          </p>
        </div> -->


      </div>
    </div>
    <!-- Desktop View -->

    <!-- Tab View -->
    <div class="d-none d-sm-block d-md-none">
      <div class="d-flex p-1">
        <div class="">
          <!-- <i class="fa fa-shopping-cart fa-2x pull-right text-lightblue"></i> -->
          <!-- <img src="assets/images/icons/cart-img.png" alt="" /> -->
          <button class="btn text-uppercase btn-my-cart" > 
            <img src="assets/svg/002-add-to-cart.svg" alt="" />
            העגלה שלי 
            <span class="item_count_cart">
              {{ cartItem?.items?.length > 0 ? cartItem?.items.length : 0 }}
            </span>
          </button>
        </div>

        <!-- <div class="">
          <h6 class="font-montserrat-ExtraBold mb-0 mt-1 mx-2">
            {{ "HEADER.MyCart" | translate }}
          </h6>
          <p class="text-dark font-montserrat-bold mx-2">
            {{ cartItem?.items?.length > 0 ? cartItem?.items.length : 0 }}
            {{ "HEADER.Items" | translate }}
            <span class="font-montserrat-ExtraBold">
              {{ cartItem?.sub_total ? cartItem?.sub_total : 0 }}
              <i class="fa fa-shekel"></i>
            </span>
          </p>
        </div> -->


      </div>
    </div>
    <!-- Tab View -->
    
    <!-- Mobile and Tablet View -->
    
    <div class="d-block d-sm-none d-md-none" style="text-align: center;">
      <!-- <div class="d-flex">
        <div class="mr-1">
          <span class="item_count">
            {{ cartItem?.items?.length > 0 ? cartItem?.items.length : 0 }}
          </span>

          <img src="assets/images/icons/cart-img.png" alt="" class="img-fluid" />
          <br />

          <span class="header-cart-price-480p">
            <i class="fa fa-shekel"></i>
            {{ cartItem?.sub_total ? cartItem?.sub_total : 0 }}
          </span>
          <img src="assets/images/icons/cart-img.png" alt="" class="d-none img-fluid" />
        </div>
      </div> -->
      <div class="d-flex p-1">
        <div class="">
          <!-- <i class="fa fa-shopping-cart fa-2x pull-right text-lightblue"></i> -->
          <!-- <img src="assets/images/icons/cart-img.png" alt="" /> -->
          <button class="btn text-uppercase btn-my-cart" >
             <!-- {{ "CART.MyCart" | translate }} -->
             <img src="assets/svg/002-add-to-cart.svg" alt="" />
             העגלה שלי 
            <span class="item_count_cart">
              {{ cartItem?.items?.length > 0 ? cartItem?.items.length : 0 }}
            </span>
          </button>
        </div>

        <!-- <div class="">
          <h6 class="font-montserrat-ExtraBold mb-0 mt-1 mx-2">
            {{ "HEADER.MyCart" | translate }}
          </h6>
          <p class="text-dark font-montserrat-bold mx-2">
            {{ cartItem?.items?.length > 0 ? cartItem?.items.length : 0 }}
            {{ "HEADER.Items" | translate }}
            <span class="font-montserrat-ExtraBold">
              {{ cartItem?.sub_total ? cartItem?.sub_total : 0 }}
              <i class="fa fa-shekel"></i>
            </span>
          </p>
        </div> -->


      </div>
    </div>

    <!-- Mobile and Tablet View -->

    <div class="cart-popup" ngbDropdownMenu>
      <div class="arrow-up-cart"></div>
      <div *ngIf="cartItem?.items?.length > 0">
        <div class="p-2" style="max-height: 330px; overflow-y: auto; overflow-x: hidden;">
          <div class="row border-bottom" *ngFor="let item of cartItem?.items; let i = index">
            <span class="with-arrow"></span>          

            <div class="col-md-12">
              <div class="d-flex my-2">
                <div class="cart-product-thumbnail ml-3">
                  <img *ngIf="item.image" src="{{ baseurl }}{{ item.image }}" class="img-fluid" />
                  <img *ngIf="!item.image" src="assets/images/no-image.png" class="img-fluid" />
                </div>
                <div>
                  <div class="d-flex">
                    <div><a class="cart-product-title" [routerLink]="['/products/details/', item.item_code,item.item_name]">
                      <span class="mb-1">{{ item.item_name }}</span>
                    </a></div>
                  </div>
                  <div class="d-flex justify-content-between bottom-line" style="min-height: 47px;">
                    <div class="align-self-end">
                      <span class="cart-product-price-blue">{{
                        item.amount
                        }} <span class="cart-product-price-icon">
                          <!-- {{ "CURRENCY_LOGO.value" | translate }}  -->
                          ₪
                        </span>
                      </span>
                    </div>
                    <div class="align-self-end">
                      <button id="add_qty" (click)="changeQty(item.qty, item, i,'plus')" href="javascript:void(0)">
                        <span class="lnr lnr-plus-circle fa-1x cart-icons"></span>
                      </button>
                      <label class="cart-item-qty mx-2">{{ item.qty }}</label>
    
                      <button id="remove_qty" [disabled]="item.qty == 1" (click)="changeQty(item.qty, item, i,'mins')"
                        href="javascript:void(0)"><span class="lnr lnr-circle-minus fa-1x cart-icons"></span>
                      </button>
                    </div>
                    <div class="align-self-end">
                      <span class="pointer float-right"><a class="text-red-trash" (click)="removeCart(item, i)">
                        <span class="lnr lnr-trash fa-1x"></span> </a></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>

        <div class="total-amount-box pt-2">
          <div class="col-md-12" style="padding: 10px 20px 0px 20px;">
           
            <div class="d-flex justify-content-center" style="width: 100%;">
              <div class="">
                <div class="cart-total-text pull-right">
                  <!-- <strong> {{ "HEADER.Total" | translate }}</strong> -->
                  <strong class="text-primary"> {{ cartItem?.sub_total }} <span>
                     <!-- {{ "CURRENCY_LOGO.value" | translate }}  -->
                     ₪
                    </span> </strong>  
                  <span> 
                    <!-- Total Cost  -->
                    סך הכל 
                  </span>
                </div>
              </div>

              <div class="">
                <div class="pull-left cart-total-amount">
                  | {{ cartItem?.items?.length > 0 ? cartItem?.items.length : 0 }} 
                  פריטים בעגלה שלך

                  <!-- items in your cart  -->
                  </div>
              </div>

            </div>

            <div class="">
              <div>
                <button class="view-chkout-btn view-co-btn text-uppercase my-3 btn-rounded" (click)="closeDropdown(MyCart);goToCheckout('yes',true)"><!----- Added By Ashwin - 10/5/2022 - Added closeDropdown(MyCart); on click ----->
                  <!-- {{ "CART.Checkout" | translate ssssss }} -->
                  <!--לבדוק-->לקופה    
                </button>
                <button (click)="closeDropdown(MyCart)" class="view-chkout-btn view-cs-btn text-uppercase my-3 btn-rounded">
                  <!-- {{ "CART.ContinueShopping" | translate }} -->
                  המשך בקניות 

                </button> 
                <p style="margin-bottom:0px !important"> תעריף המשלוח יחושב  בסיום הרכישה</p>
                <!-- <p style="margin-bottom:0px !important">{{ "CART.Delivery" | translate }}</p> -->
               
              </div>
            </div>
           
          </div>
          <div class="col-md-12">
           
          </div>
        </div>
      </div>
      <div class="p-4" *ngIf="cartItem?.items?.length == 0 || cartItem?.items?.length < 1">
        <!-- {{ "HEADER.NoItem" | translate }} -->
        לא נמצא פריט בעגלת הקניות שלך
      </div>
    </div>

  </div>
</div>
</div>

<div class="my-proposal-dropdown">
  <div ngbDropdown  [placement]="'bottom-right'" #MyProposal="ngbDropdown" class="">
    <div class="row pointer" ngbDropdownToggle>

      <!-- Desktop View -->
      <div class="d-none d-sm-none d-md-block">
        <div class="d-flex p-1">
          <div class="">
            <!-- <i class="fa fa-shopping-cart fa-2x pull-right text-lightblue"></i> -->
            <!-- <img src="assets/images/icons/cart-img.png" alt="" /> -->
            <button class="btn text-uppercase btn-proposal"> 
              <img src="assets/svg/contract.svg" alt="" />
              <!-- {{ "CART.MyProposal" | translate }} -->
              הצעת מחיר
              <span class="item_count_proposal">
                {{ proposalData?.items?.length > 0 ? proposalData?.items.length : 0 }}
              </span>
            </button>
          </div>
  
          <!-- <div class="">
            <h6 class="font-montserrat-ExtraBold mb-0 mt-1 mx-2">
              {{ "HEADER.MyCart" | translate }}
            </h6>
            <p class="text-dark font-montserrat-bold mx-2">
              {{ cartItem?.items?.length > 0 ? cartItem?.items.length : 0 }}
              {{ "HEADER.Items" | translate }}
              <span class="font-montserrat-ExtraBold">
                {{ cartItem?.sub_total ? cartItem?.sub_total : 0 }}
                <i class="fa fa-shekel"></i>
              </span>
            </p>
          </div> -->
  
  
        </div>
      </div>
      <!-- Desktop View -->

      <!-- Tab View -->
      <div class="d-none d-sm-block d-md-none">
        <div class="d-flex p-1">
          <div class="">
            <!-- <i class="fa fa-shopping-cart fa-2x pull-right text-lightblue"></i> -->
            <!-- <img src="assets/images/icons/cart-img.png" alt="" /> -->
            <button class="btn text-uppercase btn-proposal">
              <img src="assets/svg/contract.svg" alt="" />
              <!-- {{ "CART.MyProposal" | translate }} -->
              הצעת מחיר
              <span class="item_count_proposal">
                {{ proposalData?.items?.length > 0 ? proposalData?.items.length : 0 }}
              </span>
            </button>
          </div>
  
          <!-- <div class="">
            <h6 class="font-montserrat-ExtraBold mb-0 mt-1 mx-2">
              {{ "HEADER.MyCart" | translate }}
            </h6>
            <p class="text-dark font-montserrat-bold mx-2">
              {{ cartItem?.items?.length > 0 ? cartItem?.items.length : 0 }}
              {{ "HEADER.Items" | translate }}
              <span class="font-montserrat-ExtraBold">
                {{ cartItem?.sub_total ? cartItem?.sub_total : 0 }}
                <i class="fa fa-shekel"></i>
              </span>
            </p>
          </div> -->
  
  
        </div>
      </div>
      <!-- Tab View -->
  
      <!-- Mobile and Tablet View -->
      <div class="d-block d-sm-none d-md-none" style="text-align: end;">
        
        <div class="d-flex p-1">
          <div class="">
            <!-- <i class="fa fa-shopping-cart fa-2x pull-right text-lightblue"></i> -->
            <!-- <img src="assets/images/icons/cart-img.png" alt="" /> -->
            <button class="btn text-uppercase btn-proposal">
              <img src="assets/svg/contract.svg" alt="" />
               <!-- {{ "CART.MyProposal" | translate }} -->
               הצעת מחיר
              <span class="item_count_proposal">
                {{ proposalData?.items?.length > 0 ? proposalData?.items.length : 0 }}
              </span>
            </button>
          </div>
  
          <!-- <div class="">
            <h6 class="font-montserrat-ExtraBold mb-0 mt-1 mx-2">
              {{ "HEADER.MyCart" | translate }}
            </h6>
            <p class="text-dark font-montserrat-bold mx-2">
              {{ cartItem?.items?.length > 0 ? cartItem?.items.length : 0 }}
              {{ "HEADER.Items" | translate }}
              <span class="font-montserrat-ExtraBold">
                {{ cartItem?.sub_total ? cartItem?.sub_total : 0 }}
                <i class="fa fa-shekel"></i>
              </span>
            </p>
          </div> -->
  
  
        </div>
        <!-- <div class="d-flex">
          <div class="mr-1">
            <span class="item_count">
              {{ proposalData?.items?.length > 0 ? proposalData?.items.length : 0 }}
            </span>
  
            <img src="assets/images/icons/cart-img.png" alt="" class="img-fluid" />
            <br />
  
            <span class="header-cart-price-480p">
              <i class="fa fa-shekel"></i>
              {{ proposalData?.sub_total ? proposalData?.sub_total : 0 }}
            </span>
            <img src="assets/images/icons/cart-img.png" alt="" class="d-none img-fluid" />
          </div>
        </div> -->
      </div>
      <!-- Mobile and Tablet View -->

      <div class="proposal-popup" ngbDropdownMenu>
        <div class="arrow-up-proposal"></div>
        <div *ngIf="proposalData?.items?.length > 0">
          <div class="p-2" style="max-height: 330px; overflow-y: auto; overflow-x: hidden;">
            <div class="row border-bottom" *ngFor="let item of proposalData?.items; let i = index">
              <span class="with-arrow"></span>          
  
              <div class="col-md-12">
                <div class="d-flex my-2">
                  <div class="cart-product-thumbnail ml-3"><img *ngIf="item.image" src="{{ baseurl }}{{ item.image }}" class="img-fluid" />
                    <img *ngIf="!item.image" src="assets/images/no-image.png" class="img-fluid" /></div>
                  <div>
                    <div class="d-flex">
                      <div><a class="cart-product-title" [routerLink]="['/products/details/', item.item_code,item.item_name]">
                        <span class="mb-1">{{ item.item_name }}</span>
                      </a></div>
                    </div>
                    <div class="d-flex justify-content-between bottom-line" style="min-height: 47px;">
                      <div class="align-self-end">
                        <span class="cart-product-price-blue">{{
                          item.amount
                          }} <span class="cart-product-price-icon">
                            <!-- {{ "CURRENCY_LOGO.value" | translate }} -->
                            ₪
                          </span>
                        </span>
                      </div>
                      <div class="align-self-end">
                        <button id="add_qty" (click)="changeQtyProposal(item.qty, item, i,'plus')" href="javascript:void(0)">
                          <span class="lnr lnr-plus-circle fa-1x cart-icons"></span>
                        </button>
                        <label class="cart-item-qty mx-2">{{ item.qty }}</label>
      
                        <button id="remove_qty" [disabled]="item.qty == 1" (click)="changeQtyProposal(item.qty, item, i,'mins')"
                          href="javascript:void(0)"><span class="lnr lnr-circle-minus fa-1x cart-icons"></span>
                        </button>
                      </div>
                      <div class="align-self-end">
                        <span class="pointer float-right"><a class="text-red-trash" (click)="removeCartProposal(item, i)">
                          <span class="lnr lnr-trash fa-1x"></span> </a></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
  
  
            </div>
          </div>
  
          <div class="total-amount-box pt-2">
            <div class="col-md-12" style="padding: 10px 20px 0px 20px;">
             
              <div class="d-flex justify-content-center" style="width: 100%;">
                <div class="">
                  <div class="cart-total-text pull-right">
                    <!-- <strong> {{ "HEADER.Total" | translate }}</strong> -->
                    <strong class="text-primary"> {{ proposalData?.sub_total }} <span> 
                      <!-- {{ "CURRENCY_LOGO.value" | translate }}  -->
                      ₪
                    </span> </strong>  
                    <span> 
                      <!-- Total Cost  -->

                      סך הכל 
                    </span>
                  </div>
                </div>
  
                <div class="">
                  <div class="pull-left cart-total-amount">
                    | {{ proposalData?.items?.length > 0 ? proposalData?.items.length : 0 }} 
                    <!-- items in your cart  -->
                    פריטים בעגלה שלך
                    </div>
                </div>
  
              </div>
  
              <div class="">
                <div>
                  <button class="view-chkout-btn view-co-btn text-uppercase my-3 btn-rounded" (click)="goToCheckout('no',false)">
                    <!-- {{ "PRICEINGREQUEST.send" | translate }} -->
                    שלח בקשה 
                  </button>
                  <button (click)="closeDropdown(MyProposal)" class="view-chkout-btn view-cs-btn text-uppercase my-3 btn-rounded">
                    <!-- {{ "CART.ContinueShopping" | translate }} -->
                    המשך בקניות
                  </button> 
                  <p style="margin-bottom:0px !important">
                    <!-- {{ "CART.Delivery" | translate }} -->
                    תעריף המשלוח יחושב  בסיום הרכישה
                  </p>
                </div>
              </div>
             
            </div>
            <div class="col-md-12">
             
            </div>
          </div>
        </div>
        <div class="p-4" *ngIf="proposalData?.items?.length == 0 || proposalData?.items?.length < 1">
          <!-- {{ "HEADER.NoProposals" | translate }} -->
          לא נמצא פריט בהצעות שלך
        </div>
      </div>
  
    </div>
  </div>
  <!-- <a
    (click)="goToCheckout()"
    class="btn btn-warning text-uppercase btn-checkout"
    >{{ "HEADER.Checkout" | translate }}</a
  >  -->
</div>