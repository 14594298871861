<div class="row">
  <div class="col-10">
    <div class="text-md-right text-center">
      <p>
        בס"ד  
      </p>
      <p class="mb-4">
        <strong>
          ברוכים הבאים לפליי פאנקשן!
        </strong>
      </p>
      <p  class="mb-4">
אנו מייבאים ישירות מהיבואן לצרכן משחקים חדשניים, יצירתיים ומעוררי השראה, מתוצרת מיטב החברות המובילות בתחום ובאיכות גבוהה במיוחד.
<strong> מה שהופך אותנו לבוטיק המשחקים האיכותי בישראל. </strong> 
      </p>
      <p class="mb-4">  
        לאורך השנה אנו נוסעים לרחבי העולם, בוחרים לכם בקפידה משחקים מקוריים ומייבאים לכם אותם באהבה. אנו מוסיפים כל העת לרפטואר מלאי של משחקים חדשים.
        <strong>  אף פעם לא ישעמם לכם תמיד תוכלו להוסיף לקליניקה או לבית משחק שעדיין אין לכם. </strong> 
      </p>
      <p class="mb-4">
        <strong>
          במהלך השנים בררנו מה גורם לכם להיעצר, להתהפנט, להיות קשובים ולרצות עוד... בדקנו מה גורם לכם ללכת אחרינו, לסמוך עלינו, לצחוק איתנו, ובשורה התחתונה להתיישב לשחק במשחק טוב... </strong>
      </p>
      <p>
        'פליי פאנקשן' הוא שם נרדף לעולמות מלאים בתוכן לילדים ולתינוקות אנחנו מסייעים למורים והורים על ידי מתן כלים מיוחדים ומחברים הורים וילדים על ידי משחק טוב...  
      </p>
      <P class="mb-4">
        אצלנו תמצאו משחקים וערכות לשיפור ההוראה המספקים חוויית למידה מרתקת העונה לצרכים האישיים של כל ילד, בדגש על עבודה טיפולית, קוגניטיבית ורגשית.
      </P>
      <p  class="mb-4">המוצרים המגוונים שלנו כוללים משחקים ופעילות לימודית ערכית, אמצעי המחשה ומשחקים הבונים מיומנויות יסוד באמצעות חקירה, הנאה, דמיון וכיף. 
        <strong>. ויותר מכל, אנו גאים להציע שירות ידידותי, יעיל מכל הלב!
        </strong>
      </p>
      <p>
        <strong>
          אנחנו נהנים לעטוף את החבילות שלכם ולדמיין כמה תתרגשו כאשר תקבלו אותן...  הצוות של 'פליי פאנקשן' קשוב לצרכים שלכם ומתאים את המשחק בדיוק עבורכם...</strong>
      </p>
      <p  class="mb-4">
        <strong>
          הורים, סבים וסבתות, אם אתם מעוניינים להשקיע וליצור חוויית למידה מיוחדת בתוך המשפחה חשוב לנו שתדעו שהמוטו שלנו הוא להשקיע בלמידה באהבה! אנו מאמינים שחינוך יכול לשנות את חייו של ילד.</strong>
      </p>
      <p>
        הצוות שלנו אוהב ללמוד ואנו בטוחים שגם אתם. </p>
      <p>
        אנו מזמינים אתכם לשאול אותנו כל שאלה, והצוות המקצועי שלנו ישמח לסייע ולענות  לכם.
      </p>
      <P>
        נשמח לספק לכם תמיכה ולעמוד לרשותכם - לא רק בבחירת המשחקים אלא גם אחרי עריכת הרכישה אצלנו.
      </P>
      <p>
        בין לקוחותינו המרוצים: 
        <strong> קלינאיות תקשורת, מרפאות בעיסוק, פסיכולוגיות ואנשי מקצוע בתחום הטיפול,</strong>
        כמו כן גם גננות והורים. נסו אותנו (:
      </p>
    </div>
    <div
    class="sign">
      <p>
        <strong>להתראות!</strong>
      </p>
      <p>
        הצוות של פליי פאנקשן ישראל.
      </p>
    </div>
  </div>
</div>

