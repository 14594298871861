<div class="card border-0">
    <!-- <div class="card-body text-center preview"> -->
    <!-- <div class="preview-pic tab-content">
      <div *ngIf="!isDisplayVideo" class="product-img-container">
        <img *ngIf="currentImage" src="{{ baseURL }}{{ currentImage }}" class="img-fluid product-img-container"
          (mousemove)="onMouseMove($event)" (mouseleave)="onMouseLeave($event)" />
        <img *ngIf="!itemDetails?.image" src="assets/images/no-image.png" />
      </div>
      <div *ngIf="isDisplayVideo">
        <video *ngIf="!isYoutubeVideo" [src]="currentVideoLink" width="100%" controls autoplay></video>
        <iframe *ngIf="isYoutubeVideo" width="100%" height="300px" [src]="currentVideoLink" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div> -->
    <div class="">
        <div class="">
            <div *ngIf="!isDisplayVideo" class="">
                <img *ngIf="currentImage" src="{{ baseURL }}{{ currentImage }}" style="min-height: 400px;max-height: 400px;" class="img-fluid" (mousemove)="onMouseMove($event)" (mouseleave)="onMouseLeave($event)" />
                <img *ngIf="!itemDetails?.image" src="assets/images/no-image.png" />
                <ul *ngIf="item_icons.length > 0" class="list-group list-group-horizontal" style="position: absolute;bottom: 25%;right: 6%;">
                    <div *ngFor="let icon of item_icons">
                        <li class="">
                            <img height="50" width="50" *ngIf="icon.icon_type=='AW'" src="assets/images/label 1.png" />
                            <!-- <div *ngIf="icon.icon_type=='AW'" style="background-color: #85b840;" class="circle"> AWARD <br> WINING</div> -->
                        </li>
                        <li class="">
                            <!-- <div *ngIf="icon.icon_type=='BS'" style="background-color: #178284 !important;" class="circle"> BESTSELLER</div> -->
                            <img height="50" width="50" *ngIf="icon.icon_type=='BS'" src="assets/images/label 3.png" /> 
                        </li>
                        <li class="">
                            <!-- <div *ngIf="icon.icon_type=='S'" style="background-color: #e26360 !important;" class="circle">SELL</div> -->
                            <img height="50" width="50" *ngIf="icon.icon_type=='S'" src="assets/images/label 2.png" />
                        </li>
                    </div>

                </ul>
            </div>
            <div *ngIf="isDisplayVideo">
                <video *ngIf="!isYoutubeVideo" [src]="currentVideoLink" width="100%" controls autoplay></video>
                <iframe *ngIf="isYoutubeVideo" width="100%" height="300px" [src]="currentVideoLink" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>


    </div>



    <!-- <div class="card-footer"></div> -->
    <div>
        <ul class="preview-thumbnail nav">

            <li *ngFor="let item of itemImages; let i = index" (click)="clickImage(item)" style="margin-left: 3%;margin-bottom: 3%;" class="preview-image border">
                <a data-target="#{{ item.id }}" data-toggle="tab" *ngIf="i < 3">
                    <img *ngIf="item.type === 'Image'" src="{{ baseURL }}{{ item.link | trim }}" class="small-img" />

                    <div *ngIf="item.type === 'Video'">
                        <span class="thumbnail-overlay"> </span>
                        <img src="{{ baseURL }}{{ item.thumbnail | trim }}" class="small-img " />
                    </div>

                </a>
            </li>
            <!-- </swiper> -->


        </ul>
    </div>


</div>

<!-- <div #zoomViewer *ngIf="zoomImage"> -->
<!-- <img [src] = 'zoomImage' > -->
<!-- <div *ngIf="zoomImage" class="zoom-viewer mat-elevation-z18"
    [ngStyle]="{ 'background-image': 'url(' + zoomImage + ')' }"></div>
</div> -->