import { Injectable } from "@angular/core";
import { HttpWrapperService } from "./http-wrapper.service";
import { Subject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProductService {

  private updateLists = new Subject<[]>();
  updateListsObs = <Observable<[]>>this.updateLists;

 

  private updateProposalList = new Subject<[]>();
  updateProposalListsObs = <Observable<[]>>this.updateProposalList;

  private updateWishLists = new Subject<[]>();
  updateWishListsObs = <Observable<[]>>this.updateWishLists;

  constructor(private http: HttpWrapperService) { }

  updateListFn(msg) {
    this.updateLists.next(msg);
  }
  
  updateProposal(msg) {
    this.updateProposalList.next(msg);
  }

  updateWishListFn(msg) {
    this.updateWishLists.next(msg);
  }

  getCategoryItems(data) {
    return this.http.post(`get_category_items_`, data);
  }
  getTypecategory(data) {
    return this.http.get(`get_categories_by_filter?filter=${data}`)
  }
  
  getTypeDetails(data) {
    let a={}
    a[data]="Yes"
    const formData=new FormData();
    formData.append('data', JSON.stringify(a))
    return this.http.post(`get_category_items_?`,formData);
  }

  getCategoryItemsPagination(data, p, pz) {
    return this.http.get(`get_category_items_?data=${JSON.stringify(data)}&page_index=${p}&page_size=${pz}`);
  }

  getCategorizeItemsByToys(data) {
    return this.http.get(`get_categorised_item_`+"?category_level_1="+data.category+"&category_level_2="+data.type+"&age="+data.age);
  }

  getCategorizeItems(data) {
    return this.http.post(`get_categorised_item_`, data);
  }

  getProductDetails(data) {
    return this.http.post(`get_item_details`, data);
  }

  getRecommendedItem(data) {
    return this.http.post(`recommended_items`, data);
  }

  getRelatedItems(data) {
    return this.http.post(`related_items`, data);
  }

  searchProduct(keyword) {
    return this.http.get(`search_?search=${keyword}`);
  }

  getPaymentStatus(data) {
    return this.http.get(`get_payment_status?quote_id=${data}`)
  }
  /**
   * WishList
   */

  addToWishList(data) {
    return this.http.post(`add_to_wishlist`, data);
  }

  updateWishList(data) {
    return this.http.post(`update_wishlist`, data)
  }

  // getWishList() {
  //   return this.http.get(`get_wishlisted_items`);
  // }

  getWishList() {
    return this.http.get(`get_wishlist_details`);
  }

  removeWishlistItem(data) {
    return this.http.post(`delete_wishlist`, data);
  }

  /***
   * cart
   */

  addToCart(data) {
    return this.http.post(`add_to_cart`, data);
  }

  getCart(data) {
    return this.http.post(`get_cart_details`, data);
  }

  removeCart(data) {
    return this.http.post(`delete_cart_item`, data);
  }

  updateCart(data) {
    return this.http.post(`update_cart`, data);
  }

  addToCartRecommend(data) {
    return this.http.post(`bulk_add_to_cart`, data)
  }

  /**
   *
   * Proposal
   */

  quotationRequest(data) {
    return this.http.post(`request_for_quotation`, data);
  }

  getQuotationRequest(data) {
    return this.http.post(`request_for_quotation`, data);
  }

  /**
   * checkout
   */

  placeOrder(data) {
    return this.http.post(`place_order`, data);
  }

  // get all age list
  ageList() {
    return this.http.get(`age_list`);
  }

  // ad wishlist item in cart

  addWishListToCart() {
    return this.http.get(`wishlist_checkout `);
  }

  // manufacturer list

  getManufacturer() {
    return this.http.get(`manufacturer_list`);
  }
}
