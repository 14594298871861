<div class="textAlign">
  <a routerLink="/" class="text-dark">    
    <!-- {{ "PROJECT.Back" | translate }} -->
    חזרה לדף הבית 
    <i class="fa fa-chevron-left" aria-hidden="true"></i>
  </a>
</div>

<div *ngIf="projectList" class="mt-5">
  <div class="row">
    <div class="col-md-12">
      <h4 class="project-title">
        <!-- {{ "PROJECT.Projects" | translate }} -->
        הפרויקטים שלנו 
      </h4>
      <p class="project-subtitle my-3">
        <!-- {{ "PROJECT.Showing" | translate }} -->
        מציג פרויקטים 
        : {{ startFrom }} - {{ endTo }}

        <!-- {{ "PROJECT.of" | translate }} -->

        {{ totalRecords }}
      </p>
      <div class="row">
        <div
          class="col-md-3 border-light my-4"
          *ngFor="
            let item of projectList
              | paginate
                : {
                    itemsPerPage: pageSize,
                    currentPage: currentPage,
                    totalItems: totalRecords
                  }
          "
        >
          <div class="card card-262 h-100">
            <div class="card-img-top">
              <img
                *ngIf="item.image"
                src="{{ baseUrl }}{{ item.image }}"
                class="img-fluid"
                alt=""
                style="height: 208px;"
              />
              <img
                *ngIf="!item.image"
                src="assets/images/no-image.png"
                class="img-fluid"
                width="100%"
                height="208px"
              />
            </div>
            <div class="card-body">
              <p
                class="font-montserrat-bold uline"
                [ngClass]="getClassByValue(item.project_name, 'text')"
              >
                {{ item.project_name }}
              </p>
              <h6 class="project-card-title">{{ item.title }}</h6>
              <p class="card-description">
                {{ item.description | truncate: 100 }}
              </p>
            </div>
            <div class="card-footer bg-white border-top-0">
              <button
                [routerLink]="['./', item.project_name]"
                class="btn btn-rounded mt-2"
                [ngClass]="getClassByValue(item.project_name, 'btn')"
              >
                <!-- {{ "PROJECT.More" | translate }} -->
                קרא עוד 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="border-top border-bottom mt-2 p-1 text-center"
    *ngIf="projectList?.length > 0"
  >
    <pagination-controls
      (pageChange)="pageChanged($event)"
      previousLabel=""
      nextLabel=""
    ></pagination-controls>
  </div>
  <div class="row" style="height: 100px;"></div>

  <app-why-buy-from-us></app-why-buy-from-us>
</div>
