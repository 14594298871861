import { Component, OnInit } from "@angular/core";
import { GeneralService } from "@core/services/general.service";
import { BASEURL } from "app/app.config";

@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.css"]
})
export class BlogComponent implements OnInit {
  blogList: any;
  baseUrl = BASEURL;
  pageSize = 10;
  currentPage = 1;
  totalRecords: any;
  endTo: number;
  startFrom: number = 1;
  constructor(private generalService: GeneralService) {}

  ngOnInit() {
    this.getAllBlogs(this.currentPage);
  }

  getAllBlogs(currentPage) {
    this.generalService
      .getBlogs(this.pageSize, currentPage - 1)
      .subscribe((res: any) => {
        this.blogList = res.message.data;
        this.totalRecords = res.message.total;
        if (currentPage === 1) this.endTo = this.blogList.length;
      });
  }

  pageChanged(e) {
    this.currentPage = e;
    this.getAllBlogs(this.currentPage);
    const startIndex = (this.currentPage - 1) * +this.pageSize;
    const endIndex = Math.min(
      startIndex * 1 + (this.pageSize - 1) * 1,
      this.totalRecords - 1
    );
    this.startFrom = startIndex + 1;
    this.endTo = endIndex + 1;
  }

  getClassByValue(status: string, type) {
    switch (status) {
      case "Therapist":
        return type === "text" ? "blog-red-text" : "blog-red-btn";
      case "משחקי חצר":
        return type === "text" ? "blog-yellow-text" : "blog-yellow-btn";
      case "School":
        return type === "text" ? "blog-blue-text" : "blog-blue-btn";
      case "Baby (0-12months)":
        return type === "text" ? "blog-red-text" : "blog-red-btn";
      case "Toys":
        return type === "text" ? "blog-yellow-text" : "blog-yellow-btn";
      case "Outdoor Toys":
        return type === "text" ? "blog-blue-text" : "blog-blue-btn";
      case "Furniture":
        return type === "text" ? "blog-blue-text" : "blog-blue-btn";
      case "Offers and Sale":
        return type === "text" ? "blog-yellow-text" : "blog-yellow-btn";
      default:
        return type === "text" ? "blog-red-text" : "blog-red-btn";
    }
  }
}
