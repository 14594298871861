<!-- Top Bar -->

<app-navbar></app-navbar>
<!-- Top Bar end -->
 <app-header *ngIf="!isMobailView" (navbarStatus)="setSideBarState($event)"></app-header> 
<app-header1  [change]="change" (chnageDetection)="chnageDetection($event)" *ngIf="isMobailView"  (navbarStatus)="setSideBarState($event)"></app-header1>
<div class="container mt-3 wrapper max-width" id="main_container">
  <div class="mb-2"></div>

  <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none d-xl-none direction-ltr">
    <app-main-navbar (navbarStatus)="setSideBarState($event)"></app-main-navbar>
  </div>
  <div class="res-content">
    <router-outlet></router-outlet>
  </div>
</div>
<div
  class="overlay"
  (click)="toggleSidebar()"
  [ngClass]="{ show: sideBarStatus }"
></div>

<app-footer></app-footer>
<!-- [ngClass]="{ show: getSideBarState() }" -->
