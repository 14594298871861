<div class="container-fluid  d-none d-sm-block">
  <div class="row">
    <div class="col-md-12 top-bar text-center mt-2" [ngClass]="{ rtl: currentLanguage === 'hb' }">
      <a (click)="open()" href="javascript:void(0)" class="mr-2 ml-2 text-dark-blue a-btn small"
        *ngIf="!isUserLoggedIn"><img src="assets/images/svg-icons/sign-in.svg" alt="signin" width="17px" />
        <!-- Sign in / Register -->
        <!-- {{ "NAVBAR.signIn" | translate }} -->
        התחברות / הרשמה
      </a>

      <div ngbDropdown class="d-inline-block">
        <a ngbDropdownToggle href="javascript:void(0)" class="mr-3 ml-2 text-dark-blue a-btn small"
          *ngIf="isUserLoggedIn"><img src="assets/images/svg-icons/sign-in.svg" alt="signin" width="17px" />
          {{ userDisplayName }}</a>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <div class="arrow-up"></div>
          <a routerLink="/account/profile" ngbDropdownItem>Profile</a>
          <a routerLink="/account/orders" ngbDropdownItem> Order</a>
          <a routerLink="/account/proposal" ngbDropdownItem>Proposal</a>
          <!-- <a routerLink="/account/change-password" ngbDropdownItem
          >Change password</a
          >
        -->
        </div>
      </div>

      <a routerLink="/account/wishlist" class="mr-3 ml-2 text-dark-blue a-btn small"><img
          src="assets/images/svg-icons/002-heart.svg" alt="wishlist" width="15px" />
        <!-- Wishlist -->
        <!-- {{ "NAVBAR.Wishlist" | translate }} -->
        רשימת המשאלות שלי
      </a>
      <a (click)="onRouteing(1)" class="mr-3 ml-2 text-dark-blue a-btn small">
        <img src="assets/images/svg-icons/003-price-tag.svg" alt="wishlist" width="15px" />
        <!-- Pricing request -->
        <!-- {{ "NAVBAR.PricingRequest" | translate }} -->
        בקשה להצעת מחיר
      </a>
      <!-- <a
        routerLink="/pricing-quote"
        class="mr-3 ml-2 text-dark-blue a-btn small"
        ><img
          src="assets/images/svg-icons/003-price-tag.svg"
          alt="Pricing Request"
          width="15px"
        />
        Pricing Request</a
      > -->
      <a routerLink="/help" class="mr-3 ml-2 text-dark-blue a-btn small"><img
          src="assets/images/svg-icons/004-question.svg" alt="Help" width="15px" />
        <!-- {{ "NAVBAR.Help" | translate }} -->
        עזרה
        <!-- Help -->
      </a>
      <a routerLink="/projects" class="mr-3 ml-2 text-dark-blue a-btn small"><img
          src="assets/images/svg-icons/projects.svg" alt="Projects" width="15px" />
        <!-- {{ "NAVBAR.Projects" | translate }} -->
        פרויקטים
        <!-- Projects -->
      </a>
      <a routerLink="/blog" class="mr-3 ml-2 text-dark-blue a-btn small"><img src="assets/images/svg-icons/006-blog.svg"
          alt="Blog" width="15px" />
        <!-- {{ "NAVBAR.Blog" | translate }} -->
        בלוג
        <!-- Blog -->
      </a>
      <a (click)="logout()" style="cursor: pointer;" class="mr-3 ml-2 text-dark-blue a-btn small"
        *ngIf="isUserLoggedIn"><img src="assets/images/svg-icons/001-user.svg" alt="Logout" width="15px" />
        <!-- {{ "NAVBAR.Logout" | translate }} -->
        התנתקות
        <!-- Logout -->
      </a>

      <!-- <button
        class="btn btn-default"
        #langSelect
        *ngFor="let lang of translate.getLangs()"
        (click)="switchLanguage(langSelect.value)"
        [value]="lang"
        [class.active]="lang === translate.currentLang"
      >
        {{ lang }}
      </button> -->

      <!-- <div ngbDropdown class="d-inline-block">
        <span id="dropdownBasic1" ngbDropdownToggle>
          <img
            [src]="
              currentLanguage === 'hb'
                ? 'assets/icons/us.png'
                : 'assets/icons/hb.png'
            "
            alt=""
            width="20px"
          />
        </span>
        <div
          ngbDropdownMenu
          aria-labelledby="dropdownBasic1"
          style="left:-50px"
        >
          <div ngbDropdownItem class="pointer" (click)="switchLanguage('en')">
            <img
              class="img-fluid"
              width="20px"
              src="assets/icons/us.png"
              alt="EN"
            /><span style="padding: 0px 10px;">En</span>
          </div>
          <div ngbDropdownItem class="pointer" (click)="switchLanguage('hb')">
            <img
              class="img-fluid"
              width="20px"
              src="assets/icons/hb.png"
              alt="HB"
            />
            <span style="padding: 0px 10px;">Hb</span>
          </div>
        </div>
      </div> -->

      <hr />
    </div>
  </div>
</div>

<div class="container-fluid  d-block d-sm-none d-md-none">
  <div class="row">
    <div class="col-md-12 top-bar text-center mt-2" [ngClass]="{ rtl: currentLanguage === 'hb' }">
      <a (click)="open()" href="javascript:void(0)" class="mr-2 ml-2 text-dark-blue a-btn small"
        *ngIf="!isUserLoggedIn"><img src="assets/images/svg-icons/sign-in.svg" alt="signin" width="17px" />
        <!-- Sign in / Register -->
        <!-- {{ "NAVBAR.signIn" | translate }} -->
        התחברות / הרשמה
      </a>

      <div ngbDropdown class="d-inline-block">
        <a ngbDropdownToggle href="javascript:void(0)" class="mr-3 ml-2 text-dark-blue a-btn small"
          *ngIf="isUserLoggedIn"><img src="assets/images/svg-icons/sign-in.svg" alt="signin" width="17px" />
          {{ userDisplayName }}</a>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <div class="arrow-up"></div>
          <a routerLink="/account/profile" ngbDropdownItem>פרטים אישיים</a>
          <a routerLink="/account/orders" ngbDropdownItem> היסטוריית הזמנות</a>
          <a routerLink="/account/proposal" ngbDropdownItem>היסטוריית הצעות מחיר</a>
          <!-- <a routerLink="/account/change-password" ngbDropdownItem
          >Change password</a
          >
        -->
        </div>
      </div>

      <a routerLink="/account/wishlist" class="mr-3 ml-2 text-dark-blue a-btn small"><img
          src="assets/images/svg-icons/002-heart.svg" alt="wishlist" width="15px" />
        <!-- Wishlist -->
        <!-- {{ "NAVBAR.Wishlist" | translate }} -->
        רשימת המשאלות שלי
      </a>
      <a (click)="onRouteing(2)" class="mr-3 ml-2 text-dark-blue a-btn small">
        <img src="assets/images/svg-icons/003-price-tag.svg" alt="wishlist" width="15px" />
        <!-- Pricing request -->
        <!-- {{ "NAVBAR.PricingRequest" | translate }} -->
        בקשה להצעת מחיר
      </a>

      <a href="javascript:void(0)" (click)="logout()" class="mr-3 ml-2 text-dark-blue a-btn small"
        *ngIf="isUserLoggedIn"><img src="assets/images/svg-icons/001-user.svg" alt="Logout" width="15px" />
        <!-- {{ "NAVBAR.Logout" | translate }} -->
        התנתקות
        <!-- Logout -->
      </a>

      <hr />
    </div>
  </div>
</div>