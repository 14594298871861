import { Component, OnInit } from "@angular/core";
import { GeneralService } from "@core/services/general.service";
import { BASEURL } from "app/app.config";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.css"]
})
export class ProjectsComponent implements OnInit {
  baseUrl = BASEURL;
  projectList: any;
  pageSize = 10;
  currentPage = 1;
  totalRecords: any;
  endTo: any;
  startFrom: number = 1;
  constructor(private generalService: GeneralService) {}

  ngOnInit() {
    this.getAllProject(this.currentPage);
  }

  getAllProject(currentPage) {
    this.generalService
      .getProjects(this.pageSize, currentPage - 1)
      .subscribe((res: any) => {
        this.projectList = res.message.data;
        this.totalRecords = res.message.total;
        if (currentPage === 1) this.endTo = this.projectList.length;
      });
  }

  pageChanged(e) {
    this.currentPage = e;
    this.getAllProject(this.currentPage);
    const startIndex = (this.currentPage - 1) * +this.pageSize;
    const endIndex = Math.min(
      startIndex * 1 + (this.pageSize - 1) * 1,
      this.totalRecords - 1
    );
    this.startFrom = startIndex + 1;
    this.endTo = endIndex + 1;
  }

  getClassByValue(status: string, type) {
    switch (status) {
      case "ריפוי בעיסוק":
        return type === "text" ? "project-red-text" : "project-red-btn";
      case "מתקני חצר":
        return type === "text" ? "text-success" : "project-green-btn";
      case "Schools":
        return type === "text" ? "project-yellow-text" : "project-yellow-btn";
      case "STP":
        return type === "text" ? "project-blue-text" : "project-blue-btn";
      case "הורים":
        return type === "text" ? "project-yellow-text" : "project-yellow-btn";
      case "מטפלים":
        return type === "text" ? "project-red-text" : "project-red-btn";
      case "מוסדות חינוכיים":
        return type === "text" ? "project-yellow-text" : "project-yellow-btn";
      default:
        return type === "text" ? "project-blue-text" : "project-blue-btn";
    }
  }


}
