<div class="row">
  <div class="col-md-12 mt-4 mb-4">
    <h2 class="text-center font-montserrat-regular">
      <!-- {{ "LANDING.WhyBuy.title" | translate }} -->
      למה לקנות אצלנו?

    </h2>
  </div>

  <div class="col-md-3 col-6">
    <div class="card border-0">
      <div class="card-header text-center border-0 bg-white">
        <img src="assets/images/icons/1.png" width="75" class="fill-blue" />
      </div>
      <div class="card-body pt-1 text-center">
        <p class="text-dark">
          <!-- {{ "LANDING.WhyBuy.text1" | translate }} -->
          משחקים יעודיים להורים, אנשי מקצוע, בתי ספר קופות חולים , מעונות וגני ילדים 
        
        </p>
      </div>
    </div>
  </div>

  <div class="col-md-3 col-6">
    <div class="card border-0">
      <div class="card-header text-center border-0 bg-white">
        <img src="assets/images/icons/2.png" width="75" />
      </div>
      <div class="card-body pt-1  text-center">
        <p class="text-dark">

          <!-- {{ "LANDING.WhyBuy.text2" | translate }} -->
          טכנולוגיה מאובטחת בתקן אבטחה הגבוה ביותר בכל שלבי הרכישה
        </p>
      </div>
    </div>
  </div>

  <div class="col-md-3 col-6">
    <div class="card border-0">
      <div class="card-header text-center border-0 bg-white">
        <img src="assets/images/icons/3.png" width="75" />
      </div>
      <div class="card-body pt-1  text-center">
        <p class="text-dark">

          <!-- {{ "LANDING.WhyBuy.text3" | translate }} -->
          משחקים רבי מכר ממגון מותגים ברחבי העולם ואינספור מטרות לימודיות 
        </p>
      </div>
    </div>
  </div>

  <div class="col-md-3 col-6">
    <div class="card border-0">
      <div class="card-header text-center border-0 bg-white">
        <img src="assets/images/icons/4.png" width="75" />
      </div>
      <div class="card-body pt-1 text-center">
        <p class="text-dark">

          <!-- {{ "LANDING.WhyBuy.text4" | translate }} -->
          רפרטואר עשיר של משחקים שאנחנו היבואנים הבלעדיים שלהם 
        </p>
      </div>
    </div>
  </div>
</div>