import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { menuItem } from "app/layout/components/header/menu";
import { Options, LabelType } from "ng5-slider";

@Component({
  selector: "app-item-filter",
  templateUrl: "./item-filter.component.html",
  styleUrls: ["./item-filter.component.css"],
})
export class ItemFilterComponent implements OnInit {
  @Output() filterOptionData = new EventEmitter();
  @Input() title;
  @Input() manufacturerList;
  @Input() ageList;
  openCategory = true;
  openType;
  openPrice;
  openAge;
  openManufacturer;
  categoryItem = JSON.parse(localStorage.getItem("category"));
  subCategoryItem;
  subSubCategoryItem;
  currentIndex;
  currentItem: any;
  currentItemIndex;
  level2Item: any;
  level3Item: any;
  filterData = {};
  filterDummyData  = {}; // Added By Ashwin - 20/7/2022
  ageValue: number = 3;
  ageOptions: Options = {
    floor: 0,
    ceil: 100,
  };
  minValue: number = 100;
  maxValue: number = 400;
  options: Options = {
    floor: 0,
    ceil: 5000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "<b>Min  :</b> <i class='fa fa-shekel'></i>" + value;
        case LabelType.High:
          return "<b>Max  :</b> <i class='fa fa-shekel'></i>" + value;
        default:
          return "<i class='fa fa-shekel'></i>" + value;
      }
    },
  };
  currentSelectedCategory;
  currentSelectedSubCategory;
  constructor() { }

  ngOnInit() {
 
  }

  ngOnChanges() {
    if (this.title.hasOwnProperty("keyword")) {
      this.currentSelectedCategory = this.title.keyword;
    } else if (this.title.hasOwnProperty("findToys")) {
      this.currentSelectedCategory = this.title.type;
      this.filterData["category_level_1"] = this.title.category;
      this.filterData["category_level_2"] = this.title.Parents;
      this.filterData["category_level_3"] = this.title.children;
      this.filterData["category_level_4"] = this.title.children2;
      // this.filterData["age"] = this.ageValue;
	  // Added By Ashwin - 20/7/2022
	  this.filterDummyData["category_level_1"] = this.title.category;
      this.filterDummyData["category_level_2"] = this.title.Parents;
      this.filterDummyData["category_level_3"] = this.title.children;
      this.filterDummyData["category_level_4"] = this.title.children2;
    } else {
      this.currentSelectedCategory = this.title.c1;
      this.filterData["category_level_1"] = this.title.c1;
      this.filterData["category_level_2"] = this.title.c2;
      this.filterData["category_level_3"] = this.title.c3;
      this.filterData["category_level_4"] = this.title.c4;
      // this.filterData["age"] = this.ageValue;
	  // Added By Ashwin - 20/7/2022
	  this.filterDummyData["category_level_1"] = this.title.c1;
      this.filterDummyData["category_level_2"] = this.title.c2;
      this.filterDummyData["category_level_3"] = this.title.c3;
      this.filterDummyData["category_level_4"] = this.title.c4;
      const d = this.categoryItem.findIndex((p) => p.category_id === this.title.c1);
      this.checkCategory(this.title.c1, d);
      this.level2Item = this.title.l2;
      this.level3Item = this.title.l4;
    }

   }

  checkCategory(type, index) {
    this.currentIndex = index;
    this.filterData["category_level_1"] = type;
    const d = this.categoryItem.filter((it) => it.category_id === type);
    this.subCategoryItem = d ? d[0]?.level_2 : [];
    this.currentSelectedCategory = type;
    this.filterOption();
  }

  checkSubCategory(type, index, event) {
    //this.filterData["category_level_2"] = type;
	if(event.target.checked)
    	this.filterData["category_level_2"] = type;
	else
		delete this.filterData["category_level_2"];	 // Added By Ashwin - 20/7/2022
    this.filterOption();
    const d = this.subCategoryItem.filter((it) => it.category_id === type);
    this.subSubCategoryItem = d ? d[0].level_3 : [];
  }

  checkSubSubCategory(type, index, event) {
    delete this.filterData["category_level_4"];
    //this.filterData["category_level_3"] = type;
	if(event.target.checked)
    	this.filterData["category_level_3"] = type;
	else
	    delete this.filterData["category_level_3"]; // Added By Ashwin - 20/7/2022
    this.filterOption();
    const d = this.subSubCategoryItem.filter((it) => it.category_id === type);
    this.currentItem = d ? d[0].level_4 : [];
  }

  checkSubSubSubCategory(type, index, event) {
    //this.filterData["category_level_4"] = type;
	if(event.target.checked)
    this.filterData["category_level_4"] = type;
	else
	delete this.filterData["category_level_4"]; // Added By Ashwin - 20/7/2022
    this.filterOption();
    //const d = this.currentItem.filter((it) => it.title === type);
  
  }
  
  checkType(type, index) {
    this.currentItemIndex = index;
    this.filterOption();
  }

  checkSubType(type, index) {
    this.filterOption();
  }

  checkSubSubType(type, index) {
    this.filterOption();
  }

  checkSubSubSubType(type, index) {
    this.filterOption();
  }

  filterOption() {
    if(this.title.l4){
      this.filterData['title'] = this.title.l4;
    }else if(!this.title.l4 && this.title.l3){
      this.filterData['title'] = this.title.l3;
    }else if(!this.title.l4 && !this.title.l3 && this.title.l2){
      this.filterData['title'] = this.title.l2;
    }else if(!this.title.l4 && !this.title.l3 && !this.title.l2 && this.title.l1){
      this.filterData['title'] = this.title.l1;
    }
    this.filterOptionData.emit(this.filterData);
  }

  changePrice(e) {
    this.filterData["price_from"] = e.value;
    this.filterData["price_to"] = e.highValue;
    this.filterOption();
  }

  changeAge(e) {
    this.filterData["age"] = e;
    this.filterOption();
  }

  checkManufacturer(item, i) {
    this.filterData["manufacturer"] = item;
    this.filterOption();
  }
}
