import { Component, OnInit, Inject } from "@angular/core";
import { LoginComponent } from "app/layout/containers/auth/login/login.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "@core/services/user.service";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
  isUserLoggedIn: boolean;
  userData: { userId: string; fullName: string; userName: string };
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private modalService: NgbModal,
    private userService: UserService,
    private router: Router,
    private cookieService: CookieService,
  ) { }

  main_categories = true;
  any_questions;
  
  windowScrolled = false; /*Added By Ashwin - 10/5/2022 */
   
  ngOnInit() {
    this.userData = this.userService.getCurrentUser();
    this.isUserLoggedIn = this.userService.checkLogin();
	window.addEventListener('scroll', () => {   /*Added By Ashwin - 10/5/2022 */
      //this.windowScrolled = window.pageYOffset !== 0;
	    if (this.document.body.scrollTop > 300 || this.document.documentElement.scrollTop > 300) {
        	this.windowScrolled = true;
		} else {
			this.windowScrolled = false;
		}
    });
   }

  scrollToTop(): void { /*Added By Ashwin - 10/5/2022 */
    window.scrollTo(0, 0);
	// scroll to the top of the body
    return this.document.body.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }
  
  open() {
    this.modalService.open(LoginComponent);
  }

  onRouteing(val){
  
    this.router.navigate(['/account/proposal-list']);
  }

  logout() {
    const formData = new FormData();
    formData.append("usr", this.userData.userName);
    this.userService.logOut(formData).subscribe(
      (res: any) => {
        this.router.navigate["/"];
        localStorage.clear();
        this.cookieService.deleteAll();
        alert(res.message);
        window.location.href = "";
      },
      (error) => {
        this.router.navigate["/"];
        localStorage.clear();
        window.location.href = "";
      }
    );
  }b


  addSubscribe(email) {
    this.userService.emailSubscription(email).subscribe((sucess)=>{
      alert('You have subscribed sucessfully')
    })
 
  }
}
