import { Component, OnInit } from "@angular/core";
import { BASEURL } from "app/app.config";
import { GeneralService } from "@core/services/general.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-blog-details",
  templateUrl: "./blog-details.component.html",
  styleUrls: ["./blog-details.component.css"]
})
export class BlogDetailsComponent implements OnInit {
  baseUrl = BASEURL;
  blogData: any;
  constructor(
    private route: ActivatedRoute,
    private generalService: GeneralService
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.params.blogId;
    this.generalService.getBlog(id).subscribe((res: any) => {
      this.blogData = res.message.data;
    });
  }
}
