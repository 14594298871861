import { Component, OnInit, ViewChild, PLATFORM_ID, Inject } from "@angular/core";
import { ProductService } from "@core/services/product.service";
import { UserService } from "@core/services/user.service";
import { AlertService } from "@core/services/alert.service";
import { BASEURL } from "app/app.config";
import { CartService } from "@core/services/cart.service";
import "rxjs/add/operator/map";
import { FormGroup, FormBuilder } from "@angular/forms";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoginComponent } from "app/layout/containers/auth/login/login.component";

import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: "app-cart-list",
  templateUrl: "./cart-list.component.html",
  styleUrls: ["./cart-list.component.scss"]
})
export class CartListComponent implements OnInit {
  cartItem: any;
  baseurl = BASEURL;
  currentQuote = this.userService.getUserQuote();
  guestSession = this.userService.getGuestSession();
  @ViewChild(NgbDropdown)
  private dropdown: NgbDropdown;
  proposalData: any;
  proposalList: any;
  btnType: string;
  whiteImg : boolean = false;
  constructor(
    private productService: ProductService,
    private alertService: AlertService,
    private cartService: CartService,
    private router: Router,
    private modalService: NgbModal,
    private userService: UserService,
	@Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.productService.updateListsObs.subscribe((response: any) => {
      if (response.isCheck) {
        this.cartItem = response.data.message;
      } else {
        this.cartItem = [];
        this.cartItem.items = [];
      }
    });

    this.productService.updateProposalListsObs.subscribe((response: any) => {
      // this.cartItem = response.message;
      if (response.isCheck) {
        this.proposalData = response.data.message;
      } else {
        this.proposalData = [];
        this.proposalData.items = [];
      }
    });


    this.productService.updateWishListsObs.subscribe(res => {
      this.getCartItem();
    });
  }

  ngOnInit() {
    this.getCartItem();
    this.getProposalItem();
  }

  onButtonClick(value){
    this.btnType = value;
  }

  getProposalItem() {
    if (isPlatformBrowser(this.platformId)) {
		const currentQuote = this.userService.getCurrentProposal();
		const formData = new FormData();    
		if (this.userService.checkLogin()) {
		  formData.append("quote_id", currentQuote);
		}else{
		  if(this.userService.getUserQuote()){
			formData.append("quote_id", currentQuote);
		  }else{
			const guestSessionId = this.userService.getGuestSession();
			formData.append("guest_session_id", guestSessionId);
		  }
		}
		this.productService.getCart(formData).subscribe((res: any) => {
		  this.proposalList = res.message.items;
		  this.proposalData = res.message;
		});
	}
  }

  getCartItem() {
    if (isPlatformBrowser(this.platformId)) {
		const formData = new FormData();
		if (this.userService.checkLogin()) {
		  formData.append("quote_id", this.currentQuote);
		}else{
		  if(this.userService.getUserQuote()){
			formData.append("quote_id",  this.currentQuote);
		  }else{
			const guestSessionId = this.userService.getGuestSession();
			formData.append("guest_session_id", guestSessionId);
		  }
		}
		if (this.currentQuote || this.guestSession) {
		  this.productService.getCart(formData).subscribe(
			(res: any) => {
			  this.cartItem = res.message;
			},
			err => {
			  if (!this.currentQuote && !this.guestSession) {
				this.cartItem = [];
			  }
			}
		  );
		}
	}
  }
  

  removeCart(item, index) {
    this.cartService.removeCart(this.cartItem, item, index, "Cart");
  }

  removeCartProposal(item, index) {
    this.cartService.removeCart(this.proposalData, item, index, "Proposal");
  }


  changeCartImg(){
    this.whiteImg = !this.whiteImg;
  }


  changeQty(qty, item, index, type) {

    if (type === 'plus') {
      qty = ++qty;
      this.cartItem.items[index].qty = qty;
    } else if (type === 'mins') {
      qty = --qty;
      this.cartItem.items[index].qty = qty;
    }

    // let newQty = type ? ++qty : qty > 1 ? --qty : 1;

    // this.cartItem.items[index].qty = newQty;
    type = type === 'plus' ? 1 : 0
    this.cartService.updateCartQty(type, qty, this.cartItem, item, index);
  }

  changeQtyProposal(qty, item, index, type){

    if (type === 'plus') {
      qty = ++qty;
      this.proposalData.items[index].qty = qty;
    } else if (type === 'mins') {
      qty = --qty;
      this.proposalData.items[index].qty = qty;
    }


    // let newQty = type ? ++qty : qty > 1 ? --qty : 1;

    // this.cartItem.items[index].qty = newQty;
    type = type === 'plus' ? 1 : 0
    this.cartService.updateCartQty(type, qty, this.proposalData, item, index,"proposal");
  }

  closeDropdown(dropdown) {
    dropdown.close();

    // asassa
  }



  
  goToCheckout(type,booleanType) {
      // if(this.userService.checkLogin()){
        if(booleanType){
          this.router.navigateByUrl("/account/checkout");
        }else{
          this.router.navigateByUrl("/account/pricing-request");
        }
      // }else{
      //   const modalRef = this.modalService.open(LoginComponent);
      //   modalRef.componentInstance.guest = type;
      //   if(booleanType == true){
      //     modalRef.componentInstance.type = "checkout";
      //   }else if(booleanType == false){
      //     modalRef.componentInstance.type = "request";
      //   }else{
      //     modalRef.componentInstance.type = "main";
      //   }
      // }
  }
  

  // sendQuote() {
  //   if (this.currentQuote) {
  //     this.closeDropdown();
  //     this.router.navigate(
  //       ["/account/pricing-request-edit", this.currentQuote],
  //       {
  //         queryParams: { source: "cart" }
  //       }
  //     );
  //   }
  // }
}