  import { Injectable } from "@angular/core";
  import { HttpWrapperService } from "./http-wrapper.service";
  import { Subject, Observable } from 'rxjs';
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class AccountService {

  private pagechange = new Subject<number>();

  constructor(private http: HttpWrapperService) {}

  getOrders(pageSize, pageIndex) {
    return this.http.get(
      `order_history?page_size=${pageSize}&page_index=${pageIndex}`
    );
  }
  getOrder(orderId) {
    return this.http.get(`order_details?order_id=${orderId}`);
  }

  deleteOrderItem(data) {
    return this.http.post(`delete_order_item`, data);
  }

  // Proposal

  sendProposal(data) {
    return this.http.post(`send_proposal`, data);
  }

  getProposal(data) {
    return this.http.post(`get_cart_details`, data);
  }

  getProposals(pageSize, pageIndex) {
    return this.http.get(
      `get_proposal_list?page_size=${pageSize}&page_index=${pageIndex}`
    );
  }

  deleteProposal(id) {
    return this.http.get(`delete_proposal?proposal_id=${id}`);
  }

  //  Profile

  getProfile() {
    let header=new HttpHeaders();
header.append('Content-Type',"application/json")
    let Option={
header:header
    }
    return this.http.get(`get_customer_profile`);
  }

  updateProfile(data) {
    return this.http.post(`update_customer_profile`, data);
  }

  // PageChange

  public getPage(): Observable<number> {
    return this.pagechange.asObservable();
  }
  /*
   * @param {string} message : siblingMsg
   */
  public updatePage(num: number): void {
 
    this.pagechange.next(num);
  }
}
