<!-- Mobile and Tablet View -->

<div class="container-fluid d-block d-sm-block d-md-none mobile-padding">
  <div class="row fix-header">
    <div class="col-12 col-sm-12 text-md-center px-2">
      <div class="d-flex justify-content-between">
        <div class="mt-4_5 mr-sm-5 ml-2">
          <svg
            class="icon icon-bars mr-2 ml-sm-0 mr-sm-4"
            (click)="toggleSideNav()"
            aria-hidden="true"
          >
            <use xlink:href="#019-menu"></use>
          </svg>
        </div>

        <div class=" mr-sm-4 mr-md-3">
          <a routerLink="/">
            <img
              src="assets/images/logo_new.png"
              class="img-fluid logo"
              alt="logo"
            />
          </a>
        </div>

        <div class="mt-4_5 mt-sm-4_5 mr-2">
          <div class="">
            <a
            class="text-dark"
            (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample"
          >
            <svg class="icon icon-search mx-1">
              <use xlink:href="#007-search"></use>
            </svg>
          </a>
            <!-- <app-cart-list></app-cart-list> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-first"></div>
    <div class="col-second text-md-center px-2">
      <app-cart-list></app-cart-list>
    </div>

    <div class="col-md-12">
      <div id="collapseExample" [ngbCollapse]="!isCollapsed">
        <div class="card border-0">
          <div class="card-body p-1">
            <!-- <div class="input-group w-300">
              <input
                type="text"
                class="form-control search-box border-right-0 raidus-lt-lb"
                placeholder="{{ 'HEADER.SearchPlaceHolder' | translate }}"
                aria-label="search"
                aria-describedby="search-icon"
                [(ngModel)]="searchText"
                (keyup.enter)="search()"
                [ngClass]="currentLang === 'en' ? 'text-left' : 'text-right'"
              />
              <div class="input-group-append">
                <span class="input-group-text bg-white" id="search-icon"
                  ><a (click)="search()" class="mt-1"
                    ><span class="lnr lnr-magnifier fa-1x"></span></a
                ></span>
              </div>
            </div> -->

            <div
              class="input-group w-300 search-box-container search-box-auto-complete"
            >
              <div class="input-group-prepend">
                <span
                  class="input-group-text bg-white border-darkblue border-right-0 search-box-prepend"
                  id="search-icon"
                  ><a (click)="search()" class="mt-2"
                    ><span class="lnr lnr-magnifier fa-1x icon-color"></span></a
                ></span>
              </div>
              <div class="ng-autocomplete">
                <ng-autocomplete
                  [data]="searchItem"
                  [searchKeyword]="keyword"
                  (selected)="selectEvent($event)"
                  (inputChanged)="onChangeSearch($event)"
                  (inputFocused)="onFocused($event)"
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [(ngModel)]="searchText"
                  (keyup.enter)="search()"
                  placeholder="הקלד שם או קוד מוצר לחיפוש"
                >
                <!-- placeholder="{{ 'HEADER.SearchPlaceHolder' | translate }}" -->
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                  <a
                    (click)="onProductDetail(item.item_code,item.english_name)"
                    [innerHTML]="item.item_name"
                  ></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile and Tablet View -->

<!-- Desktop View -->

<div class="container mb-2 d-none d-sm-none d-md-block">
  <div class="row fix-header">
    <div class="col-md-4">
      <div
        class="d-flex justify-content-start"
        [ngClass]="{ hb_cart_info: currentLang === 'hb' }"
      >
        <app-cart-list></app-cart-list>
      </div>
    </div>

    <div class="col-md-4 text-center">
      <a routerLink="/"
        ><img src="assets/images/logo_new.png" class="img-fluid" alt="logo" />
      </a>
      <p class="text-center mb-0 text-red">מכוונים למשלוח ישיר בין 24-72 שעות</p>
    </div>

    <!-- <div class="col-md-4">
      <div class="d-flex justify-content-end">
        <div class="input-group mt-md-4_5 w-300 search-box-container mr-3">
          <div class="input-group-prepend">
            <span
              class="input-group-text bg-white border-darkblue border-right-0 search-box-prepend"
              id="search-icon"
              ><a (click)="search()" class="mt-1"
                ><span class="lnr lnr-magnifier fa-1x icon-color"></span></a
            ></span>
          </div>
          <input
            type="text"
            class="form-control search-box border-left-0"
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            placeholder="{{ 'HEADER.SearchPlaceHolder' | translate }}"
            aria-label="search"
            aria-describedby="search-icon"
            [(ngModel)]="searchText"
            (keyup.enter)="search()"
            [ngClass]="currentLang === 'en' ? 'text-left' : 'text-right'"
          />
        </div>
      </div>
    </div> -->
    <div class="col-4">
      <div
        class="input-group mt-md-4_5 w-300 search-box-container search-box-auto-complete mr-3"
      >
        <div class="input-group-prepend">
          <span
            class="input-group-text bg-white border-darkblue border-right-0 search-box-prepend"
            id="search-icon"
            ><a (click)="search()" class="mt-2"
              ><span class="lnr lnr-magnifier fa-1x icon-color"></span></a
          ></span>
        </div>
        <div class="ng-autocomplete">
          <ng-autocomplete
            [data]="searchItem"
            [searchKeyword]="keyword"
            (selected)="selectEvent($event)"
            (inputChanged)="onChangeSearch($event)"
            (inputFocused)="onFocused($event)"
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate"
            [(ngModel)]="searchText"
            (keyup.enter)="search()"
            placeholder="הקלד שם או קוד מוצר לחיפוש">
            <!-- "{{ 'HEADER.SearchPlaceHolder' | translate }}" -->
          <!-- > -->
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a
              (click)="onProductDetail(item.item_code,item.item_name)"
              [innerHTML]="item.item_name"
            ></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Desktop View -->

<!-- Main Menu -->

<!--<div class="container d-md-block d-none d-sm-none rtl-pr-4">
  <nav class="navbar navbar-light navbar-expand-lg mainmenu">
    <button
      (click)="toggleSideNav()"
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="lnr lnr-menu"></span>
    </button>

    <div
      class="collapse navbar-collapse border-top border-bottom"
      id="navbarSupportedContent"
    >
      <ul class="navbar-nav" *ngIf="menu">
        <ng-container *ngFor="let item of menu; let i = index">
          <li
            class="dropdown pointer menu-item hover-nav"
            [ngClass]="
              item.title === 'הורים' ||
              item.title === 'מטפלים' ||
              item.title === 'מוסדות חינוכיים'
                ? 'navbar-border text-lightblue f-weight-bold'
                : ''
            "
          >
            <a
              class="font-montserrat-bold highlight"
              [class]="item.childClass"
              [ngClass]="{'text-red':item.title==='SALE', 'dropdown-toggle': item.level_2?.length > 0 ,
                'text-lightblue':item.title === 'הורים' ||  item.title === 'מטפלים' ||item.title === 'מוסדות חינוכיים'
                  
              }"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              (click)="goToPage(item.title,item.category_id)"
              [routerLink]="
                item.has_child
                  ? null
                  : [
                      '/products',
                      item.title,
                      {
                        l1: item.title,
                        c1: item.category_id
                      }
                    ]
              "
              >{{ item.title }} <i class="" aria-hidden="true"></i>
              <span
                [ngClass]="
                  item.has_child &&
                  (item.title === 'הורים' ||
                    item.title === 'מטפלים' ||
                    item.title === 'מוסדות חינוכיים')
                    ? 'fa fa-chevron-down mx-1 has-arrow1 '
                    : item.has_child
                    ? 'fa fa-chevron-down mx-1 has-arrow2'
                    : ''
                "
              ></span>
            </a>

            <ul
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
              *ngIf="item.has_child"
            >
              <li class="menu-item--level2" *ngFor="let l2 of item.level_2">
                <div>
                  <a
                    class=" "
                    [routerLink]="
                      l2.has_child
                        ? null
                        : [
                            '/products',
                            l2.title,
                            {
                              l1: item.title,
                              c1: item.category_id,
                              l2: l2.title,
                              c2: l2.category_id
                            }                          ]
                    "
                    [ngClass]="{ 'dropdown-toggle': l2.level_2?.length > 0 }"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style="display: flex; justify-content: space-between;"
                  >
                    {{ l2.title }}
                    <span *ngIf="l2.level_3"></span>
                  </a>
                </div>

                <ul
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbarDropdown"
                  *ngIf="l2.has_child"
                >
                  <li
                    class="dropdown menu-item--level3"
                    *ngFor="let l3 of l2.level_3"
                  >
                    <a
                      class=""
                      [ngClass]="{ 'dropdown-toggle': l3.level_3?.length > 0 }"
                      [routerLink]="
                        l3.has_child
                          ? null
                          : [
                              '/products',
                              l3.title,
                              {
                                l1: item.title,
                                c1: item.category_id,
                                l2: l2.title,
                                c2: l2.category_id,
                                l3: l3.title,
                                c3: l3.category_id
                              }  
                            ]
                      "
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      >{{ l3.title }}
                      <span *ngIf="l3.level_4?.length > 0"> </span
                    ></a>
                    <ul
                      class="dropdown-menu dropdown-menu-right"
                      aria-labelledby="navbarDropdown"
                      *ngIf="l3.has_child"
                    >
                      <li
                        class="dropdown menu-item--level4"
                        *ngFor="let l4 of l3.level_4"
                      >
                        <a
                          [routerLink]="[
                            '/products',
                            l4.title,
                              {
                                l1: item.title,
                                c1: item.category_id,
                                l2: l2.title,
                                c2: l2.category_id,
                                l3: l3.title,
                                c3: l3.category_id,
                                l4: l4.title,
                                c4: l4.category_id
                              } 
                          ]"
                          >{{ l4.title }}</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </nav>
</div>-->

<!-- Main Menu -->



<!----------------------- Just For Testing---------Remove It-------------------------->
<div class="container">

<!-- ============= COMPONENT ============== -->
    <nav class="custom-navbar navbar navbar-light navbar-expand-lg mainmenu">  <!--navbar-dark bg-primary -->
        <div class="container-fluid">
            <div class="collapse navbar-collapse border-top border-bottom" id="main_nav">
            	<ul class="navbar-nav" *ngIf="menu">
                	<ng-container *ngFor="let item of menu; let i = index">
                      <li class="nav-item dropdown" [ngClass]="item.title === 'הורים' || item.title === 'מטפלים' || item.title === 'מוסדות חינוכיים' ? 'navbar-border text-lightblue f-weight-bold' : ''">
                      	<a
                              class="dropdown-toggle font-montserrat-bold highlight"
                              [class]="item.childClass"
                              [ngClass]="{'text-red':item.title==='SALE', 'dropdown-toggle': item.level_2?.length > 0 ,
                                'text-lightblue':item.title === 'הורים' ||  item.title === 'מטפלים' ||item.title === 'מוסדות חינוכיים'
                                  
                              }"
                              id="navbarDropdown"
                              (click)="goToPage(item.title,item.category_id)"
                              [routerLink]="
                                item.has_child
                                  ? null
                                  : [
                                      '/products',
                                      item.category_id,
                                      {
                                     
                                        c1: item.category_id
                                      }
                                    ]
                              "
                              >{{ item.title }} <i class="" aria-hidden="true"></i>
                              <span
                                [ngClass]="
                                  item.has_child &&
                                  (item.title === 'הורים' ||
                                    item.title === 'מטפלים' ||
                                    item.title === 'מוסדות חינוכיים')
                                    ? 'fa fa-chevron-down mx-1 has-arrow1 '
                                    : item.has_child
                                    ? 'fa fa-chevron-down mx-1 has-arrow2'
                                    : ''
                                "
                              ></span>
                            </a>
                            <ul class="dropdown-menu" *ngIf="item.has_child">
                            	<li *ngFor="let l2 of item.level_2" [ngClass] = " (item.title === 'משחקים' || item.title === 'מתקני חצר' || item.title === 'ריהוט') ? 'has-megasubmenu' : 'has-megasubmenu open-to-left'">
                                	<a
                                        class="dropdown-item"
                                        [routerLink]="
                                          l2.has_child
                                            ? null
                                            : [
                                                '/products',
                                                l2.category_id,
                                                {
                                                  
                                                  c1: item.category_id,
                                                
                                                  c2: l2.category_id
                                                }                          ]
                                        "
                                        [ngClass]="{ 'dropdown-toggle': l2.level_2?.length > 0 }">
                                        {{ l2.title }}
                                        <span [ngClass] = "(item.title === 'משחקים' || item.title === 'מתקני חצר' || item.title === 'ריהוט') ? 'custom_arrow' : 'custom_arrow'" *ngIf="l2.level_3">&nbsp;&nbsp;&nbsp; &raquo;</span>
                                    </a>
                                    <div class="megasubmenu dropdown-menu" *ngIf="l2.has_child">
                                        <div class="headers_loop" *ngFor="let l3 of l2.level_3">
                                            <div class="column-stack duplicate-column-stack" *ngIf="l3.has_child == 0" >
                                              <ul>
                                                <li *ngFor="let l33 of l2.level_3">
                                                    <a *ngIf="l33.has_child == 0" [routerLink]="[
                                                          '/products',
                                                          l33.category_id,
                                                          {
                                                          
                                                            c1: item.category_id,
                                                          
                                                            c2: l2.category_id,
                                                      
                                                            c3: l33.category_id
                                                          }  
                                                        ]
                                                  ">{{l33.title}}</a>
                                                    
                                                </li>
                                              </ul>
                                            </div>
                                            <div class="headers" *ngIf="l3.level_4?.length > 0">
                                              <div class="column-header">
                                                <a
                                                  class=""
                                                  [ngClass]="{ 'dropdown-toggle': l3.level_3?.length > 0 }"
                                                  [routerLink]="
                                                    l3.has_child
                                                      ? null
                                                      : [
                                                          '/products',
                                                          l3.category_id,
                                                          {
                                                       
                                                            c1: item.category_id,
                                                          
                                                            c2: l2.category_id,
                                                         
                                                            c3: l3.category_id
                                                          }  
                                                        ]
                                                  "                                              
                                                  >{{ l3.title }}</a>
                                              </div>
                                            </div>
                                            
                                            <div class="column-stack" *ngIf="l3.level_4?.length > 0">
                                                    <ul *ngIf="l3.has_child">
                                                        <li *ngFor="let l4 of l3.level_4">
                                                        <a
                                                          [routerLink]="[
                                                            '/products',
                                                            l4.category_id,
                                                              {
                                                   
                                                                c1: item.category_id,
                                                            
                                                                c2: l2.category_id,
                                                         
                                                                c3: l3.category_id,
                                                     
                                                                c4: l4.category_id
                                                              } 
                                                          ]"
                                                          >{{ l4.title }}</a
                                                        >
                                                      </li>
                                                    </ul>
                                            </div>
                                        </div>
                                     </div>
                                </li>
                            </ul>
                      </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </nav>
</div>