<div class="textAlign">
  <a routerLink="/expert" class="text-dark">
    <!-- {{ "EXPERT.BackExpert" | translate }} -->
    {{homeBackExpert}}
  </a>
</div>

<div *ngIf="expertData" class="mt-2">
  <div class="row">
    <div class="col-md-12 textLeft">
      <h4 class="expert-detail-title my-2">{{ expertData.name }}</h4>
      <p class="expert-detail-subtitle">{{ expertData.profession }}</p>
      <img [src]="expertData.image" class="img-fluid my-2" />
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-9 border-left">
      <p class="expert-description-text" [innerHTML]="expertData.bio"></p>
    </div>
    <div class="col-md-3">
      <p class="expert-description-text">
        Angelica focuses on blah blah.Here goes short description of expertise
        and skills
      </p>
      <p class="expert-descrtiption-title">Awards and Achievements</p>
      <p class="expert-description-text">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry.
      </p>
      <button
        class="linkedin-profile-btn text-uppercase"
        routerLink="./details"
      >
        LinkedIn Profile
      </button>

      <div class="col-md-12 mt-3">
        <p class="font-montserrat-bold text-black">
          <!-- {{ "EXPERT.RecommendedProduct" | translate }} -->
          מוצרים מומלצים
        </p>
        <div class="row">
         
          <div class="col-md-4 p-1 text-center">
            <img src="assets/images/2.png" class="img-fluid" />
          </div>
          <div class="col-md-6">
            <p class="small text-black mb-0 font-montserrat-bold mt-2">
              Short product title
            </p>
            <p class="mb-0">
              <span class="font-montserrat-bold text-lightblue"
                > 88.00 <i class="fa fa-shekel"></i></span
              >
              <br />
              <span class="small text-light-gray"
                ><del> 123.00 <i class="fa fa-shekel"></i></del></span
              >
            </p>
          </div>
          <div class="col-md-2">
            <p>
              <span class="pull-right"
                ><a class="btn btn-black btn-rounded btn-sm mt-3 ml-2"
                  ><strong class=""
                    ><i class="fa fa-search-plus"></i></strong></a
              ></span>
            </p>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-4 p-1 text-center">
            <img src="assets/images/2.png" class="img-fluid" />
          </div>
          <div class="col-md-6">
            <p class="small text-black mb-0 font-montserrat-bold mt-2">
              Short product title
            </p>
            <p class="mb-0">
              <span class="font-montserrat-bold text-lightblue"
                > 88.00 <i class="fa fa-shekel"></i></span
              >
              <br />
              <span class="small text-light-gray"
                ><del> 123.00 <i class="fa fa-shekel"></i></del></span
              >
            </p>
          </div>
          <div class="col-md-2">
            <p>
              <span class="pull-right"
                ><a class="btn btn-black btn-rounded btn-sm mt-2 ml-2"
                  ><strong class=""
                    ><i class="fa fa-search-plus"></i></strong></a
              ></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="height: 200px;"></div>
</div>
<app-why-buy-from-us></app-why-buy-from-us>
