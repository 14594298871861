import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '@core/services/general.service';
import { ProductService } from '@core/services/product.service';
import { UiService } from '@core/services/ui.service';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { json } from 'express';

@Component({
  selector: 'app-header1',
  templateUrl: './header1.component.html',
  styleUrls: ['./header1.component.scss']
})
export class Header1Component implements OnInit,OnChanges {
  searchText = "";
  isCollapsed;
  ik=0;
  mouseover(i){

   
    this.ik=i;
  }
  menu; //= JSON.parse(localStorage.getItem("category"));
  @Output() navbarStatus = new EventEmitter();
  @Output() chnageDetection=new EventEmitter();
  @Input() change:string;
  currentLang: string = this.translate.currentLang;
  @ViewChild(NgbDropdown)
  private dropdown: NgbDropdown;
  keyword = 'item_name';
  searchItem = [];
  level3_menu_items: any;
  constructor(
    private router: Router,
    private generalService: GeneralService,
    public sideNavService: UiService,
    private translate: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef,
    private productService: ProductService
  ) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
      // if (
      //   this._changeDetectorRef !== null &&
      //   this._changeDetectorRef !== undefined &&
      //   !this._changeDetectorRef.detach
      // ) {
      this._changeDetectorRef.detectChanges();
      // }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }

  ngOnInit() {
    this.generalService.getCategory().subscribe((res: any) => {
	
      this.menu = res.message;
	  const temp_items = {};
	  this.menu.forEach(function(val,indx) { 
	    let level2_items = val.level_2;

		if(level2_items) {
		    level2_items.forEach(function(val2,indx2) {
			    let level3_items = val2.level_3; 
				if(level3_items) {
				  
				    level3_items.forEach(function(val3,indx3) {
					    if(val3.has_child > 0) {
						    if(typeof(temp_items[val2.category_id]) == "undefined")
	  						temp_items[val2.category_id] = [];
							if(typeof(temp_items[val2.category_id]['level_3']) == "undefined")
							//temp_items[val2.category_id]['level_3'] = [];
							temp_items[val2.category_id].push(val3);
							//temp_items.val2.category_id.'level_3' = val3;
					
						}
					});
				}
			});
		}
	  });
	  this.level3_menu_items = temp_items;
	
      localStorage.setItem("category", JSON.stringify(res.message));
    });
  }
  
  selectEvent(item) {

    this.searchProduct(item);
    // do something with selected item
  }


  ValueReturn(item,l2?,l3?,l4?){
    let obj: any = {};
    if(item && !l2 && !l3 && !l4){
      obj = {
        l1: item
      }
    }else if(item && l2 && !l3 && !l4){
      obj = {
        l1: item,
        l2: l2
      }
    }else if(item && l2 && l3 && !l4){
      obj = {
        l1: item,
        l2: l2,
        l3: l3
      }
    }else if(item && l2 && l3 && l4){
      obj = {
        l1: item,
        l2: l2,
        l3: l3,
        l4: l4
      }
    }
    return {...obj};
  }

  onChangeSearch(val: string) {


    this.searchProduct(val)
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  
  onFocused(e){

    this.searchProduct(e);
    // do something when input is focused
  }

  onProductDetail(code,name){
    
    this.router.navigate(["/products/details/",code]);
  }
  
  goToPage(route,category) {

    this.sideNavService.toggleSideNav();
    this.navbarStatus.emit(this.sideNavService.getToggleStatus());
    this.router.navigate([
      `/products/${route}`,
      {
        l1: route,
        c1: category
      },
    ]);
  }

  closeDropdown() {
    this.dropdown.close();
  }

  // goToCheckout() {
  //   if(this.userService.checkLogin()){
  //     this.router.navigateByUrl("/account/checkout");
  //   }else{
  //     const modalRef = this.modalService.open(LoginComponent);
  //     modalRef.componentInstance.type = "main";
  //     modalRef.componentInstance.guest = "yes";
  //   }
  // }

  search() {
    
    this.router.navigate(["/products/", { keyword: this.searchText }]);
  }

  searchProduct(keyword? :string | any) {

    if(typeof(keyword)=='object'){
      if(keyword.item_code!=undefined){
        this.productService.searchProduct(keyword?.item_code).subscribe((res: any) => {
          this.searchItem = res.message.items;
        });
      }
      
    }else{
      this.productService.searchProduct(keyword).subscribe((res: any) => {
        this.searchItem = res.message.items;
      });
    }
   
  }

  toggleSideNav() {
    this.sideNavService.toggleSideNav();
    this.navbarStatus.emit(this.sideNavService.getToggleStatus());
  }

  closeSideBar() {
    if (this.sideNavService.getToggleStatus()) {
      this.sideNavService.toggleSideNav();
      this.navbarStatus.emit(false);
      this.isCollapsed = true;
    }
    //
  }
  getRight(i){
    return (-110-i)
  }

  clicke1(){
    let dd=document.getElementById('id22');
    // pointer-events: none;
   }
  clicke(){
    localStorage.setItem('paginationArray',JSON.stringify([]));
   let dd=document.getElementById('id22');
  //  dd.style.display="none";
  this.chnageDetection.emit("hii");

  }

}
