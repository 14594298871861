import { Component, OnInit, ViewChild} from "@angular/core";
import { OwlCarousel } from 'ngx-owl-carousel';

@Component({
  selector: "app-brand-work",
  templateUrl: "./brand-work.component.html",
  styleUrls: ["./brand-work.component.css"]
})
export class BrandWorkComponent implements OnInit {
  $: any;
  carouselOptions: any;
  images: any;
  @ViewChild('owlElement') owlElement: OwlCarousel;
  constructor() {
  	this.carouselOptions = {   

    margin: 25,
    nav: true,
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>"
    ],
    dots: true,
    responsiveClass: true,
    responsive: {
      0: {
        items:3,
        nav: true
      },
      600: {
        items: 4,
        nav: true
      },
      1000: {
        items: 6,
        nav: true,
        loop: false
      },
      1500: {
        items: 8,
        nav: true,
        loop: false
      }
    }
  };
    this.images = [
    {
      image: "assets/images/brands/ablenet.png",
      brand_url:"https://www.ablenetinc.com/"
    },
    {
      image: "assets/images/brands/fisher-price.png",
      brand_url:"https://www.fisher-price.com/in"
    },
    {
      image: "assets/images/brands/folkmanis.png",
      brand_url:"https://www.folkmanis.com/146/home.htm"
    },
    {
      image: "assets/images/brands/lda.png",
      brand_url:"https://www.ldalearning.com/"
    },
    {
      image: "assets/images/brands/melisa.png",
      brand_url:"https://www.melissaanddoug.com/"
    },
    {
      image: "assets/images/brands/nathan.png",
      brand_url:"https://www.nathansports.com/"
    },
    {
      image: "assets/images/brands/ravensburg.png",
      brand_url:"https://www.ravensburger.com/start/index.html"
    },
    {
      image: "assets/images/brands/schubi.png",
      brand_url:"https://www.schubi.com/ch/de/"
    }
  ];
  }

  ngOnInit() {}

}