<div>
  <div class="row">
    <div class="col-md-12 mt-2">
      <h4 class="font-montserrat-regular">
        <strong>{{ title }}</strong>
      </h4>
      <p class="mb-0">
        Showing products {{ currentlyShowingFromProduct }} -
        {{ currentlyShowingToProduct }} of {{ totalRecords }}
      </p>
      <hr />
    </div>
    <div class="col-md-2">
      <a href="#" class="text-lightblue mr-2"
        ><i class="fa fa-th-list fa-2x"></i
      ></a>
      <a href="#" class="text-light-gray"
        ><i class="fa fa-th-large fa-2x"></i
      ></a>
    </div>

    <div class="col-md-5">
      <div class="form-inline">
        <label class="mr-2">Sort By:</label>
        <select class="form-control form-rounded">
          <option>Bestseller</option>
          <option>Bestseller</option>
          <option>Bestseller</option>
          <option>Bestseller</option>
          <option>Bestseller</option>
        </select>
      </div>
    </div>

    <div class="col-md-5">
      <div class="form-inline pull-right">
        <label class="mr-2">Items Per Page:</label>
        <select
          class="form-control form-rounded"
          (change)="getCountRecords($event.target.value)"
        >
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="25">25</option>
          <option value="50"> 50</option>
        </select>
        <label class="ml-2"
          ><a (click)="getAllProducts()" class="text-lightblue"
            >Show All</a
          ></label
        >
      </div>
    </div>
  </div>
  <div class="row" *ngIf="productList?.length > 0">
    <div
      class="col-md-4 col-6 col-sm-6 border-light  mt-3 mb-3"
      *ngFor="
        let item of productList
          | paginate
            : {
                itemsPerPage: pageSize,
                currentPage: currentPage,
                totalItems: totalRecords
              };
        let i = index
      "
    >
      <a [routerLink]="['/products', item.name]">
        <app-product-box [listItem]="item"></app-product-box>
      </a>
    </div>
  </div>
</div>
<div class="border-top border-bottom p-1">
  <!-- <div class="dot bg-lightblue"><a class="page-num">1</a></div>
  <div class="dot"><a>2</a></div>
  <div class="dot"><a>3</a></div>
  <div class="dot"><a>4</a></div> -->
  <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
</div>

