 //export const BASEURL = "https://erp.playfunction.co.il";
//export const BASEURL = "https://playfunction.indictranstech.com";
export const BASEURL ="https://devuat.playfunction.co.il";
export enum PaymentType {
  card = "תשלום באמצעות כרטיס אשראי", // credit by credit card
 // institution = "תשלום באמצעות קוד"  // credit by code
  // institution = "תשלום בטלפון (נציג יחזור אליך)" // payment by phone
  institution = "תשלום באמצעות נציג טלפונ"
}

export enum shippingOption {
  homeDelivery = "Fast delivery to the collection point near your home - NIS 10",
  registeredSubsidized = "Registered mail at subsidized price - NIS 10",
  homeDeliverySubsidies = "משלוח מהיר עד הבית/ארגון במחיר מסובסד -29 ש\"ח",
  selfCollection = "איסוף עצמי מהסניף שלנו בירושלים (מומלץ לבדוק זמינות מלאי ) = 0 ש\"ח"
}
