<div class="textAlign">
  <a routerLink="/" class="text-dark">
    <i class="fa fa-chevron-left" aria-hidden="true"></i>
    <!-- {{ "HELP.Back" | translate }} -->
    חזרה לדף הבית 
  </a>
</div>

<div class="mt-5">
  <div class="row">
    <div class="col-md-12">
      <h4 class="help-maintitle mb-3">
        <!-- {{ "HELP.Question" | translate }} -->
        יש לכם שאלה?
      </h4>
      <p class="help-subtitle mb-4">
        <!-- {{ "HELP.Description" | translate }} -->
        אנא עיין בהמשך לשאלות הנפוצות שלנו לפי הקטגוריות שנמצאות להלן 
      </p>
      <div class="row">
        <div
          class="col-md-5 my-4 ml-5"
          *ngFor="let item of faqList; let odd = odd; let even = even"
        >
          <div class="d-flex">
            <div class="ml-2">
              <span class=""
                ><img src="../../../assets/images/help-icon.PNG" width="35"
              /></span>
            </div>
            <div class="">
              <h5 class="mr-2 help-que-title">{{ item.question }}</h5>
              <p class="mr-2 my-1 help-ans-title text-justify">
                {{ item.answer }}
              </p>
            </div>
          </div>

          <div></div>
        </div>

        <!-- <div class="col-md-6 mt-3">
          <div *ngFor="let item of questions" class="mb-3">
            <h6>
              <span class="text-lightblue"><i class="fa fa-file"></i></span>
              <span class="ml-2">{{ item.title }}</span>
            </h6>
            <p class="ml-4">{{ item.answer }}</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>

  <div class="row" style="height: 100px;"></div>

  <app-why-buy-from-us></app-why-buy-from-us>
</div>
