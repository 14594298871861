<div class="col-md-12">
  <div class="item-grid border">
    <div class="col-md-3 item-grid__image">
      <div>
        <a  (click)="onDetail(listItem,false)">
        <img
          *ngIf="listItem.image"
          src="{{ baseURL }}{{ listItem.image }}"
          class="img-conf"
          alt=""
        />
        <img
          *ngIf="!listItem.image"
          src="assets/images/no-image.png"
          alt=""
          width="400"
          class="img-conf"
        />
        </a>
      </div>
    </div>
    <div class="col-md-5 item-grid__description">
      <h6 class="text-style-1 text-black">{{ listItem.item_name }}</h6>
      <p class="text-style-2" *ngIf="listItem.age_range">
        <!-- {{ "PRODUCT.AgeRange" | translate }}  -->
        {{ listItem.age_range }}
        גיל
      </p>
      <p *ngIf="listItem?.selling_price > 0">
        <span class="font-montserrat-bold text-lightblue mr-2"
          ><i class="fa fa-shekel"></i>{{ listItem.after_discount }}</span
        >

        <del class="text-light-gray small">
          <i class="fa fa-shekel"></i>{{ listItem?.selling_price }}
        </del>
      </p>
      <ng-template #elsepart>
        <div class="cart-for-quotation text-lightblue">
          <!-- {{ "PRODUCT.AddToCartForQuote" | translate }} -->
          הוסף לסל הצעת מחיר 
        </div>
      </ng-template>
    </div>

    <div class="col-md-4 item-grid__cart">
      <div>
        <button
        (click)="addToCart('',listItem)"
          class="item-grid__cart--cartbtn add-to-cart-btn-box mt-2"
        >
          <strong class="font-size14 mr-1">
            <!-- {{ "PRODUCT.AddToCart" | translate }} -->
            הוסף לסל 
            <svg class="icon icon-cart">
              <use xlink:href="#009-shopping-cart"></use>
            </svg>
          </strong>
        </button>
        <button href="#" class="item-grid__cart--magnifier quick-view-btn mt-2">
          <strong><i class="fa fa-search-plus"></i></strong>
        </button>
      </div>
    </div>
  </div>
</div>
