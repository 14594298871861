<div>
    <div class="row">
        <div class="col-md-12">
            <span class="category-title">
        {{ title }}
      </span>
            <p class="ml-1 mt-3 mb-0 showing-product-title">
                <!-- {{ "PRODUCT.ShowingProducts" | translate }} -->
                תוצאות חיפוש עבור {{ currentlyShowingFromProduct }} - {{ currentlyShowingToProduct }} מתוך {{ totalRecords }}
            </p>
            <hr />
        </div>

        <div class="col-md-2 col-12 pt-2 d-none d-md-block">
            <a style="margin-left: 12px;" class="mr-3" (click)="viewTypeFn(2)" [ngClass]="{ 'text-lightblue': viewType === 'grid','text-light-gray': viewType !== 'grid' }"><i
          class="fa fa-th fa-2x"></i></a>
            <a class="text-light-gray" (click)="viewTypeFn(1)" [ngClass]="{ 'text-lightblue': viewType === 'list' , 'text-light-gray': viewType !== 'list' }"><i
          class="fa fa-list fa-2x"></i></a>
        </div>

        <div class="col-md-5  col-5 pl-md-1">
            <div class="form-inline selectParent">
                <label class="mr-3" style="margin: 0 10px;">
          <!-- {{ "PRODUCT.SortBy" | translate }}  -->
          מיין לפי
        </label>
                <select class="form-control form-rounded sort-by-dropdown" (change)="onChangeType($event.target.value)">
          <option value="">
            <!-- {{ "PRODUCT.Select" | translate }}  -->
            בחר
          </option>
          <option [value]="item.value" *ngFor="let item of itemTypeArr">{{
            item.label
            }}</option>
        </select>
            </div>
        </div>

        <div class="col-md-4  col-4 text-truncate" style="margin-left: -4%;">
            <div class="form-inline selectParent pull-right">
                <label class="ml-4" style="margin: 0px 3px 0px 0px;">
          פריטים לעמוד
        </label>
                <select class="form-control form-rounded item-perpage-dropdown" [(ngModel)]="pageSize" (change)="getCountRecords($event.target.value)">
          <option value="12">12</option>
          <option value="15">15</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
                <!-- <div (click)="getAllProducts()" class="form-control form-rounded item-perpage-dropdown mr-2 back">
                    <a class="pointer" style="color: black;">

                        <b>הצג הכל</b>
                    </a>
                </div> -->
            </div> 
        </div>
        <div class="col-md-1  col-1">
            <div (click)="getAllProducts()" style="margin-right: 0% !important;" class="form-control form-rounded item-perpage-dropdown mr-2 back mTop">
                <a class="pointer" style="color: black;">

                    <b>הצג הכל</b>
                </a>
            </div>
        </div>
        <div class="col-md-12">
            <hr />
        </div>
    </div>
    <div class="border-top-bottom mt-3 p-1 text-center" *ngIf="productList?.length > 0">
        <!----- Added By Ashwin - 10/5/2022 ----->
        <pagination-controls (pageChange)="currentPage = $event" pages="1" (click)="pageChanged($event,pageSize,currentPage)" previousLabel="" nextLabel="" id="itemListPagination">
        </pagination-controls>
    </div>
    <div *ngIf="productList?.length > 0; else elseBlock" class="row">

        <ng-container *ngFor="
        let item of productList | paginate: {
                itemsPerPage: pageSize,
                currentPage: currentPage_num,
                totalItems: totalRecords,
                id:'itemListPagination'
              };
        let i = index
      ">

            <div (click)="onItems(list)" *ngIf="viewType === 'grid'" class="col-md-4 col-sm-6 col-12 border-light my-3">
                <!-- <a [routerLink]="['/products/details', item.item_code]"> -->

                <div>

                    <app-item-details [pageNo]="currentPage" [listItem]="item" [totalRecords]="totalRecords" [pageSize]="pageSize" [list]="list"></app-item-details>
                </div>
                <!-- </a> -->
            </div>
            <div *ngIf="viewType === 'list'" class="w-100">
                <app-item-list-view  [pageNo]="currentPage" [listItem]="item" [totalRecords]="totalRecords" [pageSize]="pageSize"></app-item-list-view>
            </div>
        </ng-container>
    </div>

    <ng-template #elseBlock>
        <div class="text-center mt-5">
            <div class="alert alert-light" role="alert">
                לא נמצאו פריטים
            </div>
        </div>
    </ng-template>
</div>
<div class="border-top-bottom mt-3 p-1 text-center" *ngIf="productList?.length > 0">
    <!-- <div class="dot bg-lightblue"><a class="page-num">1</a></div>
  <div class="dot"><a>2</a></div>
  <div class="dot"><a>3</a></div>
  <div class="dot"><a>4</a></div> -->
    <!-- <pagination-controls 
  (pageChange)="currentPage = $event"
  pages="1"
  (click)="pageChanged($event,pageSize,currentPage)"
  previousLabel=""
  nextLabel=""
  id="itemListPagination"
  ></pagination-controls> -->
</div>