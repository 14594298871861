import { ProductFilterComponent } from "./product-filter/product-filter.component";
import { ProductListComponent } from "./product-list/product-list.component";
import { AccordionComponent } from "./accordion/accordion.component";
import { ToggleComponent } from "./accordion/toggle/toggle.component";
import { ProductBoxComponent } from "./product-list/product-box/product-box.component";

export const components = [
  ProductFilterComponent,
  ProductListComponent,
  AccordionComponent,
  ToggleComponent,
  ProductBoxComponent
];
