import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class UiService {
  hideSideNav: boolean = false;

  constructor() {}

  toggleSideNav(): void {
    this.hideSideNav = !this.hideSideNav;
  }

  getToggleStatus() {
    return this.hideSideNav;
  }
}
