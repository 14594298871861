<div class="row">
    <div class="col-md-12">
    <form [formGroup]="contactUsForm" (ngSubmit)="submitContactUs()">

      <div class="row">
        <div class="col-md-12">
          <div class="checkout-subtitle bg-graybox p-2 mt-2">
            Contact Us
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group mt-4">
            <input type="text" class="form-control form-rounded" placeholder="שם" formControlName="name"
              [ngClass]="errorMark('name')" maxlength="40" />
            <div *ngIf="formErrors.name" class="text-danger">
              <span [innerHTML]="formErrors.name"></span>
            </div>
          </div>
        </div>

        <div class="col-md-6">
        </div>
        <!-- <div class="col-md-6">
          <div class="form-group mt-4">
            <input type="tel" class="form-control form-rounded" placeholder="טלפון" formControlName="phone"
              [ngClass]="errorMark('phone')" maxlength="40" />
            <div *ngIf="formErrors.phone" class="text-danger">
              <span [innerHTML]="formErrors.phone"></span>
            </div>
          </div>
        </div> -->
        <div class="col-md-6">
            <div class="form-group mt-4">
              <input type="text" class="form-control form-rounded" placeholder="טלפון"
                formControlName="phone" [ngClass]="errorMark('phone')" maxlength="40" />
              <div *ngIf="formErrors.phone" class="text-danger">
                <span [innerHTML]="formErrors.phone"></span>
              </div>
            </div>
        </div>

        <div class="col-md-6">
          <div class="form-group mt-4">
            <input type="text" class="form-control form-rounded" placeholder="דואר אלקטרוני"
              formControlName="email_id" [ngClass]="errorMark('email_id')" maxlength="40" />
            <div *ngIf="formErrors.email_id" class="text-danger">
              <span [innerHTML]="formErrors.email_id"></span>
            </div>
          </div>
        </div>

        <div class="col-md-12 my-3">
          <div class="form-group">
            <textarea class="form-control form-rounded-textarea" formControlName="message" placeholder="הערות למוכר"></textarea>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-md-3 mt-3 pl-1">
          <button type="submit" class="checkout-place-order-btn text-uppercase font-montserrat-bold">
            Contact Us
          </button>
        </div>
      </div>
    </form>
  </div>
</div>