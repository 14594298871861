import { Component, OnInit, Input } from "@angular/core";
import { BASEURL } from "app/app.config";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from '@core/services/user.service';
import { CartService } from '@core/services/cart.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: "app-item-list-view",
  templateUrl: "./item-list-view.component.html",
  styleUrls: ["./item-list-view.component.scss"]
})
export class ItemListViewComponent implements OnInit {
  @Input() listItem;
  @Input() pageNo;
  @Input() pageSize;
  @Input() totalRecords;
  queryParams: any;
  baseURL = BASEURL;
  quantity: Number = 1;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private cartService: CartService,
  ) {
        this.route.params.subscribe(param => {
      this.queryParams = param;
    });
  }

  ngOnInit() {}

  onDetail(item,isQuerry){
   

    let navigateTo = "/products/details/" + item.item_code;
    if(isQuerry){
      this.router.navigate([navigateTo], {
        relativeTo: this.route,
      //   queryParams: {
		  // l1: this.queryParams.l1,
		  // l2: this.queryParams.l2,
      //     detailRouteType : 'product-list',
      //     pageChange: JSON.stringify(this.pageNo),
      //     productInfo: JSON.stringify(item),
      //     navigateTo: JSON.stringify(navigateTo),
      //     navigateBackTo: JSON.stringify(this.router.url),
      //     totalRecords : JSON.stringify(this.totalRecords),
      //     pageSize: JSON.stringify(this.pageSize),
      //   },
      });
    }else{
      this.router.navigate([navigateTo], {
        relativeTo: this.route,
        queryParams: {
          detailRouteType : 'product-list',
          pageChange: JSON.stringify(this.pageNo),
          productInfo: JSON.stringify(item),
          navigateTo: JSON.stringify(navigateTo),
          navigateBackTo: JSON.stringify(this.router.url),
          totalRecords : JSON.stringify(this.totalRecords),
          pageSize: JSON.stringify(this.pageSize),
        },
      });
    }
  }

  
  addToCart(type, data){
    if (this.userService.checkLogin()) {
      this.cartService.addToCart(data, type, +this.quantity);
    } else {
      if(localStorage.getItem("guest_session_id")){
        this.cartService.addToCart(data, type, +this.quantity);
      }else{
        let val = uuidv4();
        localStorage.setItem("guest_session_id", val);
        this.cartService.addToCart(data, type, +this.quantity);
      }
      // const modalRef = this.modalService.open(LoginComponent);
      // modalRef.componentInstance.type = "main";
    }
  }
}
