import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "@core/services/user.service";

import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import {
  Router,
  ActivatedRouteSnapshot,
  ActivatedRoute,
} from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { environment } from "@env/environment";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  @Input() public type;
  @Input() public guest;
  @Input() public routing;
  loginForm: FormGroup;
  fieldTextType: boolean = false;
  guestSession = this.userService.getGuestSession();
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    public activeModal: NgbActiveModal,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService
  ) {}

  // administrator;
  // playfunction@123
  ngOnInit() {
    this.loginForm = this.fb.group({
      userName: [environment.hmr ? "data@play.com" : "", Validators.required],
      password: [environment.hmr ? "demo@123" : "", Validators.required],
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  onSubmitForm() {
    const { value, valid } = this.loginForm;
    if (valid) {
      let body = {
        usr: value.userName,
        pwd: value.password,
      };
      if (!this.userService.checkLogin()) {
        body["guest_session_id"] = this.guestSession;
      }
      let formData = new FormData();
      formData.append("data", JSON.stringify(body));
      this.userService.login(formData).subscribe(
        (res: any) => {
          if (res.status_code === 200) {
            localStorage.setItem("sid", res.sid);
            sessionStorage.setItem("sid", res.sid);
            localStorage.setItem("fullName", res.full_name);
            localStorage.setItem("userName", value.userName);
            localStorage.setItem("quote_id", res.quote_id);
            localStorage.setItem("proposal_id", res.proposal_id);
            localStorage.removeItem('guest_session_id');
            // this.cookieService.set("sid", res.sid);
            // this.cookieService.set("user_id", res.email);
            // this.cookieService.set("full_name", res.full_name);
            this.userService.setUserDetails(res.full_name);
            if (this.type === "register") {
              this.activeModal.dismiss();
              this.router.navigate(["/"]);
            } else if((this.type === "checkout")){
              this.activeModal.dismiss();
              this.router.navigateByUrl("/account/checkout");
            } else if((this.type === "request")){
              this.activeModal.dismiss();
              this.router.navigateByUrl("/account/pricing-request");
            } else {
              window.location.reload();
            }
          } else {
            alert("Invalid credentials");
          }
        },
        (err) => {
          alert("Invalid credentials");
        }
      );
    }
  }

  goToCheckout(){
    this.activeModal.dismiss();
    this.router.navigateByUrl("/account/checkout");
  }

  gotoRegister() {
    this.activeModal.dismiss();
    this.router.navigate(["/register"]);
  }
  forgotPassword() {
    this.activeModal.dismiss();
    this.router.navigate(["/forgot-password"]);
  }
}
