import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UiService } from "@core/services/ui.service";
import { Router } from "@angular/router";

declare var $: any;

@Component({
  selector: "app-main-navbar",
  templateUrl: "./main-navbar.component.html",
  styleUrls: ["./main-navbar.component.scss"]
})
export class MainNavbarComponent implements OnInit {
  isCollapsed;
  @Input() menuItem;
  @Output() navbarStatus = new EventEmitter();
  menu = JSON.parse(localStorage.getItem("category"));
  showMenu: string;
  isShown: boolean;
  level: string
  levelOneIndex: any;
  levelTwoIndex: any;
  levelThreeIndex: any;
  levelOneTitle: any;
  levelTwoTitle: any;
  levelThreeTitle: any;

  levelOnecategory: any;
  levelTwocategory: any;
  levelThreecategory: any;

  
  constructor(public sideNavService: UiService, private router: Router) { }
  ngOnInit() {

    this.level = 'zero'
  }

  goToPage(route) {
    this.sideNavService.toggleSideNav();
    this.navbarStatus.emit(this.sideNavService.getToggleStatus());
    this.router.navigate([
      `/products/${route}`,
      {
        l1: route
      }
    ]);

  }

  findLevel(level, i, titleText, child,category_id) {
    if (child == 0) {
      this.sideNavService.toggleSideNav();
      this.navbarStatus.emit(this.sideNavService.getToggleStatus());
    }

    this.level = level
    if (level === 'one') {
      this.levelOneIndex = i;
      this.levelOneTitle = titleText;
      this.levelOnecategory=category_id;
    } else if (level === 'two') {
      this.levelTwoIndex = i;
      this.levelTwoTitle = titleText;
      this.levelTwocategory=category_id;
    } else if (level === 'three') {
      this.levelThreeIndex = i;
      this.levelThreeTitle = titleText;
      this.levelThreecategory=category_id;
    } else {
      this.levelOneIndex = 0;
      this.levelTwoIndex = 0;
      this.levelThreeIndex = 0;
      this.levelThreeTitle = '';

    }
  }

  findAllCategoriesLevel(level) {
    this.findLevel('zero', 0, '', 1,'')
  }


  toggleSideNav() {
    this.sideNavService.toggleSideNav();
    this.navbarStatus.emit(this.sideNavService.getToggleStatus());
  }

  closeSideBar() {
    if (this.sideNavService.getToggleStatus()) {
      this.sideNavService.toggleSideNav();
      this.navbarStatus.emit(false);
      this.isCollapsed = true;
    }
    //
  }

}

