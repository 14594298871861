import { Component, OnInit, Input } from "@angular/core";
import { BASEURL } from "app/app.config";

@Component({
  selector: "app-product-box",
  templateUrl: "./product-box.component.html",
  styleUrls: ["./product-box.component.css"]
})
export class ProductBoxComponent implements OnInit {
  @Input() listItem;
  baseUrl = BASEURL;
  constructor() {}

  ngOnInit() {}
}
