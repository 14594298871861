import { Injectable } from "@angular/core";
import { HttpWrapperService } from "./http-wrapper.service";

@Injectable({
  providedIn: "root"
})
export class GeneralService {
  constructor(private http: HttpWrapperService) {}

  getFaq() {
    return this.http.get("get_faq");
  }

  getProjects(pageSize, pageIndex) {
    return this.http.get(
      `get_project_list?page_size=${pageSize}&page_index=${pageIndex}`
    );
  }

  getProject(projectId) {
    return this.http.get(`get_project_details?project_id=${projectId}`);
  }

  getExperts(pageSize, pageIndex) {
    return this.http.get(
      `get_expert_list?page_size=${pageSize}&page_index=${pageIndex}`
    );
  }
  getExpert(expert) {
    return this.http.get(`get_expert_details/?expert_id=${expert}`);
  }

  getBlogs(pageSize, pageIndex) {
    return this.http.get(
      `get_blog_list?page_size=${pageSize}&page_index=${pageIndex}`
    );
  }
  getBlog(blogId) {
    return this.http.get(`get_blog/?blog_id=${blogId}`);
  }

  getCategory() {
    return this.http.get(`get_category_tree_`);
  }
}
