<div>
  <div class="row justify-content-center">
    <div class="col-6 text-center">
      <div class="row" *ngIf="blogData">
        <div>
          <p class="small font-montserrat-bold text-danger text-center mb-1">
            {{ blogData.blog_category }}
          </p>
          <hr class="mb-2 mt-1 bg-danger w-25" />
          <h4 class="text-dark font-montserrat-bold text-center">
            {{ blogData.name }}
          </h4>
          <p class="small text-center">Author {{ blogData.author }}</p>
          <img
            *ngIf="blogData.image"
            src="{{ baseUrl }}{{ blogData.image }}"
            class="img-fluid"
          />
          <img
            *ngIf="!blogData.image"
            src="assets/images/no-image.png"
            class="img-fluid"
          />
          <p class="mt-3 blog-description-text" [innerHTML]="blogData.content"></p>
          <p class="text-center mb-4">
            <button class="btn blog-red-btn text-uppercase btn-rounded">
              Next Article
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-why-buy-from-us></app-why-buy-from-us>
