<div class="container-fluid">
    <div class="row" style="direction: initial;">
        <div class="col-md-4 mt-4 col-12 offset-md-4">
            <div class="card">
                <div class="card-header text-center border-bottom-0">
                    <a routerLink="/"><img src="assets/images/Play-function.png" width="150" class="img-fluid" /></a>
                </div>
                <div class="card-body p-2">
                    <h3 class="mt-4 mb-4">
                        <strong>
              <!-- {{ "REGISTER.CreateAccount" | translate }} -->
              צור חשבון 
            </strong>
                    </h3>
                    <div *ngIf="!isRegistrationSuccess">
                        <form [formGroup]="registrationForm" (ngSubmit)="submitForm()">
                            <div class="form-group">
                                <input type="text" class="form-control mt-4" placeholder="שם פרטי" formControlName="firstName" />
                                <!-- placeholder=" {{ 'REGISTER.FirstName' | translate }}" -->
                                <small class="text-danger" *ngIf="
                    isSubmitted &&
                    registrationForm.controls['firstName'].hasError('required')
                  ">
                  <!-- {{ "REGISTER.required" | translate }} -->
                  שדה חובה 
                </small>
                            </div>

                            <div class="form-group">
                                <input type="text" class="form-control mt-4" placeholder=" שם משפחה " formControlName="lastName" />
                                <!-- placeholder=" {{ 'REGISTER.Lastname' | translate }}" -->
                                <small class="text-danger" *ngIf="
                    isSubmitted &&
                    registrationForm.controls['lastName'].hasError('required')
                  ">
                  <!-- {{ "REGISTER.required" | translate }} -->
                  שדה חובה 
                </small>
                            </div>

                            <div class="form-group">
                                <!--תכלת מרדכי, ירושלים 9439623-->
                                <textarea style="text-align: right;" type="text" class="form-control mt-4" placeholder=" כתובת " formControlName="address" rows="2">
                  <!-- placeholder=" {{ 'REGISTER.address' | translate }}" -->
                </textarea>
                            </div>

                            <div class="form-group">
                                <input type="text" class="form-control mt-4" placeholder="דואר אלקטרוני " formControlName="email" />
                                <!-- placeholder=" {{ 'REGISTER.Email' | translate }}" -->

                                <small class="text-danger" *ngIf="
                    isSubmitted &&
                    registrationForm.controls['email'].hasError('required')
                  "> 
                  <!-- {{ "REGISTER.required" | translate }} -->
                  שדה חובה 
                </small>
                            </div>

                            <div class="form-group">
                                <input type="text" class="form-control mt-4" formControlName="mobile" placeholder="טלפון נייד " />
                                <!-- placeholder=" {{ 'REGISTER.MobileNo' | translate }}" -->
                                <small class="text-danger" *ngIf="
                    isSubmitted &&
                    registrationForm.controls['mobile'].hasError('required')
                  "> 
                  <!-- {{ "REGISTER.required" | translate }} -->
                  שדה חובה 
                </small>
                            </div>
                            <!-- <div class="form-group"> -->
                            <div class="form-group">
                                <div class="input-group">
                                    <!-- <label for=""> Password</label> -->
                                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" id="password" placeholder="סיסמה" formControlName="password" />
                                    <!-- placeholder=" {{ 'REGISTER.Password' | translate }}"  -->
                                    <div class="input-group-prepend">
                                        <button type="button" class="input-group-text" (click)="toggleFieldTextType()" style="border-radius: 0px 25px 25px 0px; background: #bfcedc6e">
                      <i [class]="fieldTextType ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                    </button>
                                    </div>
                                    <!-- <input
                    id="password"
                    formControlName="password"
                    placeholder=" {{ 'REGISTER.Password' | translate }}"
                    type="password"
                    class="form-control"
                    [ngClass]="
                      registrationForm.controls['password'].invalid
                        ? 'is-invalid'
                        : ''
                    "
                  /> -->

                                    <label *ngIf="isSubmitted" class="col d-none" [ngClass]="
                      registrationForm.controls['password'].hasError(
                        'required'
                      ) ||
                      registrationForm.controls['password'].hasError(
                        'minlength'
                      )
                        ? 'text-danger'
                        : 'text-success'
                    ">
                    <i [ngClass]="
                        registrationForm.controls['password'].hasError(
                          'required'
                        ) ||
                        registrationForm.controls['password'].hasError(
                          'minlength'
                        )
                          ? 'fa fa-times-circle'
                          : 'fa fa-check-circle-o'
                      ">
                    </i>

                    {{ "REGISTER.characters" | translate }}

                  </label>

                                    <label *ngIf="isSubmitted" class="col d-none" [ngClass]="
                      registrationForm.controls['password'].hasError(
                        'required'
                      ) ||
                      registrationForm.controls['password'].hasError(
                        'hasNumber'
                      )
                        ? 'text-danger'
                        : 'text-success'
                    ">
                    <i [ngClass]="
                        registrationForm.controls['password'].hasError(
                          'required'
                        ) ||
                        registrationForm.controls['password'].hasError(
                          'hasNumber'
                        )
                          ? 'fa fa-times-circle'
                          : 'fa fa-check-circle-o'
                      ">
                    </i>

                    {{ "REGISTER.number" | translate }}
                  </label>

                                    <label *ngIf="isSubmitted" class="col d-none" [ngClass]="
                      registrationForm.controls['password'].hasError(
                        'required'
                      ) ||
                      registrationForm.controls['password'].hasError(
                        'hasCapitalCase'
                      )
                        ? 'text-danger'
                        : 'text-success'
                    ">
                    <i [ngClass]="
                        registrationForm.controls['password'].hasError(
                          'required'
                        ) ||
                        registrationForm.controls['password'].hasError(
                          'hasCapitalCase'
                        )
                          ? 'fa fa-times-circle'
                          : 'fa fa-check-circle-o'
                      ">
                    </i>
                    {{ "REGISTER.CapitalCase" | translate }}
                  </label>
                                    <label *ngIf="isSubmitted" class="col d-none" [ngClass]="
                      registrationForm.controls['password'].hasError(
                        'required'
                      ) ||
                      registrationForm.controls['password'].hasError(
                        'hasSmallCase'
                      )
                        ? 'text-danger'
                        : 'text-success'
                    ">
                    <i [ngClass]="
                        registrationForm.controls['password'].hasError(
                          'required'
                        ) ||
                        registrationForm.controls['password'].hasError(
                          'hasSmallCase'
                        )
                          ? 'fa fa-times-circle'
                          : 'fa fa-check-circle-o'
                      ">
                    </i>
                    {{ "REGISTER.SmallCase" | translate }}
                  </label>
                                    <label *ngIf="isSubmitted" class="col d-none" [ngClass]="
                      registrationForm.controls['password'].hasError(
                        'required'
                      ) ||
                      registrationForm.controls['password'].hasError(
                        'hasSpecialCharacters'
                      )
                        ? 'text-danger'
                        : 'text-success'
                    ">
                    <i [ngClass]="
                        registrationForm.controls['password'].hasError(
                          'required'
                        ) ||
                        registrationForm.controls['password'].hasError(
                          'hasSpecialCharacters'
                        )
                          ? 'fa fa-times-circle'
                          : 'fa fa-check-circle-o'
                      ">
                    </i>

                    {{ "REGISTER.SpecialCharacter" | translate }}
                  </label>
                                </div>

                                <small class="text-danger" *ngIf="
                isSubmitted && registrationForm.controls['password'].hasError('required')">
                <!-- {{ "REGISTER.required" | translate }} -->
                שדה חובה 
                </small>

                                <!-- <div class="pull-right pointer" style="margin: -8px 7px 4px 0;">
                  <ng-template #tipContent>
                    <p>{{ "REGISTER.characters" | translate }}</p>
                    <p>{{ "REGISTER.number" | translate }}</p>
                    <p>{{ "REGISTER.CapitalCase" | translate }}</p>
                    <p>{{ "REGISTER.SmallCase" | translate }}</p>
                    <p>{{ "REGISTER.SpecialCharacter" | translate }}</p>
                  </ng-template>

                  <i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    [ngbTooltip]="tipContent"
                  ></i>
                </div> -->
                            </div>
                            <!-- </div> -->
                            <div class="form-group">
                                <div class="input-group">
                                    <input [type]="fieldTextType1 ? 'text' : 'password'" class="form-control " placeholder="אשר סיסמה " formControlName="confirmPassword" />
                                    <!-- placeholder="{{ 'REGISTER.ConfirmPassword' | translate }}"  -->
                                    <div class="input-group-prepend">
                                        <button type="button" class="input-group-text" (click)="toggleFieldTextType1()" style="border-radius: 0px 25px 25px 0px; background: #bfcedc6e">
                      <i [class]="fieldTextType1 ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                    </button>
                                    </div>
                                    <!-- <input
                  type="password"
                  class="form-control mt-4"
                  formControlName="confirmPassword"
                  placeholder=" {{ 'REGISTER.ConfirmPassword' | translate }}"
                /> -->

                                </div>
                                <small class="text-danger" *ngIf="
                isSubmitted &&
                registrationForm.get('confirmPassword').hasError('required')
              ">
              <!-- {{ "REGISTER.required" | translate }} -->
              שדה חובה 
                </small>
                                <small class="text-danger" *ngIf="
                registrationForm
                  .get('confirmPassword')
                  .hasError('passwordMatch')
              ">
                  <!-- {{ "REGISTER.Passwordmustmatch" | translate }} -->
                  הסיסמה חייבת להתאים 
                </small>
                            </div>

                            <div class="form-group">
                                <div class="checkbox chkbox checkbox-info m-3">
                                    <input class="form-control " formControlName="moreInformation" id="checkbox2" class="styled" type="checkbox" />
                                    <label class="" for="checkbox2">
                    <!-- {{ "REGISTER.moreInformation" | translate }} -->
                    <!-- שלח לי מידע נוסף מהאתר -->
                    <!-- chceck tranlation -->
                    מעוניין לקבל מבצעי החנות בדוא"ל
                  </label>
                                </div>
                            </div>

                            <p class="small mt-3 mb-3 p-2">
                                {{ "REGISTER.Description" | translate }}

                            </p>

                            <div *ngIf="registrationFail">
                                <div class="alert alert-danger" role="alert">
                                    <h4 class="alert-heading">
                                        <!-- {{ "REGISTER.Error" | translate }} -->
                                        שגיאה!
                                    </h4>
                                    <p>
                                        {{ registrationMessage }}
                                    </p>
                                </div>
                            </div>

                            <button type="submit" class="btn btn-warning btn-block btn-rounded">
                <!-- {{ "REGISTER.REGISTER" | translate }} -->
                הרשמה
              </button>
                        </form>
                    </div>
                    <div *ngIf="isRegistrationSuccess">
                        <div class="alert alert-success" role="alert">
                            <h4 class="alert-heading">
                                <!-- {{ "REGISTER.Success" | translate }} -->
                                הצלחה!
                            </h4>
                            <p>
                                {{ registrationMessage }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center">
                <hr class="hr-text small mb-2" data-content="יש לך חשבון?" />
                <br>
                <p>
                    <a (click)="open()" class="btn btn-block btn-dark btn-rounded">
                        <!-- {{ "REGISTER.Signin" | translate }} -->
                        התחבר
                    </a>
                </p>

                <p class="small text-secondary mt-4">
                    Copyright © 2020 Playfunction
                </p>
            </div>
        </div>
    </div>
</div>