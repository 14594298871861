import { Component, OnInit, Input, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-bread-cum",
  templateUrl: "./bread-cum.component.html",
  styleUrls: ["./bread-cum.component.css"]
})
export class BreadCumComponent implements OnInit {
  @Input() data;
  constructor() {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges): void {}
}
