import { Component, ElementRef, Renderer2, HostListener, PLATFORM_ID, Inject, OnInit } from '@angular/core';
//import { filter } from 'minimatch';
import { ProductService } from '@core/services/product.service';
import { UserService } from '@core/services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { GeneralService } from '@core/services/general.service';
// import { RuntimeCompiler} from '@angular/compiler';

import { isPlatformBrowser, isPlatformServer } from '@angular/common';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { Title, Meta } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { json } from 'express';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'play-function';

  // @HostListener('window:beforeunload', ['$event'])
  // unloadNotification($event: any) {

  //   this.logout();
  //   // if (!this.canDeactivate()) {
  //   //   //  this.cancelTransaction();
  //   //     $event.returnValue = "Are you sure you want close?)";
  //   // }
  // }

  rememberScroll: any = {};
  position = 0;
  wishList: any;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  userData: { userId: string; fullName: string; userName: string };
  constructor(
    private titleService: Title, private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private productService: ProductService,
    private userService: UserService,
    private cookieService: CookieService,
    private router: Router,
	private activatedRoute: ActivatedRoute,
    private generalService: GeneralService, private idle: Idle, private keepalive: Keepalive
    // private runtimeCompiler: RuntimeCompiler
  ) {

      let today= moment().format('YYYY-MM-DD');
      const dateB = moment('2023-06-06');
   
  // this.cookieService.set("sid", res.sid);
            // this.cookieService.set("user_id", res.email);
            // this.cookieService.set("full_name", res.full_name);

// Delete the cookie by setting its expiration date in the past
this.cookieService.delete('sid', '/', "2023-06-08");
this.cookieService.delete('user_id', '/', "2023-06-08");
this.cookieService.delete('full_name', '/', "2023-06-05");
this.cookieService.delete('your-cookie-name', '/', "2023-06-08");
    
localStorage.setItem("paginationArray",JSON.stringify([]))
    // this.store
    //   .pipe(select(fromRoot.getPosition))
    //   .pipe(filter(Boolean))
    //   .subscribe((componentPosition: any) => {
    //     this.rememberScroll = { ...this.rememberScroll, componentPosition };
    //     this.renderer.setProperty(
    //       this.elRef.nativeElement.querySelector(".main-scroll-wrapp"),
    //       "scrollTop",
    //       0
    //     );
    //   });

	
	if (isPlatformBrowser(this.platformId)) {
		let sId = localStorage.getItem('sid');	
		// sets an idle timeout of 5 seconds, for testing purposes.
		if (sId) {
			  idle.setIdle(120);
			  // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
			  idle.setTimeout(240);
			  // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
			  idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
		
			  idle.onIdleEnd.subscribe(() => {
				this.idleState = 'No longer idle.';
	
				this.reset();
			  });
		
			  idle.onTimeout.subscribe(() => {
				this.idleState = 'Timed out!';
				this.timedOut = true;
				alert('Session timeout');
				this.logout();
			
				this.router.navigate(['/']);
			  });
		
			  idle.onIdleStart.subscribe(() => {
				this.idleState = 'You\'ve gone idle!';
				
				// this.childModal.show();
			  });
		
			  idle.onTimeoutWarning.subscribe((countdown) => {
				this.idleState = 'You will time out in ' + countdown + ' seconds!';
				
			  });
		
			  // sets the ping interval to 15 seconds
			  this.keepalive.interval(15);
		
			  this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
		
			  this.reset();
		}
	}
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
  
  ngOnInit(): void {
    this.userData = this.userService.getCurrentUser();
    if (this.userService.checkLogin()) this.getWishList();

    this.goTopNavigation();
    this.generalService.getCategory().subscribe((res: any) => {
      localStorage.setItem('category', JSON.stringify(res.message));
    });
  }
  
  setSEO() {
    this.titleService.setTitle("Testing Seo Attributes");
	this.metaTagService.updateTag({ name: 'description', content: "Description from App Components" });
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Angular SEO, Angular Universal' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Ronak Patel' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2021-05-17', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);
  }

  getWishList() {
    this.productService.getWishList().subscribe((res: any) => {
      this.wishList = res.message.items;
      localStorage.setItem('wishlist', JSON.stringify(this.wishList));
    });
  }

  goTopNavigation() {
    this.router.events.subscribe(path => {
     window.scrollTo(0, 0);
    });
  }

  logout() {
    const formData = new FormData();
    formData.append("usr", this.userData.userName);
    this.userService.logOut(formData).subscribe(
      (res: any) => {
        this.router.navigate["/"];
        localStorage.clear();
        this.cookieService.deleteAll()
        window.location.href = "";
      },
      (error) => {
        this.router.navigate["/"];
        localStorage.clear();
        window.location.href = "";
      }
    );
  }

  reset() {
    if (isPlatformBrowser(this.platformId)) {
    	this.idle.watch();
	}
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}