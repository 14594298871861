<div class="row">
  <div class="col-10">
    <div class="text-md-right text-center">
      <p  class="mb-4">
        <strong>
          תקנון האתר
        </strong>
      </p>
      <p >
        תקנון זה מסדיר את היחסים המשפטיים והמסחריים שבין
        <strong> 
          פליי פאנקשן בע"מ
        </strong>
        (להלן : פליי פאנקשן ח.פ. 514972330)
      </p>
      <p>
        באמצעות אתר האינטרנט 
      </p>
      <p>  
        (להלן : האתר)www.playfunction.co.il 
      </p>
      <p>  
        לבין לקוחותיו (להלן: הלקוחות ו/או הלקוח)
      </p>
      <p class="mb-4">
        יש לקרוא בעיון את התקנון וכל הוראות תקנון זה יחולו על כל שימוש או רכישה באתר.
      </p>
      <p class="mb-4">
        *ככל שאינך מסכים למי מתנאי התקנון ו/או התנאים הנוספים באתר, הנך מתבקש להימנע מלעשות כל שימוש באתר מכל מין וסוג שהוא, לרבות הרשמה לאתר ו/או רכישת מוצרים באמצעות האתר.
      </p>

      <P class="mb-4">
        <strong>הזמנות באתר</strong>
      </P>
      <p>
        המכירה דרך האתר הינה בכפוף למלאי הקיים במחסני האתר והחברה. החברה אינה מתחייבת להחזיק בכל עת מלאי של כל הדגמים ו/או המוצרים שתמונותיהם מופיעות באתר. 
      </p>
      <p  class="mb-4">
        למרות שאנו עושים כמיטב יכולתנו להבטיח שהמוצרים המוצגים באתר יימצאו במלאי, הרי שלעתים מוצרים המוצגים באתר אזלו מהמלאי. אם המוצרים חסרים, תשלח לך הודעה מתאימה באמצעות הדואר האלקטרוני שלך או באמצעות הטלפון. במקרה הזה, שומרת לעצמה פליי פאנקשן את הזכות להגיש לך, במידה וישנה כזאת, הצעה לרכישת מוצר חלופי בעל אופי ומחיר דומים. אם תקבל את הצעתנו, יעודכנו פרטי הזמנתך מחדש. אם החלטת לסרב, תימחק הזמנתך וכרטיס האשראי שלך לא יחויב.
      </p>
      <p>
        <strong>כרטיס האשראי מחויב כאשר ההזמנה מוכנה ולאחר בדיקת מלאי. ורק אז חלה הרכישה.</strong>
      </p>

      <p  class="mb-4">
        מועדי אספקה יחלו רק לאחר קבלת אישור מחברת כרטיסי האשראי על תקינות העסקה.  
כרטיס אשראי שנמסר על ידי גולש ואשר יסורב על ידי חברת האשראי, תהווה ראיה לביטול עסקה מבחינת האתר. הגולש יכול לפנות ולתקן את האישור לתשלום וזאת במידה והאתר יאשר את השינוי. 
    </p>

      <p  class="mb-4">
        <strong>מדיניות משלוחים</strong>
      </p>
      <P>
        המוצרים השונים יסופקו באמצעות חברת שליחויות חיצונית. מוצרים שיסופקו יישלחו בהתאם לזמן האספקה הנהוג 
      </P>
      <p>
        זמן אספקה מוגדר באתר לכל מוצר ולכל הזמנה הינו עד 7 ימי עסקים. הזמן הסביר של שליחות עד הבית הינו עד 3 ימי עסקים מלמחרת יום התשלום. בדר"כ האספקה מתבצעת לאחר יום עסקים אחד בלבד ולרוב חברת השליחויות עומדת ביעד הזמן הסביר, אך יחד עם זאת מדובר בשירות חיצוני וייתכנו עיכובים מעבר לזמן הנקוב
      </p>
      <p class="mb-4">
        מובהר כי, במהלך מכירה מיוחדת/תקופות מבצעים, לרבות SHOPPING IL, בלאק פריידיי וכיוצא בזה, זמן האספקה עשוי להתארך ולארוך 14 ימי עסקים.
      </p>
      <p>
        <strong>
          במידה ובעת ביצוע ההזמנה יימסרו פרטי זיהוי שגויים, לא נוכל להבטיח שהמוצרים יגיעו אליך. במקרה שהמוצרים יחזרו אלינו בגלל פרטים מוטעים שנמסרו, יחויב הלקוח שוב בתשלום בגין דמי משלוח במידה וירצה שנשלח את המוצרים שוב. אנא הקפד למלא פרטים מדויקים ועדכניים. הגשת פרטים
        </strong>
      </p>
      <p>
        <strong>
          אישיים כוזבים אסורה לחלוטין ומהווה עבירה פלילית.
        </strong>
      </p>
      <p class="mb-4">
        <strong>
          צרו איתנו קשר לגבי שירות איסוף עצמי מהסניף שלנו בירושלים. 
        </strong>
      </p>
      <p>
        <strong>
          (עלות שליחות עד הבית מסובסדת ל 19.90 ש"ח) 
        </strong>
      </p>
      <p class="mb-4">
        <strong>
          החברה רשאית לעדכן את תעריפי המשלוחים מעת לעת ובלא צורך בהודעה מוקדמת.
        </strong>
      </p>
      <p>
        <strong>
          נוהל אספקה 
        </strong>
      </p>
      <p>זמן אספקה הינו על פי ימי עסקים שאינם כוללים ימי שישי ושבת וחגי ישראל.</p>
      <p>אספקת מוצר לבית הלקוח תגיע באריזה סגורה שעברה ביקורות מוקדמות לגבי תכולתה וטיב וכמות המוצרים שבתוכה.</p>
      <p>במקרה בו לקוח יתלונן על אי קבלת מוצר שהגיע בחבילה סגורה , התלונה על כך חייבת להגיע באופן מיידי להנהלת האתר שזו תפעל לאיתור הבעיה.</p>
      <p>חתימה על קבלת החבילה תהווה אישור לקבלת המוצר.</p>
      <p class="mb-4">היה ומוצר יגיע כאשר הוא פגום בשל חבטה או סימן של נזק על החבילה , אזי שהרוכש יוכל לסרב לקבל את החבילה או להודיע בו בעת להנהלת האתר כדי שזו תקבל החלטה ותייתר ללקוח לפתוח את החבילה טרם יחתום ויאשר את קבלתה בשלמותה.</p>
      <p class="mb-4">
        <strong>
          תשלום
        </strong>
      </p>
      <p>התשלום מתבצע באמצעות כרטיס אשראי, כאשר קיימת אפשרות לקנייה בתשלומים. פרטי ההזמנה וכרטיס האשראי מועברים באמצעות שרת מאובטח כאשר הם מוצפנים.</p>
      <p class="mb-4">כאשר תדרשו להזין פרטים רגישים, סימן המנעול יופיע בתחתית הדפדפן בצידו הימני. סימן זה מאשר כי המידע המועבר אלינו מאובטח ומוצפן .</p>
      <p class="mb-4">
        <strong>
          אישור הזמנה ומעקב משלוחים
        </strong>
      </p>
      <p>לאחר אישור ההזמנה וביצוע התשלום, תתקבל הודעה על אישור קבלת ההזמנה. בנוסף, תשלח לדוא"ל של הלקוח הודעה על תחילת הטיפול בהזמנה</p>
      <p class="mb-4">ניתן לעקוב אחרי הטיפול בהזמנה באמצעות האזור האישי של הלקוח באתר.</p>
      <p class="mb-4">
        <strong>החזרת מוצרים / ביטול עסקה ע"י הלקוח</strong>
      </p>
      <p class="mb-4">
        המשתמש יהא רשאי לבטל את העסקה בכתב בהתאם ובכפוף לכל הוראות הדין ולרבות הוראות חוק הגנת הצרכן, התשמ"א – 1981 (להלן: 
        <strong>"חוק הגנת הצרכן"</strong>) לרבות התקנות מכוחו.
      </p>
      <p class="mb-4">לקוח רשאי לבטל את העסקה ולקבל החזר כספי החל מיום עשיית העסקה ועד 14 ימים מיום קבלת ההזמנה, או מיום קבלת מסמך פרטי העסקה כנדרש על פי חוק, לפי המאוחר מבינהם. ביטול העסקה ייעשה תוך 14 ימים מיום קבלת המוצר, או מסמך פרטי העסקה, לפי המאוחר ביניהם.</p>
      <p class="mb-4">
        על אף האמור לעיל ובהתאם לחוק, אדם עם מוגבלות, אזרח ותיק או עולה חדש, יהא רשאי לבטל את העסקה באתר בתוך ארבעה חודשים מיום עשיית העסקה, מיום קבלת הזמנה בפועל או מיום קבלת מסמך פרטי העסקה, לפי המאוחר,
        <strong>ובלבד שההתקשרות בעסקה כללה שיחה בין פליי פאנקשן ללקוח, ובכלל זה שיחה באמצעות תקשורת אלקטרונית.</strong>
        החברה תהיה רשאית לבקש מהלקוחה שלעיל תעודה, כמפורט בחוק, המוכיחה כי היא אדם עם מוגבלות, אזרחית ותיקה או עולה חדשה, לפי העניין.
      </p>
      <p class="mb-4">בהתאם לחוק, הלקוח רשאי לבטל עסקה באחת הדרכים שלהלן:</p>
      <p class="mb-4">
        <strong>תמסור עותק מהודעת ביטול החיוב, למעט דמי ביטול בשיעור של 5% או 100 ₪, לפי הנמוך ביניהם וככל שהלקוח שילם דמי משלוח, סכום זה לא יוחזר עם ביטול העסקה. המוצר יוחזר על חשבון המשתמש. </strong>
      </p>
      <p class="mb-4">
        כל החזר כספי שיינתן על ידי האתר יועבר אך ורק לכרטיס האשראי דרכו שולמה הרכישה, בניכוי דמי משלוח (ככל ונגבו) ובהתאם ללוחות הזמנים של חברת האשראי. למען הסר ספק, החזר כספי יבוצע בהתאם לתשלום בפועל.
      </p>
      <p class="mb-4">
        ביטול ההזמנה לפני שליחת המוצר או המוצרים  ללקוח (לפני יציאתם ממחסני האתר) לא יחויב בדמי ביטול. ביטול ההזמנה לאחר שליחת המוצר או המוצרים (לאחר יציאתם ממחסני האתר) יחויב בדמי ביטול בשיעור של 5% ממחיר הטובין או 100 שקלים, לפי הנמוך מביניהם. החזר כספי יינתן בהתאם להוראות החוק.
      </p>
      <p class="mb-4">
        אין בזכותו של צרכן לבטל עסקה כדי לגרוע מזכותה של החברה לתבוע את נזקיה במקרה של החזרת מוצרים שערכם פחת כתוצאה מהרעה משמעותית במצבם בזמן שהיו ברשות הצרכן, לרבות במקרה של החזרת מוצר שנעשה בו שימוש, שאריזתו נפתחה או נפגמה, שניזוק, שנפגם, שהתקלקל ו/או שספג פגיעה כלשהי. כן מתבקשות הלקוחות, על מנת להימנע מגרימת נזק למוצר, להימנע מעשיית שימוש במוצר ולהחזירו ללא כל פגם ו/או קלקול מכל מין וסוג שהוא.
      </p>
      <p class="mb-4">הזכות לביטול העסקה לא תחול על מוצרים המפורטים בחוק ובין השאר, במקרים הבאים: א. מוצרים שהוזמנו והורכבו במיוחד בעבור הצרכן עקב ההזמנה; ב. מוצרים הניתנים להקלטה, לשעתוק או לשכפול.</p>
      <p class="mb-4">המשתמש תבדוק את המוצר מיד עם קבלתו ותודיע לחברה אם המוצר שהתקבל פגום או שונה מהותית מכפי שהמופיע באתר.</p>
      <p>
        <strong>במקרה של ביטול עסקה עקב פגם במוצר או אי התאמה בין המוצר לבין הפרטים שנמסרו ללקוח או עקב אי  אספקת המוצר במועד שנקבע או הפרה אחרת של תנאי החוזה (להלן: "אי התאמה") ,</strong>
        תחזיר החברה, בתוך 14 ימים מיום קבלת ההודעה על הביטול, את אותו חלק ממחיר העסקה ששולם על ידי הצרכן בגין המוצר הפגום, תבטל את החיוב בשל העסקה, תמסור עותק מהודעת ביטול החיוב ולא תגבה דמי ביטול כלשהם מהצרכן. תודיע על כך הלקוחה לחברה בכתב, תבוצע בדיקה מול הלקוחה ובמידת הצורך יתואם המועד לאיסוף המוצר.
      </p>
      <p class="mb-4">על המשתמש לבדוק את הפריט/ים מיד עם קבלת ההזמנה לידיו. במידה ופריט כלשהו התקבל בידי המשתמש כשהוא פגום, או כאשר מפרט הפריט בפועל שונה מן המפרט שהוצג באתר, יהא רשאי המשתמש לבטל את העסקה בתוך 14 יום מיום קבלת הפריט לידיו. בביטול בנסיבות אלה לא ייגבו דמי ביטול, כספו של המשתמש יושב לו תוך 14 ימים מקבלת הודעת הביטול, והמוצר יוחזר אל פליי פאנקשן על חשבונה.</p>
      <p class="mb-4">החזר כספי במקרה של ביטול עסקה שבוצעה באמצעות פייפאל יבוצע באמצעות העברה בנקאית אל חשבון המשתמש, זאת בתוך 14 יום מקבלת הבקשה לביטול העסקה והפריט המוחזר. במקרה של ביטול חלק מן העסקה (החזרת פריט אחד מבין כמה שנרכשו) פליי פאנקשן תחזיר למשתמש, בתוך 14 ימים מיום קבלת ההודעה על הביטול, את אותו חלק ממחיר העסקה ששולם על ידו בגין הפריט המוחזר.</p>
      <p class="mb-4">
        החזרת המוצר לשם החלפתו במוצר אחר או לקבלת זיכוי כספי תעשה בהתאם למדיניות ההחלפות הכללית הנוהגת בפליי פאנקשן, כפי שתעודכן מעת לעת, בתוך 30 ימים מיום קבלת המוצר על ידי המשתמש, בצירוף חשבונית, ובתנאי שלא נעשה כל שימוש בפריט, אריזתו לא נפתחה ולא הוסרו תוויות הפריט.
      </p>
      <p class="mb-4">
        מוצרים שנרכשו בתנאי "<strong>מכירה מיוחדת</strong>" (למשל 1+1, מוצר שני ב-50%( יוחזרו על פי אותם תנאי הרכישה (בהחזרה של כל המוצרים), ככל שיוחזרו רק חלק מהמוצרים, החזר כספי ו/או זיכוי (לפי העניין) יינתן בהתאם לשיעור ההנחה היחסי שניתן בפועל בגין המוצר, בעת הרכישה.
      </p>
      <p class="mb-4">
        בכל שאלה הקשורה לביטול העסקה, בירורים, החלפות, החזרות וכד' יש לפנות אל מחלקת שירות הלקוחות של פליי פאנקשן בדוא"ל: info@playfunction.co.il  או בטלפון : 02-650-6262
      </p>
      <p><strong>ניתן לבטל רכישת מוצרים בתוך 14 (ארבעה עשר) ימים מיום קבלתם, כשהם ארוזים באריזתם המקורית ולא נפתחו כלל ולא נעשה בהם שימוש. עלות המשלוח לא תזוכה בכל מקרה</strong></p>
      <p class="mb-4">
        <strong>
        במידה ומוצר עומד בתנאי ההחזרה, החזר כספי / זיכוי יינתן על מחיר המוצר ששולם פחות דמי המשלוח והפחתה של 5% מעלות המוצר, עפ"י הקבוע בחוק. למען הסר ספק, אין החנות מקבלת מוצרים להחזרה / החלפה בדואר רגיל / דואר רשום
      </strong>
      </p>
      <p>
        <strong>מדיניות החלפת מוצרים </strong>
      </p>
      <p class="mb-4">החלפה - הכוונה במקרה שלקוח רוצה מוצרים ורוצה אחרים במקומם באותו שווי (או יותר), בעת שעדיין לא נפתחו והם במצב החדש כפי שהתקבלו מפליי פאנקשן.</p>
      <p class="mb-4">
        <strong>
          אנו מוציאים שליח עם המוצרים שאתם בוחרים במקום המוצרים המוחזרים,
מדובר בעלות של שני משלוחים
        </strong>
      </p>
      <p class="mb-4">
        <strong>
          ביטול עסקה ע"י החברה האתר
        </strong>
      </p>
      <p class="mb-4">
        פליי פאנקשן  תהא רשאית לבטל עסקה או מכירה כולה או חלקה במקרים מסויימים, כגון:
      </p>
      <p class="mb-4">אם הופר סעיף מסעיפי תקנון זה ו/או מכל מסמך מחייב ו/או תנאי מתנאיו של שירות שהוצע על ידי האתר;</p>
      <p>אם יתגלה כי הלקוח הזין פרטים שגויים בעת הרישום לאתר ו/או ביצוע ההזמנה;</p>
      <p>אם בוצע מעשה או מחדל אשר עלול לפגוע באתר ו/או בצדדים שלישיים כלשהם, לרבות לקוחות, עובדי וספקי החברה ו/או האתר;</p>
      <p>אם השימוש באתר הווה ו/או אפשר ו/או עודד ו/או סייע לביצוע מעשה הנחזה כבלתי חוקי על פי דיני מדינת ישראל;</p>
      <p>כאשר הלקוח חב חוב כספי לאתר ו/או לחברה וכבר חלף המועד לתשלומו;</p>
      <p class="mb-2">במקרה ונפלה באתר טעות קולמוס, בין אם במחיר המוצר המוצג באתר ובין אם בתיאור המוצר כפי שהוצג באתר; ו/או אם נפלה טעות כלשהי, לרבות טעות סופר ו/או תקלה בתקשורת ו/או כל תקלה טכנית אחרת אשר שבשה את מחיר המוצר ו/או את תיאור הפריט באתר, ו/או במשלוח המוצר;</p>
      <p>במקרה של מניעה ו/או עיכוב מחמת כוח עליון, כדוגמת, אך מבלי לגרוע מכלליות האמור: פעולת מלחמה, איבה, טרור, חבלת סייבר, תקלה מכוונת או בלתי מכוונת באתר החנות, שביתות והשבתות, אירועי טבע חריגים, תקלות בסליקת כרטיסי אשראי וכל סיבה אחרת שאינה תוצאת מעשה או מחדל של האתר;</p>
      <p class="mb-2">אם התברר לה כי העסקה ו/או המכירה לוותה בפעילות בלתי חוקית ו/או שאיננה בהתאם להוראות תקנון זה של המשתמש ו/או מי מטעמו;</p>
      <p class="mb-2">אם המוצר המוזמן סופק לכתובת ו/או לסניף לפי בחירת הלקוח ואולם לא נאסף במשך 14 ימי עסקים ו/או לא נוצר קשר עם הלקוח לשם מסירתו עקב אי זמינות הלקוח ו/או פרטי התקשרות שגויים/חלקיים/לא עדכניים.</p>
      <p class="mb-2">במקרה בו קיים חשש שהרכישה מבוצעת במסגרת רכישה סיטונאית ו/או לצורך מכירה חוזרת על ידי הקונה או מי מטעמה;</p>
      <p class="mb-2">אם אזל המוצר מהמלאי לאחר או לפני שהתבצעה מכירה (אך טרם המשלוח  ללקוח). בוטלה ההזמנה כאמור, האתר לא יהא אחראי ולא יישא בכל נזק שנגרם  ללקוח ו/או לצד שלישי, לרבות אך לא רק נזק בגין רכישת המוצר אצל צד שלישי במחיר גבוה יותר;</p>
      <p class="mb-2">כאשר קיים חשש, מצד החברה שהתמורה בגין הרכישה לא תתקבל ו/או שכרטיס האשראי שברשות הלקוחה נחסם ו/או הוגבל לשימוש בדרך כלשהי.</p>
      <p class="mb-2">מבלי לגרוע מאמור לעיל, החברה תהא רשאית שלא לאשר הזמנה מסיבות נוספות לפי שיקול דעתה הבלעדי.</p>
      <p class="mb-5">הודעה על ביטול המכירה תימסר ללקוח בטלפון ו/או בכתב לכתובת אותה ציין הלקוח בעת ביצוע העסקה או בעת ההרשמה לאתר. במקרה זה, תבוטל העסקה ופליי פאנקשן תימנע מחיוב כרטיס האשראי של המשתמש או תשיב לו כל סכום ששולם בגין המוצר, ככל ששולם. למעט בגין השבת סכום העסקה כאמור, לא תהיה למשתמש כל טענה, תביעה ו/או דרישה, מכל מין וסוג, כלפי פליי פאנקשן בגין ביטול העסקה כאמור בסעיף זה</p>
      <p class="mb-4"><strong>אחריות</strong></p>
      <p class="mb-4">האחריות עבור חלק מהמוצרים באתר ניתנת ע"י פליי פאנקשן ובחלק מהמוצרים האחריות ניתנת ע"י ספקים חיצוניים.</p>
      <p class="mb-4"><strong>התחייבות הגולש</strong></p>
      <p class="mb-5">הגולש מתחייב לנהוג בתום לב וביושר בעת הרכישה ו/או ההזמנה כמו גם לאחר הרכישה והאספקה
        הגולש מתחייב להודיע על כל טעות במשלוח המוצר שקיבל או אם קיבל מוצר אחר בטעות או אם קיבל יותר ממוצר אחד שהזמין . לדוגמא: אם הלקוח קיבל לביתו שתי חבילות במקום אחת שהזמין או חבילה אחרת שאיננה מיועדת לו . היה וטעות כזו תתגלה על ידי האתר מתחייב הלקוח לשלם בעבור המוצר את הסכום וערכו ביום אותו קיבל את המוצר וזאת לפי מחיר מחירון החנות והאתר ביום קבלת המוצר אליו בפועל</p>
      <p class="mb-4"><strong>עוד</strong></p>
      <p class="mb-4">החברה ו/או מי מטעמה לא יהיו אחראים ולא יישאו בכל נזק ישיר, עקיף, תוצאתי או מיוחד, שייגרם למשתמש באתר ו/או לצד שלישי, כתוצאה משימוש או רכישה באמצעות האתר, שלא על פי תקנון זה, לרבות הפסד הכנסה ו/או מניעת רווח שיגרמו מכל סיבה שהיא.</p>
      <p class="mb-4">החברה שומרת לעצמה את הזכות לשנות את התקנון מפעם לפעם על פי שיקול דעתה הבלעדי, וזאת ללא צורך במתן התראה ו/או הודעה מוקדמת, בכפוף לכל דין. הנוסח האחרון כפי שהינו מופיע באתר הינו הנוסח המחייב.</p>
      <p class="mb-4">
        התמונות המוצגות באתר הינן להמחשה בלבד וייתכנו הבדלים בין המראה ומפרט המוצרים כפי שנראים בתמונה לבין המוצרים בפועל. ככל שנפלה טעות כלשהי כגון בתיאור הפריט או במחירו, הדבר לא יחייב את החברה, ובכל מקרה החברה לא תישא באחריות כלשהי העולה על ערך הפריט הנרכש וכן בכל נזק שאינו ישיר ו/או תוצאתי. מבלי לגרוע מהאמור לעיל, מוסכם ומובהר, כי החברה תשתדל לעשות את מיטב יכולתה על מנת להציג תמונות מדויקות ומידע מדויק ככל האפשר.
      </p>
      <p class="mb-4">החברה משתדלת להקפיד ולעדכן את מחירי המוצרים ודמי המשלוח באתר בזמן אמת (להלן: "<strong>המחירים</strong>"). עם זאת, המחירים באתר מתעדכנים מעת לעת ולפיכך עלולים להיווצר פערים בין המחיר בעת איסוף המוצר לעגלת הקניה לבין עת השלמת הליך הזמנה. אם עודכנו המחירים לפני שהושלם תהליך ההזמנה, תחויב הלקוחה לפי המחירים המעודכנים. למען הסר ספק, החברה לא תישא באחריות כלשהי הנובעת ו/או קשורה לפערי המחירים כאמור.</p>
      <p class="mb-4">החברה רשאית לעדכן ולשנות את המחירים ללא צורך בהודעה מוקדמת ולפי שיקול דעתה הבלעדי. המחיר המחייב הוא המחיר שנמסר  ללקוח בעת השלמת הליך הזמנה.</p>
      <p class="mb-5">החברה רשאית לפרסם ו/או להציע למשתמשי האתר מבצעים ו/או הנחות וכל הטבה אחרת בתנאים שנקבעו על ידה ולפי שיקול דעתה הבלעדי. החברה תהא רשאית להפסיק כל הטבה כאמור באופן מידי וללא הודעה מוקדמת. </p>
      <p class="mb-4">החברה שומרת לעצמה את הזכות להעביר תזכורת ללקוח אודות סל הקניות שטרם הושלמה לגביו ההזמנה, באמצעות שליחת הודעה למספר ו/או לכתובת שהוזנה על ידי הלקוח. בנוסף, מרגע ביצוע ההזמנה באתר ואילך תהיה רשאית פליי פאנקשן לשלוח אל המשתמש הודעות עדכון באמצעות דוא"ל ו/או מסרונים, בדבר סטטוס ביצוע ההזמנה.</p>
      <p class="mb-5">שליחה של הודעות על ידי האתר בכל דרך שהיא לרבות דוא"ל ו/או SMS כחלק מתפעול האתר ו/או כחלק ממתן שירות למשתמשות האתר כגון: שליחת הודעת אימות, כהגדרתה לעיל, תזכורות לצורך השלמת הזמנה וכד' אינה מהווה "מסר פרסומי". המשתמש מאשרת בזאת שליחת הודעות כאמור על ידי האתר.</p>
      <p class="mb-3"><strong>סמכות עניינית לשיפוט</strong></p>
      <p class="mb-4">דיני מדינת ישראל יחולו על תקנון זה ועל כל הנובע ממנו או הכרוך בו, התקנון יפורש על פיהם, וכל סכסוך או שאלה משפטית בקשר אליו תובא להכרעה בערכאה השיפוטית המוסמכת בירושלים בלבד.</p>
    </div>
    <div class="mt-2">
      <p>
        <strong><u> שירות לקוחות</u></strong>
      </p>
      <p>
        לפרטים ובירורים באשר להפעלת האתר ופעילותו, ניתן לפנות אל החברה בפרטים הבאים:
      </p>
      <p>טלפון:<strong>02-650-6262</strong></p>
      <p>דוא"ל: info@playfunction.co.il</p>
    </div>
  </div>
</div>

