<div class="textAlign">
  <!-- <a routerLink="/" class="text-dark mb-5">  {{ "BLOG.Back" | translate }} </a> -->
  <a routerLink="/" class="text-dark mb-5">  חזרה לדף הבית </a>
  <div class="row">
    <div class="col-md-12">
      <!-- <h4 class="blogpage-title mt-4"> {{ "BLOG.Blogs" | translate }}</h4> -->
      <h4 class="blogpage-title mt-4"> הבלוג של פליי פאנקשן</h4>
     
      <p class="blog-subtitle my-3">
        <!-- {{ "BLOG.Showing" | translate }} -->
        מציג פוסטים 
        : {{ startFrom }} - {{ endTo }} of
        {{ totalRecords }}
      </p>
      <div class="row mt-4">
        <div
          class="col-md-3 my-3 border-light"
          *ngFor="
            let item of blogList
              | paginate
                : {
                    itemsPerPage: pageSize,
                    currentPage: currentPage,
                    totalItems: totalRecords
                  }
          "
        >
          <div class="card card-262 h-100">
            <div class="card-img-top">
              <img
                *ngIf="item.image"
                src="{{ baseUrl }}{{ item.image }}"
                class="img-conf"
                alt=""
                width="100%"
                height="208px"
              />
              <img
                *ngIf="!item.image"
                src="assets/images/no-image.png"
                class="img-conf"
                width="100%"
                height="208px"
              />
            </div>
            <div class="card-body h-100">
              <p
                class="font-montserrat-bold"
                [ngClass]="getClassByValue(item.blog_category, 'text')"
              >
                <span
                  style="border-bottom: 2px solid;"
                  [ngClass]="getClassByValue(item.blog_category, 'text')"
                  >{{ item.blog_category | titlecase }}</span
                >
              </p>
              <h6 class="blog-card-title">{{ item.title }}</h6>
              <p
                class="card-description"
                [innerHTML]="item.content | truncate: 100"
              ></p>
            </div>
            <div class="card-footer bg-white border-top-0">
              <button
                class="btn btn-rounded mt-2"
                [ngClass]="getClassByValue(item.blog_category, 'btn')"
                [routerLink]="['./', item.name]"
              >
                <!-- {{ "BLOG.More" | translate }} -->
                קרא עוד 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="border-top border-bottom p-1 mt-3 text-center"
    *ngIf="blogList?.length > 0"
  >
    <pagination-controls
      (pageChange)="pageChanged($event)"
      previousLabel=""
      nextLabel=""
    ></pagination-controls>
  </div>
  <div class="row" style="height: 100px;"></div>

  <app-why-buy-from-us></app-why-buy-from-us>
</div>
