import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { httpInterceptorProviders } from "./interceptors";

@NgModule({
  imports: [SharedModule, HttpClientModule, BrowserAnimationsModule],
  declarations: [],
  providers: [
    //  httpInterceptorProviders
  ]
})
export class CoreModule {}
