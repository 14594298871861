import { ItemFilterComponent } from "./item-filter/item-filter.component";
import { ItemListComponent } from "./item-list/item-list.component";
import { ItemDetailsComponent } from "./item-details/item-details.component";
import { ItemMediaComponent } from "./item-media/item-media.component";
import { ItemListViewComponent } from "./item-list-view/item-list-view.component";

export const components = [
  ItemFilterComponent,
  ItemListComponent,
  ItemDetailsComponent,
  ItemMediaComponent,
  ItemListViewComponent
];
