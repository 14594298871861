<nav aria-label="breadcrumb">
  <ol class="breadcrumb breadcrumb-text bg-white p-1 mb-1 mb-md-5 mr-3" *ngIf="data">
    <li class="breadcrumb-item"><a routerLink="/" class="a-breadcrumb"><i class="fa fa-home" aria-hidden="true"></i> דף הבית </a></li>
    <li class="breadcrumb-item pl-0" *ngIf="data.l1">
      <span class="mr-1">{{ data.l1 }}</span>
    </li>
    <li class="breadcrumb-item pl-0" *ngIf="data.l2">
      <span class="mr-1">{{ data.l2 }}</span>
    </li>
    <li class="breadcrumb-item pl-0" *ngIf="data.l3">
      <span class="mr-1">{{ data.l3 }}</span>
    </li>
    <li class="breadcrumb-item active pl-0" *ngIf="data.l4">
      <span class="mr-1">{{ data.l4 }}</span>
    </li>
  </ol>
</nav>
