<div class="col-md-12 mt-4 col-12">
  <div class="card bg-transparent border-0 p-4">
    <div class="card-header text-center border-bottom-0 pr-0">
      <button
        type="button"
        class="close close-btn text-danger"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      >
        <span aria-hidden="true">
          X
        </span>
      </button>
    </div>
    <div class="card-body p-4 bg-white">
      <div class="my-2 login-title">
        <!-- {{ "LOGIN.Login" | translate }} -->
        התחבר לחשבונך 

      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmitForm()">
        <div class="row">
          <div class="col-12">
            <div class="form-group m-3">
              <input
                type="text"
                class="username-field my-2"
                placeholder="דואר אלקטרוני"
                formControlName="userName"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group password-input mx-3">
              <!-- <div class="input-group">
              <input
              [type]="fieldTextType ? 'text' : 'password'"
              class="form-control password-field my-2"
              placeholder="סיסמה"
              formControlName="password"
            />

            <div class="input-group-prepend">
              <button type="button" class="input-group-text" (click)="toggleFieldTextType()"
                style="border-radius: 25px 0px 0px 25px;">
                <i [class]="fieldTextType ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
              </button>
            </div>
            </div> -->

              <div class="input-group">
                <input
                  [type]="fieldTextType ? 'text' : 'password'"
                  class="form-control password-box"
                  id="password"
                  placeholder="סיסמה"
                  formControlName="password"
                />
                <div class="input-group-prepend">
                  <button
                    type="button"
                    class="input-group-text"
                    (click)="toggleFieldTextType()"
                    style="
                      border-radius: 25px 0px 0px 25px;
                      background: #bfcedc6e;
                    "
                  >
                    <i
                      [class]="fieldTextType ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="checkbox chkbox checkbox-info m-3">
          <input id="checkbox1" class="styled" type="checkbox" />
          <label class="" for="checkbox1">
            <!-- {{ "LOGIN.StayLogged" | translate }} -->
            הישאר מחובר לתשלום מהיר יותר 
          </label>
        </div>
        <div class="row">
          <button class="login-btn mx-3">
            <!-- {{ "LOGIN.LOGIN" | translate }} -->
            התחברות
          </button>
        </div>

        <p class="mt-1 p-2 text-center">
          <a
            (click)="forgotPassword()"
            class="text-dark small font-montserrat-bold"
            style="cursor: pointer;"
          >
            <!-- {{ "LOGIN.TroubleLogging" | translate }} -->
            מתקשים להתחבר? 
          </a>
        </p>
        <div class="my-3">
          <hr class="hr-text small" data-content="או" />
        </div>

        <div class="row">
          <button class="register-btn m-3" (click)="gotoRegister()">
            <!-- {{ "LOGIN.CreateANAccount" | translate }} -->
            צור חשבון 
          </button>
        </div>

        <div *ngIf="guest == 'yes'" class="my-3">
          <hr class="hr-text small" data-content="או" />
        </div>

        <div *ngIf="guest == 'yes'" class="row">
          <button style="background-color: #fecd08 !important;"  class="register-btn m-3" (click)="goToCheckout()">
            <!-- {{ "LOGIN.checkoutAsGuest" | translate }} -->
            הסדר חשבון כאורח 
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="text-center">
    <p></p>
  </div>
</div>
