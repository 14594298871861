import { Component, OnInit, ElementRef } from "@angular/core";
import { ProductService } from "@core/services/product.service";
import { UserService } from "@core/services/user.service";
import { BASEURL, PaymentType, shippingOption } from "app/app.config";
import { AlertService } from "@core/services/alert.service";
import { CartService } from "@core/services/cart.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { validateEmail } from "@core/helpers/validator";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorage } from "@ng-idle/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  isSubmitted: boolean;
  contactUsForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private el: ElementRef,
    private router: Router,
    private activeRouter: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.contactUsForm = this.fb.group({
      name: ["", Validators.required],
      email_id: ["", [Validators.pattern(validateEmail())]],
      phone: ["", [Validators.required, Validators.pattern("[0-9]+")]],
      message: [""],
    });
  }

  errorMark(field) {
    const form = this.contactUsForm.controls[field];
    return {
      "is-invalid": form.errors && !form.pristine,
    };
  }

  onValueChanged(data?: any) {
    if (!this.contactUsForm) {
      return;
    }
    const form = this.contactUsForm;
    for (const field in this.formErrors) {
      const formControl = form.get(field);
      const control = form.get(field);
      this.formErrors[field] = "";
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + " ";
        }
      }
      if (this.isSubmitted && control && !control.dirty) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + " <br>";
        }
      }
    }
  }

  formErrors = {
    name: "",
    email_id: "",
    phone: "",
    message: "",
  };

  validationMessages = {
    name: {
      required: "שדה חובה",
    },
    message: {
      required: "שדה חובה"
    },
    phone: {
      required: "שדה חובה",
      pattern: "השימוש בשדה זה בספרות בלבד",
    },
    email_id: {
      required: "שדה חובה",
      pattern: 'חשבון דוא"ל לא תקין',
    }
  };

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    firstInvalidControl.focus(); //without smooth behavior
  }

  submitContactUs(){
    this.isSubmitted = true;
    const { value, valid ,invalid } = this.contactUsForm;

    if(invalid){
      return;
    }

    if(valid){

    }else{
      this.contactUsForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
    }
  }

}
