import { Component, OnInit, Input, ViewChild ,OnDestroy, OnChanges } from "@angular/core";
import { BASEURL } from "app/app.config";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from '@core/services/user.service';
import { CartService } from '@core/services/cart.service';
import { v4 as uuidv4 } from 'uuid';
import { AlertService } from "@core/services/alert.service";
import { ProductService } from '@core/services/product.service';
import { AccountService } from "@core/services/account.service";
import { LoginComponent } from 'app/layout/containers/auth/login/login.component';
import { Location } from '@angular/common'
import { Pagination } from "@shared/model/pagination.model";
@Component({
  selector: "app-item-details",
  templateUrl: "./item-details.component.html",
  styleUrls: ["./item-details.component.css"]
})
export class ItemDetailsComponent implements OnInit {
  @Input() listItem;
  @Input() pageNo;
  @Input() pageSize;
  @Input() totalRecords;
  @Input() list;

  pageSize2 : number;
  pageSubscription: any;

  baseURL = BASEURL;
  currentProduct: any;
  queryParams: any;
  quantity: Number = 1;
  productDetails: any;
  closeResult: string;
  isPresentInWishList;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private cartService: CartService,
    private productService: ProductService,
    private alertService: AlertService,
    private accountService: AccountService,
    private location: Location,
  ) {
    this.route.params.subscribe((param:any) => {
      this.queryParams = param;
 
    //  setTimeout(function(){ window.scrollTo(0, param['scroll']);document.body.scrollTop =  param['scroll']; }, 100); 
      // scroll=636
    });
  }



 

  ngOnInit() {
    
    this.pageSubscription = this.accountService.getPage().subscribe(
      num => 
      this.pageSize2 = num,
      );
     
  }

  public ngOnDestroy(): void {
    this.pageSubscription.unsubscribe(); // onDestroy cancels the subscribe request
  }

  openLg(content, product) {
    this.currentProduct = product;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: "lg" })
      .result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
          this.getProductItemsDetails();
        },
        reason => {
          this.closeResult = `Dismissed`;
        }
      );
  }

  getProductItemsDetails() {
    const getLocalWishList = JSON.parse(this.userService.getLocalWishList());
    const formData = new FormData();
    formData.append("item_code", this.currentProduct.item_code);
    this.productService.getProductDetails(formData).subscribe((res: any) => {
      this.productDetails = res.message.items[0];
      if (
        getLocalWishList &&
        getLocalWishList.some(
          (it) => it.item_code === this.productDetails.item_code
        )
      ) {
        this.isPresentInWishList = true;
      }
      // this.recommandedItems();
      // this.relatedProduct(this.currentRelatedItemType);
    });
  }

  addWishList(data) {
    if (this.userService.checkLogin()) {
      if (!this.isPresentInWishList) {
        const body = {
          item_code: data.item_code,
          item_name: data.item_name,
          qty: 1,
          age: data.age_range,
        };
        const frmData = new FormData();
        frmData.append("data", JSON.stringify(body));
        this.productService.addToWishList(frmData).subscribe((res) => {
          this.isPresentInWishList = true;
          this.alertService.success("המוצר נוסף לרשימת המשאלות שלך"); // Added To wishlist
        });
      } else {
        this.alertService.info("Product already in wishlist");
      }
    } else {
      const modalRef = this.modalService.open(LoginComponent);
      modalRef.componentInstance.type = "main";
    }
  }

  gotoDetails(data,page:any) {
    this.modalService.dismissAll();
    this.onDetail(data,true,page);
  }

  onDetail(item,isQuerry,page){
    // let url= this.router.url.split(';');
    // for(let i=0;i<url.length;i++){
    //   if(url[i].includes('page_num')){
    //     if(url[i]=="page_num=All"){
    //       page="All"
    //     }
    //   }
    //    }
    //    if(page=="All"){
    //     this.location.go(this.router.url+";scroll="+window.scrollY+";page_num="+page);
    //    }else{
    //     this.location.go(this.router.url+";scroll="+window.scrollY+";page_num="+page+";pageSize="+this.pageSize==null ? 1:this.pageSize);
    //    }
  
  
    let paginationArray: Array<any> = JSON.parse(localStorage.getItem('paginationArray'));
    paginationArray.push(new Pagination({isAll:page=="All"? true:false,page_no:page,pageSize:this.pageSize,scroll:window.scrollY}))
   localStorage.setItem('paginationArray',JSON.stringify(paginationArray))
 //this.router.navigateByUrl(this.router.url+";scroll="+window.scrollY+";page_num="+page)
    let navigateTo = "/products/details/" + item.item_code;
    if(isQuerry){
      this.router.navigate([navigateTo], {
        relativeTo: this.route,
      //   queryParams: {
		  // l1: this.queryParams.l1,
		  // l2: this.queryParams.l2,
      //     detailRouteType : 'product-list',
      //     pageChange: JSON.stringify(page),
      //     productInfo: JSON.stringify(item),
      //     navigateTo: JSON.stringify(navigateTo),
      //     navigateBackTo: JSON.stringify(this.router.url),
      //     totalRecords : JSON.stringify(this.totalRecords),
      //     pageSize: JSON.stringify(this.pageSize),
      //     // productList: JSON.stringify(this.list),
      //   },
      });
    }else{
      this.router.navigate([navigateTo], {
        relativeTo: this.route,
        queryParams: {
          detailRouteType : 'product-list',
          pageChange: JSON.stringify(page),
          productInfo: JSON.stringify(item),
          navigateTo: JSON.stringify(navigateTo),
          navigateBackTo: JSON.stringify(this.router.url),
          totalRecords : JSON.stringify(this.totalRecords),
          pageSize: JSON.stringify(this.pageSize),
          // productList: JSON.stringify(this.list),
        },
      });
    }
  }


  addToCart(type, data){
    if (this.userService.checkLogin()) {
      this.cartService.addToCart(data, type, +this.quantity);
    } else {
      if(localStorage.getItem("guest_session_id")){
        this.cartService.addToCart(data, type, +this.quantity);
      }else{
        let val = uuidv4();
        localStorage.setItem("guest_session_id", val);
        this.cartService.addToCart(data, type, +this.quantity);
      }
      // const modalRef = this.modalService.open(LoginComponent);
      // modalRef.componentInstance.type = "main";
    }
  }
}
