import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
const API_ENDPOINT =
//'https://playfunction.indictranstech.com/api/method/playfunction.website_api.';
  //"https://erp.playfunction.co.il/api/method/playfunction.website_api."; //Prod   
//  "https://erp.playfunction.com/api/method/playfunction.website_api."; //UAT
 //"https://playfunction.indictranstech.com/api/method/playfunction.website_api.";
// "https://playfunction.indictranstech.com/api/method/playfunction.website_api."
// "http://163.172.127.251:8181/api/method/playfunction.website_api."
    //"https://erp.playfunction.co.il/api/method/playfunction.website_api."
  "https://devuat.playfunction.co.il/api/method/playfunction.website_api."
   
@Injectable({
  providedIn: 'root'
})
export class HttpWrapperService {
  constructor(private http: HttpClient) { }

  getWithCredentials<T>(url: string): Observable<T> {
    return this.http.get<T>(API_ENDPOINT + url, {
      withCredentials: true
    });
  }
  WithCredentials<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(API_ENDPOINT + url, body, {
      withCredentials: true
    });
  }

  get<T>(url: string): Observable<T> {
    let header=new HttpHeaders();
    // header=header.append('Content-Type',"application/json")
    header= header.append('Access-Control-Allow-Origin', '*')
    // header= header.append('mode', 'no-cors')
 
    return this.http.get<T>(API_ENDPOINT + url, {
      withCredentials: true,
    });
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(API_ENDPOINT + url, body, {
      withCredentials: true
    });
  }

  put<T>(url: string, body: string): Observable<T> {
    return this.http.put<T>(API_ENDPOINT + url, body);
  }

  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(API_ENDPOINT + url);
  }

  patch<T>(url: string, body: string): Observable<T> {
    return this.http.patch<T>(API_ENDPOINT + url, body);
  }
}
