import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from "@angular/core";

@Component({
  selector: "app-toggle",
  templateUrl: "./toggle.component.html",
  styleUrls: ["./toggle.component.css"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ToggleComponent {
  @Input() active = false;
  @Input() title = "Default title";
  @Output() setActive = new EventEmitter();
}
