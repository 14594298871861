import { HelpFaqComponent } from "./help-faq/help-faq.component";
import { BlogComponent } from "./blog/blog/blog.component";
import { BlogDetailsComponent } from "./blog/blog-details/blog-details.component";
import { Routes, RouterModule } from "@angular/router";
import { StoreFrontComponent } from "app/layout/containers/store-front/store-front.component";
import { ProjectsComponent } from "./projects/projects/projects.component";
import { ProjectDetailsComponent } from "./projects/project-details/project-details.component";
import { MeetExpertsComponent } from "./experts/meet-experts/meet-experts.component";
import { MeetExpertsDetailsComponent } from "./experts/meet-experts-details/meet-experts-details.component";
import { TestComponent } from "./test/test.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { DeliveryPolicyComponent } from "./delivery-policy/delivery-policy.component";
import { CancellationPOlicyComponent } from "./cancellation-policy/cancellation-policy.component";
import { ShippingPolicyComponent } from "./shipping-policy/shipping-policy.component";

export const components = [
  HelpFaqComponent,
  BlogComponent,
  BlogDetailsComponent,
  ProjectsComponent, 
  ProjectDetailsComponent,
  MeetExpertsComponent,
  MeetExpertsDetailsComponent,
  AboutUsComponent,
  ContactUsComponent,
  DeliveryPolicyComponent,
  ShippingPolicyComponent,
  CancellationPOlicyComponent,
  TestComponent
];

const routes: Routes = [
  {
    path: "",
    component: StoreFrontComponent,
    children: [
      {
        path: "help",
        component: HelpFaqComponent
      },
      {
        path: "blog",
        children: [
          {
            path: "",
            component: BlogComponent
          },
          {
            path: ":blogId",
            component: BlogDetailsComponent
          }
        ]
      },
      {
        path: "projects",
        children: [
          {
            path: "",
            component: ProjectsComponent
          },
          {
            path: ":projectId",
            component: ProjectDetailsComponent
          }
        ]
      },
      {
        path: "expert",
        children: [
          {
            path: "",
            component: MeetExpertsComponent
          },
          {
            path: ":expert",
            component: MeetExpertsDetailsComponent
          }
        ]
      },
      {
        path: "aboutUs",
        component: AboutUsComponent
      },
      {
        path: "contactUs",
        component: ContactUsComponent
      },
      {
        path: "delivery-policy",
        component: DeliveryPolicyComponent
      },{
        path: "shipping-policy",
        component: ShippingPolicyComponent
      },
      {
        path: "cancellation-policy",
        component: CancellationPOlicyComponent
        
      }
    ]
  },
  {
    path: "test",
    component: TestComponent
  }
];

export const routing = RouterModule.forChild(routes);
