import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "@core/services/user.service";
import { passwordMatch } from "@core/helpers/password-match";
import { CustomValidators } from "@core/helpers/validator";
import { AlertService } from "@core/services/alert.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"]
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  updatePasswordForm: FormGroup;
  passwordStatus;
  passwordError = false;
  key: any;
  isSubmitted: boolean;
  fieldTextType: boolean = false;
  fieldTextType1: boolean = false;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      user: ["", Validators.required]
    });
    this.initUpdatePassword();
  }

  initUpdatePassword() {
    this.updatePasswordForm = this.fb.group(
      {
      usr: [],
      key: [],
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required],

    }, { validator: passwordMatch("password", "confirmPassword") })

  }

  // initUpdatePassword() {
  //   this.updatePasswordForm = this.fb.group(
  //     {
  //       usr: [],
  //       key: [],
  //       password: [
  //         "",
  //         Validators.compose([
  //           Validators.required,
  //           // check whether the entered password has a number
  //           CustomValidators.patternValidator(/\d/, {
  //             hasNumber: true
  //           }),
  //           // check whether the entered password has upper case letter
  //           CustomValidators.patternValidator(/[A-Z]/, {
  //             hasCapitalCase: true
  //           }),
  //           // check whether the entered password has a lower case letter
  //           CustomValidators.patternValidator(/[a-z]/, {
  //             hasSmallCase: true
  //           }),
  //           // check whether the entered password has a special character
  //           CustomValidators.patternValidator(
  //             /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
  //             {
  //               hasSpecialCharacters: true
  //             }
  //           ),
  //           Validators.minLength(8)
  //         ])
  //       ],
  //       confirmPassword: ["", Validators.required]
  //     },
  //     { validator: passwordMatch("password", "confirmPassword") }
  //   );
  // }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }

  submitForm() {
   
    const { value, valid } = this.forgotPasswordForm;
    if (valid) {
      const body = {
        usr: value.user
      };
      const formData = new FormData();
      formData.append("data", JSON.stringify(body));
      this.userService.forgotPassword(formData).subscribe(
        (res: any) => {
          this.key = res.message.key;
          this.passwordStatus = true;
          this.passwordError = false;
        },
        error => {
          this.passwordStatus = false;
          this.passwordError = true;
        }
      );
    }
  }

  updatePassword() {
    this.isSubmitted = true;
    this.updatePasswordForm.patchValue({
      usr: this.forgotPasswordForm.value.user,
      key: this.key
    });
    const { value, valid } = this.updatePasswordForm;
  
    if (valid) {
      const body = {
        usr: value.usr,
        key: value.key,
        new_password: value.password
      };
      const formData = new FormData();
      formData.append("data", JSON.stringify(body));
      this.userService.updatePassword(formData).subscribe((res: any) => {
        this.alertService.success(res.message.message);
        this.router.navigateByUrl("/");
      });
    }
  }
}
