<a routerLink="/projects" class="text-dark">
  <i class="fa fa-chevron-left" aria-hidden="true"></i> חזרה לפרויקט
</a>

<div class="my-5">
  <div class="row justify-content-center">
    <div class="col-6 text-center">
      <div class="row" *ngIf="projectData">
        <div>
          <p class="small font-montserrat-bold text-danger text-center mb-1">
            {{ projectData.category }}
          </p>
          <hr class="mb-2 mt-1 bg-danger w-25" />
          <h4 class="text-dark font-montserrat-bold text-center my-4">
            {{ projectData.title }}
          </h4>
          <img
            *ngIf="projectData.image"
            src="{{ baseUrl }}{{ projectData.image }}"
            class="img-fluid"
          />
          <img
            *ngIf="!projectData.image"
            src="assets/images/no-image.png"
            class="img-fluid"
          />
          <p class="mt-3 project-description-text" [innerHTML]="projectData.description"></p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-why-buy-from-us></app-why-buy-from-us>
