import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "@core/services/user.service";
import { passwordMatch } from "@core/helpers/password-match";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoginComponent } from "../login/login.component";
import { CustomValidators } from "@core/helpers/validator";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.css"]
})
export class RegistrationComponent implements OnInit {
  registrationForm: FormGroup;
  isSubmitted: boolean;
  isRegistrationSuccess;
  registrationMessage;
  registrationFail;
  fieldTextType: boolean = false;
  fieldTextType1: boolean = false;
  guestSession = this.userService.getGuestSession();
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.registrationForm = this.fb.group(
      {
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        address: [""],
        email: ["", Validators.required],
        mobile: ["", Validators.required],
        password: ["", Validators.required],
        // password: [
        //   "", Validators.required
        //   Validators.compose([
        //     Validators.required,
        //     // check whether the entered password has a number
        //     CustomValidators.patternValidator(/\d/, {
        //       hasNumber: true
        //     }),
        //     // check whether the entered password has upper case letter
        //     CustomValidators.patternValidator(/[A-Z]/, {
        //       hasCapitalCase: true
        //     }),
        //     // check whether the entered password has a lower case letter
        //     CustomValidators.patternValidator(/[a-z]/, {
        //       hasSmallCase: true
        //     }),
        //     // check whether the entered password has a special character
        //     CustomValidators.patternValidator(
        //       /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        //       {
        //         hasSpecialCharacters: true
        //       }
        //     ),
        //     Validators.minLength(8)
        //   ])
        // ],
        confirmPassword: ["", Validators.required],
        moreInformation:[""]
      },
      { validator: passwordMatch("password", "confirmPassword") }
    );
  }
  open() {
    const modalRef = this.modalService.open(LoginComponent);
    modalRef.componentInstance.type = "register";
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }

  submitForm() {
    this.isSubmitted = true;
    const { valid, value } = this.registrationForm;
    if (valid) {
      const body = {
        email: value.email,
        first_name: value.firstName,
        last_name: value.lastName,
        mobile_no: value.mobile,
        password: value.password,
        moreInformation: value.moreInformation,
      };
      if (!this.userService.checkLogin()) {
        body["guest_session_id"] = this.guestSession;
      }
      const formData = new FormData();
      formData.append("data", JSON.stringify(body));
      this.userService.registerUser(formData).subscribe(
        (res: any) => {
       
          this.isRegistrationSuccess = true;
          this.registrationMessage = res.message.message;
          this.registrationFail = false;
        },
        err => {
      
          this.registrationMessage = "שגיאה ארעה בתהליך ההרשמה"; //Error Occured While Registration
          this.registrationFail = true;
          this.isRegistrationSuccess = false;
        }
      );
    }
  }
}
