import { Component, OnInit } from "@angular/core";
import { GeneralService } from "@core/services/general.service";
import { ActivatedRoute } from "@angular/router";
import { BASEURL } from "app/app.config";

@Component({
  selector: "app-project-details",
  templateUrl: "./project-details.component.html",
  styleUrls: ["./project-details.component.css"]
})
export class ProjectDetailsComponent implements OnInit {
  projectData: any;
  baseUrl = BASEURL;
  constructor(
    private generalService: GeneralService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.params.projectId;
    this.generalService.getProject(id).subscribe((res: any) => {
      this.projectData = res.message.data;
    });
  }
}
