<div class="content">
    <div class="row">
        <span class="item-filter-title mb-3">
      <!-- {{ "PRODUCT.FilterResult" | translate }} -->
      סנן את התוצאה 
    </span>
        <div class="col-md-12 bg-graybox p-0">
            <div class="card bg-transparent border-0">
                <div class="card-header bg-transparent Hmmm1" id="headingOne">
                    <div class="mb-0 icon-toggle">
                        <a class="item-filter-graybox-title" data-toggle="collapse" data-target="#collapseOne" [attr.aria-expanded]="openCategory" aria-controls="collapseOne" (click)="openCategory = !openCategory">
                            <!-- {{ "PRODUCT.Category" | translate }} -->
                            קטגוריה
                        </a>
                    </div>
                </div>
                <div *ngIf="openCategory">
                    <div class="card-body px-2">
                        <!-- level One  -->

                        <li *ngFor="let item of categoryItem; let i = index">
                            <div *ngIf="currentSelectedCategory === item.category_id">
                                <div class="form-group" style="display: none;" (mouseenter)="checkCategory(item.category_id, i)" (mouseout)="checkCategory(item.category_id, i)" [ngClass]="{'hideItems': level2Item === item?.level_2?.title }">
                                    <input type="checkbox" id="{{ item }}_{{ i }}" [attr.checked]="
						currentSelectedCategory === item.category_id ? true : null
					  " />
                                </div>

                                <!-- level Two -->

                                <ul class="pl-1 pr-0 mt-2 mr-4" *ngIf="item.level_2?.length > 0 && currentIndex === i">
                                    <li *ngFor="let it of item?.level_2; let j = index">
                                        <div *ngIf="filterDummyData['category_level_2'] === it.category_id">
                                            <div class="form-group">
                                                <input type="checkbox" id="{{ it.title }}_{{ j }}" [attr.checked]="
                    filterData['category_level_2'] === it.category_id ? true : null
                  " (change)="checkSubCategory(it.category_id, i, $event)" />
                                                <label for="{{ it.title }}_{{ j }}"><span class="ml-2">{{ it.title }}</span></label
                        >
                      </div>
                      <!-- level Three -->
                   
                      <ul
                        class="pl-2 pr-3"
                        *ngIf="it.level_3?.length > 0 && currentIndex === i"
                      >
             
                        <li *ngFor="let iT of it?.level_3; let j = index">
                          <div
                         
                        >
                          <!-- <div
                            *ngIf="filterDummyData['category_level_3'] === iT.category_id"
                          > -->
                            <div class="form-group">
                       
                              <input
                                type="checkbox"
                                id="{{ iT.title }}_{{ j }}"
                               
                                [checked]="filterData['category_level_3'] === iT.category_id ? true : null"
                                (change)="checkSubSubCategory(iT.category_id, i, $event)"
                              />
                              <label for="{{ iT.title }}_{{ j }}"
                                ><span class="ml-2">{{ iT.title }}</span>
                              </label>
                                            </div>

                                            <!-- level Four  -->

                                            <ul class="pl-0 pr-4" *ngIf=" iT.level_4?.length > 0 && currentIndex === i">
                                                <li *ngFor="let iTT of iT?.level_4; let j = index">
                                                    <div class="form-group">
                                                        <input type="checkbox" id="{{ iTT.title }}_{{ j }}" [checked]="filterData['category_level_3'] === iT.category_id &&
                                filterData['category_level_4'] === iTT.category_id ? true : null" (click)="checkSubSubSubCategory(iTT.category_id, i, $event)" />
                                                        <label for="{{ iTT.title }}_{{ j }}"><span class="ml-2"
                                      >{{ iTT.title }}</span
                                    ></label
                                  >
                                  <!-- <span>{{iTT.group_level}} {{iTT.title}} </span> -->
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 bg-graybox p-0">
      <div class="card bg-transparent border-0">
        <!-- <div class="card-header bg-transparent Hmmm2" id="headingOne">
          <div class="mb-0 icon-toggle">
            <a
              class="item-filter-graybox-title"
              data-toggle="collapse"
              data-target="#collapseOne"
              [attr.aria-expanded]="openType"
              aria-controls="collapseOne"
              (click)="openType = !openType"
            >
             
              סוג
            </a>
          </div>
        </div> -->
        
        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordion"
          *ngIf="openType"
        >
          <div class="card-body">
            <li *ngFor="let item of currentItem; let ij = index">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="{{ item.title }}_{{ ij }}"
                  class="cb_first"
                  (change)="checkType(item.category_id, ij)"
                  [attr.checked]=""
                /><label for="{{ item.title }}_{{ ij }}"
                  ><span>{{ item.title }}</span></label
                >
              </div>
              <ul
                class="pl-3"
                *ngIf="item.level_4?.length > 0 && currentItemIndex === ij"
              >
                <li *ngFor="let it of item?.level_4; let j = index">
                  <div class="form-group">
                    <input
                      type="checkbox"
                      id="{{ it.title }}_{{ j }}"
                      (change)="checkSubType(it.category_id, j)"
                    /><label for="{{ it.title }}_{{ j }}"
                      ><span>{{ it.title }}</span></label
                    >
                  </div>
                </li>
                <li></li>
              </ul>
            </li>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 bg-graybox p-0">
      <div class="card bg-transparent border-0">
        <div class="card-header bg-transparent Hmmm3" id="headingOne">
          <div class="mb-0 icon-toggle">
            <a
              class="item-filter-graybox-title"
              data-toggle="collapse"
              data-target="#collapseOne"
              [attr.aria-expanded]="openAge"
              aria-controls="collapseOne"
              (click)="openAge = !openAge"
            >
              <!-- {{ "PRODUCT.AgeRange" | translate }} -->
              גיל
            </a>
          </div>
        </div>

        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordion"
          *ngIf="openAge"
        >
          <div class="card-body">
            <!-- <ng5-slider
              [(value)]="ageValue"
              [options]="ageOptions"
              (userChange)="changeAge($event)"
            ></ng5-slider> -->
            <div class="selectParent">
              <select
                class="form-control form-rounded font-montserrat-bold"
                (change)="changeAge($event.target.value)"
              >
                <option value="">גיל</option>
                <option [value]="item" *ngFor="let item of ageList"
                  ><span>{{ item }}</span></option
                >
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 bg-graybox p-0">
      <div class="card bg-transparent border-0">
        <div class="card-header bg-transparent Hmmm4" id="headingOne">
          <div class="mb-0 icon-toggle">
            <a
              class="item-filter-graybox-title"
              data-toggle="collapse"
              data-target="#collapseOne"
              [attr.aria-expanded]="openPrice"
              aria-controls="collapseOne"
              (click)="openPrice = !openPrice"
            >
              <!-- {{ "PRODUCT.Price" | translate }} -->
              מחיר
            </a>
          </div>
        </div>

        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordion"
          *ngIf="openPrice"
        >
          <div class="card-body">
            <ng5-slider
              [(value)]="minValue"
              [(highValue)]="maxValue"
              [options]="options"
              (userChange)="changePrice($event)"
            ></ng5-slider>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="col-md-12 bg-graybox p-0">
      <div class="card bg-transparent border-0">
        <div class="card-header bg-transparent" id="headingOne">
          <div class="mb-0 icon-toggle">
            <a class="item-filter-graybox-title" data-toggle="collapse" data-target="#collapseOne"
              [attr.aria-expanded]="openManufacturer" aria-controls="collapseOne"
              (click)="openManufacturer = !openManufacturer">
              {{ "PRODUCT.Manufacturer" | translate }}
            </a>
          </div>
        </div>

        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion"
          *ngIf="openManufacturer">
          <div class="card-body">
            <div>
              <li *ngFor="let item of manufacturerList; let i = index">
                <div class="form-group">
                  <input type="checkbox" id="{{ item }}_{{ i }}" (change)="checkManufacturer(item, i)" /><label
                    for="{{ item }}_{{ i }}"><span>{{ item }}</span></label>
                                                    </div>
                                                </li>
                                        </div>
                            </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>