import { Injectable } from "@angular/core";
import { HttpWrapperService } from "./http-wrapper.service";

@Injectable({
  providedIn: "root"
})
export class CategoryService {
  constructor(private http: HttpWrapperService) { }

  getAllCategoryItemA(body) {
    return this.http.post(`get_category_items`, body);
  }

  getCategoryBySearch(data) {
    return this.http.post(`item.search`, data);
  }
}
