import { Component, OnInit } from "@angular/core";
import { GeneralService } from "@core/services/general.service";
import { BASEURL } from "app/app.config";

@Component({
  selector: "app-meet-experts",
  templateUrl: "./meet-experts.component.html",
  styleUrls: ["./meet-experts.component.css"]
})
export class MeetExpertsComponent implements OnInit {
  expertList: any;
  baseUrl = BASEURL;
  pageSize = 10;
  currentPage = 1;
  totalRecords: any;
  endTo: any;
  startFrom: number = 1;
  constructor(private generalService: GeneralService) {}
  homeBackExpert: "< חזרה לדף המומחים"
  homeExpert: "<חזרה לדף הבית"

  ngOnInit() {
    this.getExpert(this.currentPage);
  }

  getExpert(currentPage) {
    this.generalService
      .getExperts(this.pageSize, currentPage - 1)
      .subscribe((res: any) => {
        this.expertList = res.message.data;
        this.totalRecords = res.message.total;
        if (currentPage === 1) this.endTo = this.expertList.length;
      });
  }

  pageChanged(e) {
    this.currentPage = e;
    this.getExpert(this.currentPage);
    const startIndex = (this.currentPage - 1) * +this.pageSize;
    const endIndex = Math.min(
      startIndex * 1 + (this.pageSize - 1) * 1,
      this.totalRecords - 1
    );
    this.startFrom = startIndex + 1;
    this.endTo = endIndex + 1;
  }
}
