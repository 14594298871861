<!-- <div style="color: blueviolet;margin-bottom: 10px;visibility: hidden;">
  pageNo : {{pageNo}}
</div> -->
<div class="card h-100">
    <div class="product-image">
        <a (click)="onDetail(listItem,true,pageNo)">
            <img *ngIf="listItem.image" src="{{ baseURL }}{{ listItem.image }}" class="img-fluid product-img" alt="" />
            <img *ngIf="!listItem.image" src="assets/images/no-image.png" alt="" class="img-fluid product-img" />
        </a>
    </div>
    <div class="card-body p-3 " style="height: 120px;">
        <div class="prod_name">
            <a (click)="onDetail(listItem,true,pageNo)" class="text-style-1 text-black mb-1">
                <span>{{ listItem.item_name }}</span>
            </a>
        </div>

        <div class="text-style-2 mb-2" *ngIf="listItem.age_range">
            <!-- {{ "PRODUCT.AgeRange " | translate }} --><span class="ml-2"> גיל </span>‎ {{ listItem.age_range }}
        </div>

        <div>
            <span class="font-montserrat-bold text-lightblue ml-2">
        <span class="text-style-4 ml-2">{{ listItem.after_discount }}
          <span class="text-style-3 mr-1">
            <!-- {{ "CURRENCY_LOGO.value" | translate }}  -->
            ₪
           </span>
            </span>
            </span>
            <del class="text-light-gray small" *ngIf="(listItem.selling_price !== listItem.after_discount)">

        <span class="text-style-6">{{ listItem.selling_price }}
          <span class="text-style-5 mr-1">
            <!-- {{ "CURRENCY_LOGO.value" | translate }}  -->
            ₪
          </span>
        </span>
      </del>
        </div>
        <a (click)="addToCart('proposal',listItem)" class="text-dark ml-2 pointer font-montserrat-regular"><i class="fa fa-tags"></i>
       
          הוסף להצעת המחיר שלי 
        </a>

        <p class="cart-for-quotation text-lightblue p-0" *ngIf="listItem.selling_price === 0">
            <!-- {{ "PRODUCT.AddToCartForQuote" | translate }} -->
            הוסף לסל הצעת מחיר
        </p>
    </div>

    <div class="card-footer bg-white p-2 border-0">
        <button (click)="addToCart('',listItem)" class="add-to-cart-btn-box text-uppercase font-montserrat-bold mt-2 ml-2">
      <span class="font-size14 mr-md-1">
        <!-- {{ "PRODUCT.AddToCart" | translate }} -->
        הוסף לסל 
        <svg class="icon icon-cart">
          <use xlink:href="#009-shopping-cart"></use>
        </svg>
      </span>
      
    </button>
        <button href="javascript:void(0)" class="quick-view-btn mt-2" (click)="openLg(content, listItem)">
      <strong><i class="fa fa-search-plus fa-1x"></i></strong>
    </button>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <span class="close text-white pointer" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
        </span>
    </div>
    <div class="modal-body bg-white">
        <div class="row">
            <div class="col-md-6 text-center">
                <!-- <img [src]="products[0].image" class="img-fluid" alt="" /> -->
                <img *ngIf="currentProduct.image" src="{{ baseURL }}{{ currentProduct.image }}" class="img-fluid product-img" alt="" />
                <img *ngIf="!currentProduct.image" src="assets/images/no-image.png" class="img-fluid product-img" alt="" />
            </div>
            <div class="col-md-6">
                <h4 class="font-montserrat-bold">{{ currentProduct.item_name }}</h4>
                <h5 class="mb-1" *ngIf="currentProduct?.selling_price > 0; elsepart">
                    <span class="font-montserrat-bold text-lightblue">
            {{ currentProduct.after_discount }} <i class="fa fa-shekel"></i></span>

                    <span class="small text-light-gray ml-2">
            <del>
              {{ currentProduct.selling_price }} <i class="fa fa-shekel"></i>
            </del>
          </span>
                </h5>
                <ng-template #elsepart>
                    <div class="cart-for-quotation text-lightblue my-2">
                        <!-- {{ "PRODUCT.AddToCartForQuote" | translate }} -->
                        הוסף לסל הצעת מחיר
                    </div>
                </ng-template>

                <p class="font-montserrat-bold text-lightblue">
                    <!-- In Stock -->
                    במלאי
                </p>
                <p class="small">
                    <!-- {{ "PRODUCT.AgeRange" | translate }} -->
                    גיל {{ currentProduct.age_range }} גיל
                    <!-- {{ "PRODUCT.Year" | translate }} -->
                </p>
                <button class="add-to-cart-btn-box btn-sm mt-2" (click)="addToCart('',currentProduct)">
          <span class="font-size14 mr-md-1">
            <!-- {{ "PRODUCT.AddToCart" | translate }} -->
            הוסף לסל 
            <svg class="icon icon-cart">
              <use xlink:href="#010-add"></use>
            </svg></span>
        </button>
                <p class="mt-3">
                    <a (click)="addWishList(currentProduct)" class="text-dark mr-2 pointer font-montserrat-regular">
                        <i class="fa fa-heart-o"></i>
                        <!-- {{ "PRODUCT.AddToWishlist" | translate }} -->
                        הוסף לרשימת המשאלות
                    </a>
                    <a (click)="addToCart('proposal',currentProduct)" class="text-dark ml-2 pointer font-montserrat-regular"><i class="fa fa-tags"></i>
       
            הוסף להצעת המחיר שלי 
          </a>
                </p>
                <p class="mt-4">
                    <a (click)="gotoDetails(currentProduct,pageNo)" class="text-lightblue pointer">
                        <!-- {{ "PRODUCT.ViewFullDetails" | translate }} -->
                        צפה בפרטים המלאים
                    </a>
                </p>
            </div>
        </div>
    </div>
</ng-template>