
import { NgModule } from "@angular/core";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { StoreFrontComponent } from "./containers/store-front/store-front.component";
import { SharedModule } from "@shared/shared.module";
import * as fromAuthComponents from "./containers/auth";
import { MainNavbarComponent } from "./components/main-navbar/main-navbar.component";
import { Header1Component } from "./components/header1/header1.component";
const layoutComponents = [
  HeaderComponent,
  FooterComponent,
  NavbarComponent,
  StoreFrontComponent,
  MainNavbarComponent,
  Header1Component
];

@NgModule({
  declarations: [...layoutComponents, ...fromAuthComponents.components],
  imports: [SharedModule],
  exports: [...layoutComponents, ...fromAuthComponents.components],
  providers: [],
  bootstrap: []
})
export class LayoutModule {}
