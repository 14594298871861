<div class="card h-100">
  <div class="h-100">
    <img
      *ngIf="listItem.image"
      src="{{ baseUrl }}{{ listItem.image }}"
      class="img-conf"
      alt=""
    />
    <img
      *ngIf="!listItem.image"
      src="assets/images/4.png"
      alt=""
      class="img-fluid"
    />
  </div>
  <div class="card-body h-100">
    <h6 class="font-montserrat-bold">{{ listItem.item_name }}</h6>
    <p class="small">{{ listItem.age_range }}</p>
    <p>
      <span class="font-montserrat-bold text-lightblue"
        ><i class="fa fa-shekel"></i> {{ listItem.selling_price }}</span
      >
      <span class="small ml-2"
        ><del>{{ listItem.selling_price }}</del></span
      >
    </p>
  </div>
  <div class="card-footer">
    <div>
      <button href="#" class="add-to-cart-btn-box text-uppercase mt-2 mr-1">
        <strong>
          <!-- Add To Cart  -->
           הוסף לסל<svg class="icon icon-cart">
          <use xlink:href="#009-shopping-cart"></use>
        </svg></strong>
      </button>
      <button href="#" class="quick-view-btn mt-2">
        <strong><i class="fa fa-search-plus"></i></strong>
      </button>
    </div>
  </div>
</div>
