import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { StoreFrontComponent } from "./layout/containers/store-front/store-front.component";
import { LoginComponent } from "./layout/containers/auth/login/login.component";
import { RegistrationComponent } from "./layout/containers/auth/registration/registration.component";
import { ForgotPasswordComponent } from "./layout/containers/auth/forgot-password/forgot-password.component";
import { VerifyMailComponent } from "./layout/containers/auth/verify-mail/verify-mail.component";
import { UpdatePasswordComponent } from "./layout/containers/auth/update-password/update-password.component";
const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "",
    component: StoreFrontComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)
      },
      {
        path: "products",
        loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
      },

      // {
      //   path: "pricing-quote",
      //   loadChildren: () =>
      //     import("./pricing-quote/pricing-quote.module").then(
      //       m => m.PricingQuoteModule
      //     )
      // },

      {
        path: "account",
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
      }
    ]
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "register",
    component: RegistrationComponent
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent
  },
  {
    path: "verify-mail",
    component: VerifyMailComponent
  },
  {
    path: "change-password",
    component: UpdatePasswordComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled", anchorScrolling: 'enabled', relativeLinkResolution: 'legacy', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
