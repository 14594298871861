<div class="row">
    <div class="col-10">
      <div class="text-md-right text-center">
        <p  class="mb-4">
          <strong>
            מדיניות החזרת מוצרים - ביטול עסקה
          </strong>

        </p>
        <p>
            <strong>
החזרת מוצרים / ביטול עסקה ע"י הלקוח</strong> 
המשתמש יהא רשאי לבטל את העסקה בכתב בהתאם ובכפוף לכל הוראות הדין ולרבות הוראות חוק הגנת הצרכן, התשמ"א – 1981 <strong>(להלן: "חוק הגנת הצרכן")</strong> לרבות התקנות מכוחו
           
        </p>
        <p>
            לקוח רשאי לבטל את העסקה ולקבל החזר כספי החל מיום עשיית העסקה ועד 14 ימים מיום קבלת ההזמנה, או מיום קבלת מסמך פרטי העסקה כנדרש על פי חוק, לפי המאוחר מבינהם. ביטול העסקה ייעשה תוך 14 ימים מיום קבלת המוצר, או מסמך פרטי העסקה, לפי המאוחר ביניהם
       
        </p>
        <p>
            על אף האמור לעיל ובהתאם לחוק, אדם עם מוגבלות, אזרח ותיק או עולה חדש, יהא רשאי לבטל את העסקה באתר בתוך ארבעה חודשים מיום עשיית העסקה, מיום קבלת הזמנה בפועל או מיום קבלת מסמך פרטי העסקה, לפי המאוחר,
            <strong> ובלבד שההתקשרות בעסקה כללה שיחה בין פליי פאנקשן ללקוח, ובכלל</strong>
              זה שיחה באמצעות תקשורת אלקטרונית. החברה תהיה רשאית לבקש מהלקוחה שלעיל תעודה ,כמפורט בחוק, המוכיחה כי היא אדם עם מוגבלות, אזרחית ותיקה או עולה חדשה, לפי העניין
        </p>
    <p>
        :בהתאם לחוק, הלקוח רשאי לבטל עסקה באחת הדרכים שלהלן
    </p>
    <p>
        <strong>
            תמסור עותק מהודעת ביטול החיוב, למעט דמי ביטול בשיעור של 5% או 100 ₪, לפי הנמוך ביניהם וככל שהלקוח שילם דמי משלוח, סכום זה לא יוחזר עם ביטול העסקה. המוצר יוחזר על חשבון המשתמש
        </strong>
    </p>
    <p>
        כל החזר כספי שיינתן על ידי האתר יועבר אך ורק לכרטיס האשראי דרכו שולמה הרכישה, בניכוי דמי משלוח (ככל ונגבו) ובהתאם ללוחות הזמנים של חברת האשראי. למען הסר ספק, החזר כספי יבוצע בהתאם לתשלום בפועל
    </p>
    <p>
        ביטול ההזמנה לפני שליחת המוצר או המוצרים ללקוח (לפני יציאתם ממחסני האתר) לא יחויב בדמי ביטול. ביטול ההזמנה לאחר שליחת המוצר או המוצרים (לאחר יציאתם ממחסני האתר) יחויב בדמי ביטול בשיעור של 5% ממחיר הטובין או 100 שקלים, לפי הנמוך מביניהם. החזר כספי יינתן בהתאם להוראות החוק
    </p>
    <p>
        אין בזכותו של צרכן לבטל עסקה כדי לגרוע מזכותה של החברה לתבוע את נזקיה במקרה של החזרת מוצרים שערכם פחת כתוצאה מהרעה משמעותית במצבם בזמן שהיו ברשות הצרכן, לרבות במקרה של החזרת מוצר שנעשה בו שימוש, שאריזתו נפתחה או נפגמה, שניזוק, שנפגם, שהתקלקל ו/או שספג פגיעה כלשהי. כן מתבקשות הלקוחות, על מנת להימנע מגרימת נזק למוצר, להימנע מעשיית שימוש במוצר ולהחזירו ללא כל פגם ו/או קלקול מכל מין וסוג שהוא
    </p>
    <p>
        :הזכות לביטול העסקה לא תחול על מוצרים המפורטים בחוק ובין השאר, במקרים הבאים:
    </p>
    <p>
        א. מוצרים שהוזמנו והורכבו במיוחד בעבור הצרכן עקב ההזמנה; ב. מוצרים הניתנים להקלטה, לשעתוק או לשכפול
    </p>
    <p>
        המשתמש תבדוק את המוצר מיד עם קבלתו ותודיע לחברה אם המוצר שהתקבל פגום או שונה מהותית מכפי שהמופיע באתר.
    </p>
    <p>
        <strong>
            במקרה של ביטול עסקה עקב פגם במוצר או אי התאמה בין המוצר לבין הפרטים שנמסרו ללקוח או
            עקב אי אספקת המוצר במועד שנקבע או הפרה אחרת של תנאי החוזה (להלן: "אי התאמה") , תחזיר
        </strong>
        החברה, בתוך 14 ימים מיום קבלת ההודעה על הביטול, את אותו חלק ממחיר העסקה ששולם על ידי הצרכן בגין המוצר הפגום, תבטל את החיוב בשל העסקה, תמסור עותק מהודעת ביטול החיוב ולא תגבה דמי ביטול כלשהם מהצרכן. תודיע על כך הלקוחה לחברה בכתב, תבוצע בדיקה מול הלקוחה ובמידת הצורך יתואם המועד לאיסוף המוצר.
    </p>
    <p>
        על המשתמש לבדוק את הפריט/ים מיד עם קבלת ההזמנה לידיו. במידה ופריט כלשהו התקבל בידי המשתמש כשהוא פגום, או כאשר מפרט הפריט בפועל שונה מן המפרט שהוצג באתר, יהא רשאי המשתמש לבטל את העסקה בתוך 14 יום מיום קבלת הפריט לידיו. בביטול בנסיבות אלה לא ייגבו דמי ביטול, כספו של המשתמש יושב לו תוך 14 ימים מקבלת הודעת הביטול, והמוצר יוחזר אל פליי פאנקשן על חשבונה.
    </p>
    <p>
        החזר כספי במקרה של ביטול עסקה שבוצעה באמצעות פייפאל יבוצע באמצעות העברה בנקאית אל חשבון המשתמש, זאת בתוך 14 יום מקבלת הבקשה לביטול העסקה והפריט המוחזר. במקרה של ביטול חלק מן העסקה (החזרת פריט אחד מבין כמה שנרכשו) פליי פאנקשן תחזיר למשתמש, בתוך 14 ימים מיום קבלת ההודעה על הביטול, את אותו חלק ממחיר העסקה ששולם על ידו בגין הפריט המוחזר
    </p>
    <p>
        החזרת המוצר לשם החלפתו במוצר אחר או לקבלת זיכוי כספי תעשה בהתאם למדיניות ההחלפות הכללית הנוהגת בפליי פאנקשן, כפי שתעודכן מעת לעת, בתוך 30 ימים מיום קבלת המוצר על ידי המשתמש, בצירוף חשבונית, ובתנאי שלא נעשה כל שימוש בפריט, אריזתו לא נפתחה ולא הוסרו תוויות הפריט
    </p>
    <p>
        מוצרים שנרכשו בתנאי "מכירה מיוחדת" (למשל 1+1, מוצר שני ב-50%( יוחזרו על פי אותם תנאי הרכישה (בהחזרה של כל המוצרים), ככל שיוחזרו רק חלק מהמוצרים, החזר כספי ו/או זיכוי (לפי העניין) יינתן בהתאם לשיעור ההנחה היחסי שניתן בפועל בגין המוצר, בעת הרכישה
    </p>
    <p>
        בכל שאלה הקשורה לביטול העסקה, בירורים, החלפות, החזרות וכד' יש לפנות אל מחלקת שירות הלקוחות של פליי פאנקשן בדוא"ל: <a href="info@playfunction.co.il">info@playfunction.co.il</a> או בטלפון : 02-650-6262
    </p>
    <p>
        <strong>
            ניתן לבטל רכישת מוצרים בתוך 14 (ארבעה עשר) ימים מיום קבלתם, כשהם ארוזים באריזתם המקורית ולא נפתחו כלל ולא נעשה בהם שימוש. עלות המשלוח לא תזוכה בכל מקרה
        </strong>
    </p>


    <p>
        <strong>
            במידה ומוצר עומד בתנאי ההחזרה, החזר כספי / זיכוי יינתן על מחיר המוצר ששולם פחות דמי המשלוח והפחתה של 5% מעלות המוצר, עפ"י הקבוע בחוק. למען הסר ספק, אין החנות מקבלת מוצרים להחזרה / החלפה בדואר רגיל / דואר רשום
        </strong>
    </p>
    <p>
        <strong>
            מדיניות החלפת מוצרים
        </strong>
    </p>
    <p>
        <strong>
            החלפה - תנתן אפשרות ללקוח להמיר מוצרים בפריטים אחרים  במקומם באותו שווי בתנאי שעדיין לא נעשה בהם שימוש
        </strong>
    </p>
    <p>
        <strong>
            אנו מוציאים שליח עם המוצרים שאתם בוחרים במקום המוצרים המוחזרים, מדובר בעלות של שני משלוחים
        </strong>
    </p>
      </div>


      <div class="mt-2">
        <p>
          <strong> פליי פאנקשן בע''מ</strong>
        </p>
       
        <p>טלפון:<strong>02-650-6262</strong></p>
        <p>נייד:<strong>454 8432 054</strong></p>
        <p>דוא"ל: info@playfunction.co.il</p>
        <p><u>אתר האינטרנט שלנו בלחיצה כאן</u></p>
      </div>


      </div>
      </div>
      