import { AfterViewInit, Component, OnInit } from "@angular/core";
import { UiService } from "@core/services/ui.service";

@Component({
  selector: "app-store-front",
  templateUrl: "./store-front.component.html",
  styleUrls: ["./store-front.component.scss"],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class StoreFrontComponent implements OnInit,AfterViewInit {
  isMobailView=true;
  onResize(event){
if( event.target.innerWidth > 600){
  this.isMobailView=true;
}else{
  this.isMobailView=false;
}
    }
  sideBarStatus = false;
  constructor(private uiService: UiService) {}
  ngAfterViewInit(): void {
   
  }

  ngOnInit() {
    this.sideBarStatus = this.uiService.hideSideNav;
  }

  getSideBarState() {
    this.uiService.getToggleStatus();
  }
  setSideBarState(e) {

    this.sideBarStatus = e;
  }

  toggleSidebar() {
    this.uiService.toggleSideNav();
    this.sideBarStatus = !this.sideBarStatus;
  }
  change=100;
  chnageDetection(data){
   this.change=this.change+1;
  }
}
