<div class="container-fluid">
  <div class="row">
    <div class="mt-4 col-12">
      <div class="d-flex justify-content-center">
        <div class="card" style="width: 500px;">
          <div class="card-header text-center border-bottom-0">
            <a routerLink="/">
              <img src="assets/images/Play-function.png" width="150" class="img-fluid" alt="" /></a>
          </div>
          <div class="card-body p-2">
            <div *ngIf="!passwordStatus">
              <h3 class="mt-4 mb-2 text-center"><strong>
                  <!-- Password Assistance -->
                  <!-- {{ "FORGET-PASSWORD.Assistance" | translate }} -->
                  סיוע בשחזור סיסמה 

                </strong></h3>
              <p class="small p-1">
                <!-- Enter the email address or mobile phone number associated with
                your playfunction account. -->
               <span class="float-left">
                <!-- {{ "FORGET-PASSWORD.Assistance-Desc" | translate }} -->
                הזן את כתובת הדואר האלקטרוני שלך 
               </span>
              </p>
              <div>
                <form [formGroup]="forgotPasswordForm" (ngSubmit)="submitForm()">
                  <div class="form-group">
                    <input type="text" class="form-control mt-4 mb-4" placeholder="הזן את כתובת הדואר האלקטרוני שלך"
                      formControlName="user" />
                  </div>
                  <div *ngIf="passwordError">
                    <div class="alert alert-danger" role="alert">
                      <h4 class="alert-heading">Error!</h4>
                      <p>
                        <!-- Please enter correct email -->
                        <!-- {{ "FORGET-PASSWORD.Error" | translate }} -->
                        אנא הכנס דוא ל נכון 
                      </p>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-warning btn-block btn-rounded">
                    <!-- CONTINUE -->
                    <!-- {{ "FORGET-PASSWORD.Button" | translate }} -->
                    שלח
                  </button>

                  <h6 class="mt-5 p-1">
                    <!-- Has your email address or mobile phone number changed? -->
                     <span class="float-left">
                      <!-- {{ "FORGET-PASSWORD.Head" | translate }}  -->
                      האם כתובת הדואר האלקטרוני שלך השתנתה?

                    </span>
                  </h6>
                  <p class="small p-1">
                    <!-- If you no longer use the e-mail address associated with your
                    Playfunction account, you may contact Customer Service for
                    help restoring access to your account. -->
                   <span class="float-left text-left">
                     <!-- {{ "FORGET-PASSWORD.Description" | translate }} -->
                     אם אינך משתמש עוד בכתובת הדוא ל המשויכת לחשבון באתר של פליי פאנקשן, תוכל לפנות אלינו לקבלת עזרה . נשמח לסייע .
                    </span> 
                  </p>

                  <p class="mt-2 p-2">
                    <!-- Customer Service -->
                    <!-- <a href="#" class="text-light-blue small ">
                      
                      {{ "FORGET-PASSWORD.Customer-Service" | translate }}
                      שירות לקוחות
                    </a> -->
                  </p>
                </form>
              </div>
            </div>
            <!-- *ngIf="passwordStatus" -->
            <div *ngIf="passwordStatus">
              <h3 class="mt-4 mb-2"><strong>
                  <!-- Password Assistance -->
                  <!-- {{ "FORGET-PASSWORD.Assistance" | translate }} -->
                  סיוע בשחזור סיסמה
                </strong></h3>
              <p class="small p-1">
                <!-- {{ "FORGET-PASSWORD.Enter-New-Password" | translate }} -->
                הכנס סיסמה חדשה
              </p>
              <form [formGroup]="updatePasswordForm" (ngSubmit)="updatePassword()">
                <div class="form-group">

                  <div class="input-group">
                    <!-- <label for=""> Password</label> -->
                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control inputElem" id="password"
                      placeholder="הזן את הסיסמה" formControlName="password" [ngClass]="
                      updatePasswordForm.controls['password'].invalid
                        ? 'is-invalid'
                        : ''
                    " />
                    <!-- <div class="input-group-prepend">
                      <button type="button" class="input-group-text" (click)="toggleFieldTextType()"
                        style="border-radius: 25px 0px 0px 25px; background: #bfcedc6e">
                        <i [class]="fieldTextType ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                      </button>
                    </div> -->



                    <!-- <label for=""> Password</label>
                  <input
                    id="password"
                    formControlName="password"
                    placeholder="Password"
                    type="password"
                    class="form-control"
                    [ngClass]="
                      updatePasswordForm.controls['password'].invalid
                        ? 'is-invalid'
                        : ''
                    "
                  /> -->

                    <!-- <div class="pull-right pointer">
                      <ng-template #tipContent>
                        <p>Must be at least 8 characters!</p>
                        <p>Must contain at least 1 number!</p>
                        <p>Must contain at least 1 in Capital Case!</p>
                        <p>Must contain at least 1 Letter in Small Case!</p>
                        <p>Must contain at least 1 Special Character!</p>
                      </ng-template>

                      <i class="fa fa-info-circle" aria-hidden="true" [ngbTooltip]="tipContent"></i>
                    </div> -->
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="
                    isSubmitted &&
                    updatePasswordForm
                      .get('password')
                      .hasError('required')
                  ">This is required field</small>
                  </div>

                </div>

                <div class="form-group mt-4">
                  <div class="input-group">
                    <input [type]="fieldTextType1 ? 'text' : 'password'" class="form-control" placeholder="אשר סיסמה"
                      formControlName="confirmPassword" [ngClass]="
                      updatePasswordForm.controls['confirmPassword'].invalid
                        ? 'is-invalid'
                        : ''
                    " />
                    <div class="input-group-prepend">
                      <button type="button" class="input-group-text" (click)="toggleFieldTextType1()"
                        style="border-radius: 25px 0px 0px 25px; background: #bfcedc6e">
                        <i [class]="fieldTextType1 ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                      </button>
                    </div>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="
                    isSubmitted &&
                    updatePasswordForm
                      .get('confirmPassword')
                      .hasError('required')
                  ">This is required field</small>
                    <small class="text-danger" *ngIf="
                    updatePasswordForm
                      .get('confirmPassword')
                      .hasError('passwordMatch')
                  ">Password must match</small>
                  </div>
                </div>
                <button type="submit" class="btn btn-warning btn-block btn-rounded">
                  <!-- {{ "FORGET-PASSWORD.Update-Button" | translate }} -->
                  עדכן סיסמה 
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>