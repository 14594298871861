import { Component, OnInit, ContentChildren, QueryList } from "@angular/core";
import { ToggleComponent } from "./toggle/toggle.component";

@Component({
  selector: "app-accordion",
  templateUrl: "./accordion.component.html",
  styleUrls: ["./accordion.component.css"]
})
export class AccordionComponent {
  @ContentChildren(ToggleComponent) toggles: QueryList<ToggleComponent>;

  ngAfterContentInit() {
    this.toggles.map((toggle, i) => {
      toggle.setActive.subscribe(() => this.setActive(i));
    });
  }

  setActive(index) {
    this.toggles.map((toggle, i) => {
      index !== i || toggle.active === true
        ? (toggle.active = false)
        : (toggle.active = true);
    });
  }
}
