<div class="bg-red mt-4">
    <div class="to-top" (click)="scrollToTop()" [ngClass]="{ 'show-scrollTop': windowScrolled }">
        <!----- Added By Ashwin - 10/5/2022 ----->
        <img src="assets/images/scroll-up.png" alt="to-top-icon" />
    </div>
    <div class="container">
        <div class="row p-md-5 p-2">
            <div class="col-md-6 col-12">
                <div class="redbox-text text-md-right text-center mb-3">
                    <!-- {{ "FOOTER.ContactText1" | translate }} -->
                    חברי מועדון נהנים מהנחות ועדכונים על
                    <br class="d-none d-md-block" />
                    <!-- {{ "FOOTER.ContactText2" | translate }} -->
                    מוצרים חדשים ומבצעים באתר של פליי פאנקשן
                </div>
            </div>

            <div class="col-md-4 col-8">
                <div class="form-group mt-2" id="subscription-email">
                    <input #email id="" type="email" class="form-control-lg form-rounded bg-transparent text-white border-white2x w-100" placeholder="כתובת דואר אלקטרוני">
                    <!-- "{{ 'FOOTER.ContactPlaceHolder' | translate }}" /> -->
                </div>
            </div>
            <div class="col-md-2 col-4">
                <button (click)="addSubscribe(email.value)" class="btn btn-light btn-whitish btn-rounded mt-2 redbtn btn-submit text-uppercase">
          <strong class="font-size18">
            <!-- {{ "MISC.Submit" | translate }} -->
            הרשמו
          </strong>
        </button>
            </div>
        </div>
    </div>
</div>

<section class="footer-bg p-2">
    <div class="container d-none d-md-block d-sm-none">
        <div class="row mt-4">
            <div class="col-md-3 text-md-left text-center">
                <div class="mt-1 mt-md-5">
                    <img src="assets/images/logo_new.png" class="img-fluid" width="150" />
                    <hr class="d-md-none" />
                </div>
            </div>

            <div class="col-md-2 col-12 col-sm-6 mt-4">
                <p class="mb-2">
                    <a (click)="open()" class="text-dark font-montserrat-semi-bold" *ngIf="!isUserLoggedIn">
                        <svg class="icon icon-shield mr-1">
              <use xlink:href="#projects"></use>
            </svg>

                        <!-- {{ "NAVBAR.signIn" | translate }} -->
                        התחברות / הרשמה
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/account/wishlist" class="text-dark my-2 font-montserrat-semi-bold" *ngIf="isUserLoggedIn">
                        <svg class="icon icon-heart mr-1">
              <use xlink:href="#002-heart"></use>
            </svg>
                        <!-- Wish list -->
                        <!-- {{ "NAVBAR.Wishlist" | translate }} -->
                        רשימת המשאלות שלי
                    </a>
                </p>
                <p class="mb-2">
                    <!-- /pricing-quote -->
                    <a routerLink="/account/proposal-list" class="text-dark my-2 font-montserrat-semi-bold" *ngIf="isUserLoggedIn">
                        <svg class="icon icon-pricetag mr-1">
              <use xlink:href="#003-price-tag"></use>
            </svg>
                        <!-- Pricing Request -->
                        <!-- {{ "NAVBAR.PricingRequest" | translate }} -->
                        בקשה להצעת מחיר
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/help" class="text-dark my-2 font-montserrat-semi-bold">
                        <svg class="icon icon-question mr-1">
              <use xlink:href="#004-question"></use>
            </svg>
                        <!-- {{ "NAVBAR.Help" | translate }}
             -->
                        עזרה
                        <!-- Help -->
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/projects" class="text-dark my-2 font-montserrat-semi-bold">
                        <svg class="icon icon-projects mr-1">
              <use xlink:href="#014-storage"></use>
            </svg>
                        <!-- {{ "NAVBAR.Projects" | translate }} -->
                        פרויקטים
                        <!-- Projects -->
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/blog" class="text-dark my-2 font-montserrat-semi-bold">
                        <svg class="icon icon-blog mr-1">
              <use xlink:href="#006-blog"></use>
            </svg>
                        <!-- {{ "NAVBAR.Blog" | translate }} -->
                        בלוג
                        <!-- Blog -->
                    </a>
                </p>
                <!-- <p class="mb-2">
                    <a routerLink="/aboutUs" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/006-blog.svg" alt="aboutUs" width="15px" class="mr-1" />
                  
                        עלינו
                  
                    </a>
                </p> -->
                <!-- <p class="mb-2">
                    <a routerLink="/contactUs" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/006-blog.svg" alt="contactUS" width="15px" class="mr-1" /> צור קשר
                   
                    </a>
                </p> -->
                <!-- <p class="mb-2">
                    <a routerLink="/delivery-policy" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/006-blog.svg" alt="signin" width="15px" class="mr-1" />
                   
                        תקנון האתר
                    </a>
                </p> -->

                <!-- <p class="mb-2">
                    <a routerLink="/cancellation-policy" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/006-blog.svg" alt="signin" width="15px" class="mr-1" />
             
                        
                        מדיניות החזרת מוצרים - ביטול עסקה
                    </a>
                </p> -->

                <!-- <p class="mb-2">
                    <a routerLink="/shipping-policy" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/006-blog.svg" alt="signin" width="15px" class="mr-1" />
                       
                        מדיניות משלוחים
                    </a>
                </p> -->

                
            </div>

            <div class="col-md-2 col-6 mt-4">
                <p class="text-dark font-montserrat-bold mb-1">
                    <strong>
         
            <!-- קטגוריות נפוצות -->
          </strong>
                </p>
                <p class="mb-2">
                    <a routerLink="/aboutUs" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/006-blog.svg" alt="aboutUs" width="15px" class="mr-1" />
                  
                        עלינו
                  
                    </a>
                </p>
                
                <p class="mb-2">
                    <a routerLink="/contactUs" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/006-blog.svg" alt="contactUS" width="15px" class="mr-1" /> צור קשר
                   
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/cancellation-policy" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/006-blog.svg" alt="signin" width="15px" class="mr-1" />
             
                        
                        מדיניות החזרת מוצרים - ביטול עסקה
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/shipping-policy" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/006-blog.svg" alt="signin" width="15px" class="mr-1" />
                        <!-- {{ "NAVBAR.deliveryPolicy" | translate }} -->
                        <!-- מדיניות משלוחים -->
                        <!-- Blog -->
                        מדיניות משלוחים
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/delivery-policy" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/006-blog.svg" alt="signin" width="15px" class="mr-1" />
                        <!-- {{ "NAVBAR.deliveryPolicy" | translate }} -->
                        <!-- מדיניות משלוחים -->
                        <!-- Blog -->
                        תקנון האתר
                    </a>
                </p>
                <!-- <p class="mb-2">
    
                    <a class="text-dark font-montserrat-semi-bold">  
          מטפלים
      
          </a>
                </p> -->
                <!-- <p class="mb-2">
               
                    <a class="text-dark font-montserrat-semi-bold">  
                    הורים
                    </a>
                </p> -->
                <!-- <p class="mb-2">
           
                    <a class="text-dark font-montserrat-semi-bold">
                     
                        בתי ספר
                    </a>
                </p> -->
                <!-- <p class="mb-2">
                 
                    <a class="text-dark font-montserrat-semi-bold">  
                    מרכזים קהילתיים
                    </a>
                </p> -->
                <!-- <p class="mb-2">
                
                    <a class="text-dark font-montserrat-semi-bold">  
                    תינוקות 0-3
                    </a>
                </p> -->
                <!-- <p class="mb-2">
                
                    <a class="text-dark font-montserrat-semi-bold">  
                    משחקים
                    </a>
                </p> -->
                <!-- <p class="mb-2">
            
                    <a class="text-dark font-montserrat-semi-bold">  
                    מתקני חצר‎
                    </a>
                </p> -->
                <!-- <p class="mb-2">
                
                    <a class="text-dark font-montserrat-semi-bold">  
                    ריהוט
                    </a>
                </p>
                <p class="mb-2">
                 
                    <a class="text-dark font-montserrat-semi-bold">
                     
                        SALE
                    </a>
                </p> -->
            </div>

            <div class="col-md-2 col-6 mt-4">
                <hr class="d-none d-sm-block d-md-none" />
                <p class="text-dark font-montserrat-bold mb-1">
                    <strong>
        
            יש לכם שאלה?
          </strong>
                </p>
                <p class="mb-2">
                    <a href="#" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "FOOTER.Address" | translate }}: -->
                        כתובת תכלת מרדכי, ירושלים 9439623:
                    </a>
                </p>
                <p class="mb-2">
                    <a href="#" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "FOOTER.Email" | translate }} -->
                        דואל: info@playfunction.co.il
                    </a>
                </p>
                <p class="mb-2">
                    <a href="#" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "FOOTER.PhoneNumber" | translate }} -->
                        02-6506262
                    </a>
                </p>
                <p class="mb-2">
                    <a href="#" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "FOOTER.Mobile" | translate }} -->
                        054-84-32-454
                    </a>
                </p>
            </div>

            <div class="col-12 col-sm-6 col-md-3 mt-4 text-center text-md-right">
                <hr class="d-none d-sm-block d-md-none" />
                <p class="text-dark font-montserrat-bold mb-3 ml-md-2 d-none d-md-block d-sm-block">
                    <strong>
            <!-- {{ "FOOTER.LetsSocialize" | translate }} -->
            עקבו אחרינו
          </strong>
                </p>
                <a href="#">
                    <div class="social-circle">
                        <i class="fa fa-twitter"></i>
                    </div>
                </a>

                <a href="https://www.instagram.com/playfunction/?hl=en" target="_blank">
                    <div class="social-circle">
                        <span class="ig"><i class="fa fa-instagram"></i></span>
                    </div>
                </a>
                <a href="https://youtu.be/NSEu1arldDU" class="yt" target="_blank">
                    <div class="social-circle">
                        <i class="fa fa-youtube"></i>
                    </div>
                </a>
                <a href="https://www.facebook.com/pages/category/Toy-Store/Play-Function-164896593950153/" target="_blank" class="fb">
                    <div class="social-circle">
                        <i class="fa fa-facebook"></i>
                    </div>
                </a>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <hr />
            </div>

            <div class="col-md-6 col-12">
                <p class="mb-1 text-center text-md-right mt-md-1 font14">
                    <a href="#" class="text-black text-sm">
                        <!-- {{ "FOOTER.Copyright" | translate }} -->
                        זכויות יוצרים
                    </a>
                    |
                    <a href="#" class="text-black text-sm">
                        <!-- {{ "FOOTER.TermsAndConditions" | translate }} -->
                        תנאים
                    </a>
                    |
                    <a href="#" class="text-black text-sm">
                        <!-- {{ "FOOTER.PrivacyPolicy" | translate }} -->
                        מדיניות פרטיות
                    </a>
                    <!-- |
          <a href="#" class="text-black text-sm"
            >{{ "FOOTER.PoweredBy" | translate }}</a
          > -->
                </p>
            </div>

            <div class="col-md-6 col-12">
                <div class="d-flex pull-right">
                    <div class="">
                        <img src="assets/images/ssl.png" alt="ssl" class="mr-1 img-fluid" />
                    </div>
                    <div>
                        <img src="assets/images/pci.png" alt="pci" class="mr-1 img-fluid" />
                    </div>
                    <div>
                        <img src="assets/images/credit-guard.png" alt="Credit Guard" class="mr-1 img-fluid" />
                    </div>

                    <div>
                        <img src="assets/images/ms-logo.png" alt="Mastecard" class="mr-1 img-fluid" />
                    </div>

                    <div>
                        <img src="assets/images/amercian exp.png" alt="Americal Express" class="mr-1 img-fluid" />
                    </div>

                    <div>
                        <img src="assets/images/paypal.png" alt="paypal" class="mr-1 img-fluid" />
                    </div>
                </div>
                <p class="pull-right mb-1"></p>
            </div>
        </div>
    </div>

    <div class="container-fluid d-none d-md-none d-sm-block">
        <div class="row mt-4">
            <div class="col-sm-4 mt-4">
                <div class="mb-4">
                    <img src="assets/images/logo_new.png" class="img-fluid" width="150" />
                </div>

                <p class="mb-2">
                    <a (click)="open()" class="text-dark font-montserrat-semi-bold" *ngIf="!isUserLoggedIn"><img src="assets/images/svg-icons/001-user.svg" alt="signin" width="15px" class="mr-1" />
                        <!-- {{ "NAVBAR.signIn" | translate }} -->
                        התחברות / הרשמה
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/account/wishlist" class="text-dark my-2 font-montserrat-semi-bold" *ngIf="isUserLoggedIn"><img src="assets/images/svg-icons/002-heart.svg" alt="signin" width="15px" class="mr-1" />
                        <!-- Wish list -->
                        <!-- {{ "NAVBAR.Wishlist" | translate }} -->
                        רשימת המשאלות שלי
                    </a>
                </p>
                <p class="mb-2">
                    <a [routerLink]="['/account/proposal-list']" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/003-price-tag.svg" alt="signin" width="15px" class="mr-1" />
                        <!-- Pricing Request -->
                        <!-- {{ "NAVBAR.PricingRequest" | translate }} -->
                        בקשה להצעת מחיר
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/help" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/004-question.svg" alt="signin" width="15px" class="mr-1" />
                        <!-- {{ "NAVBAR.Help" | translate }} -->
                        עזרה
                        <!-- Help -->
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/projects" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/012-business-and-finance.svg" alt="signin" width="15px" class="mr-1" />
                        <!-- {{ "NAVBAR.Projects" | translate }} -->
                        פרויקטים
                        <!-- Projects -->
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/blog" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/006-blog.svg" alt="signin" width="15px" class="mr-1" />
                        <!-- {{ "NAVBAR.Blog" | translate }} -->
                        בלוג
                        <!-- Blog -->
                    </a>
                </p>
            </div>

            <div class="col-sm-4 mt-4">
                <p class="text-dark font-montserrat-bold mb-1">
                    <strong>
            <!-- {{ "FOOTER.MainCategories" | translate }} -->
            קטגוריות נפוצות
          </strong>
                </p>
                <p class="mb-2">
                    <a routerLink="/products/Therapists" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "MENU.Therapists" | translate }} -->
                        מטפלים
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/products/Parents" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "MENU.Parents" | translate }} -->
                        הורים
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/products/Schools" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "MENU.Schools" | translate }} -->
                        בתי ספר
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/products/Community Center" class="text-dark font-montserrat-semi-bold">
          מרכזים קהילתיים
            <!-- {{ "MENU.CommunityCenter" | translate }} -->
          </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/products/Baby (0-12 months)" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "MENU.Baby" | translate }} -->
                        תינוקות 0-3
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/products/Toys" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "MENU.Toys" | translate }} -->
                        משחקים
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/products/Outdoor Toy" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "MENU.OutdoorToys" | translate }} -->
                        מתקני חצר‎
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/products/Furniture" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "MENU.Furniture" | translate }} -->
                        ריהוט
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/products/Offers & Sales" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "MENU.OffersSale" | translate }} -->
                        SALE
                    </a>
                </p>
            </div>

            <div class="col-sm-4 mt-4">
                <p class="text-dark font-montserrat-bold mb-1">
                    <strong>
            
            <!-- {{ "FOOTER.AnyQuestions" | translate }} -->
            יש לכם שאלה?

          </strong>
                </p>
                <p class="mb-2">
                    <a href="#" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "FOOTER.Address" | translate }}: -->
                        כתובת תכלת מרדכי, ירושלים 9439623
                    </a>
                </p>
                <p class="mb-2">
                    <a href="#" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "FOOTER.Email" | translate }} -->
                        דואל: info@playfunction.co.il
                    </a>
                </p>
                <p class="mb-2">
                    <a href="#" class="text-dark font-montserrat-semi-bold">
                        <!-- {{ "FOOTER.PhoneNumber" | translate }} -->
                        02-6506262
                    </a>
                </p>
                <p class="mb-2">
                    <a href="#" class="text-dark font-montserrat-semi-bold">

                        <!-- {{ "FOOTER.Mobile" | translate }} -->
                        054-84-32-454
                    </a>
                </p>

                <div class="mt-4">
                    <p class="text-dark font-montserrat-bold mb-2 ml-md-2 d-none d-md-block d-sm-block">
                        <strong>
              <!-- {{ "FOOTER.LetsSocialize" | translate }} -->
              עקבו אחרינו
            </strong>
                    </p>
                    <a href="https://youtu.be/NSEu1arldDU" target="_blank" class="yt">
                        <div class="social-circle">
                            <i class="fa fa-youtube"></i>
                        </div>
                    </a>

                    <a href="https://www.instagram.com/playfunction/?hl=en" target="_blank" class="ig">
                        <div class="social-circle">
                            <i class="fa fa-instagram"></i>
                        </div>
                    </a>
                    <a href="#" class="tw">
                        <div class="social-circle">
                            <i class="fa fa-twitter"></i>
                        </div>
                    </a>
                    <a href="https://www.facebook.com/pages/category/Toy-Store/Play-Function-164896593950153/" target="_blank" class="fb">
                        <div class="social-circle">
                            <i class="fa fa-facebook"></i>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <hr />
            </div>

            <div class="col-md-6 col-12">
                <p class="mb-1 text-center text-md-right mt-md-2 font14">
                    <a href="#" class="text-black text-sm">

                        <!-- {{ "FOOTER.Copyright" | translate }} -->
                        זכויות יוצרים
                    </a>
                    |
                    <a href="#" class="text-black text-sm">
                        <!-- {{ "FOOTER.TermsAndConditions" | translate }} -->
                        תנאים
                    </a>
                    |
                    <a href="#" class="text-black text-sm">

                        <!-- {{ "FOOTER.PrivacyPolicy" | translate }} -->
                        מדיניות פרטיות
                    </a>
                    <!-- |
          <a href="#" class="text-black text-sm"
            >{{ "FOOTER.PoweredBy" | translate }}</a
          > -->
                </p>
            </div>

            <div class="col-md-6 col-12">
                <div class="d-flex pull-right">
                    <div class="">
                        <img src="assets/images/ssl.png" alt="ssl" class="mr-1 img-fluid" />
                    </div>
                    <div>
                        <img src="assets/images/pci.png" alt="pci" class="mr-1 img-fluid" />
                    </div>
                    <div>
                        <img src="assets/images/credit-guard.png" alt="Credit Guard" class="mr-1 img-fluid" />
                    </div>

                    <div>
                        <img src="assets/images/ms-logo.png" alt="Mastecard" class="mr-1 img-fluid" />
                    </div>

                    <div>
                        <img src="assets/images/amercian exp.png" alt="Americal Express" class="mr-1 img-fluid" />
                    </div>

                    <div>
                        <img src="assets/images/paypal.png" alt="paypal" class="mr-1 img-fluid" />
                    </div>
                </div>
                <p class="pull-right mb-1"></p>
            </div>
        </div>
    </div>

    <div class="container d-block d-md-none d-sm-none">
        <div class="row">
            <div class="col-md-2 col-12 text-md-left text-center">
                <div class="mt-1 mt-md-5">
                    <img src="assets/images/logo_new.png" class="img-fluid" width="150" />
                    <hr class="d-md-none" />
                </div>
            </div>

            <div class="col-md-12 p-0">
                <div class="card bg-transparent border-left-0 border-right-0 border-top-0">
                    <div class="card-header bg-transparent border-bottom-0" id="headingOne">
                        <h4 class="mb-0 icon-toggle">
                            <a class="a-collapse-footer" data-toggle="collapse" data-target="#collapseOne" [attr.aria-expanded]="main_categories" aria-controls="collapseOne" (click)="main_categories = !main_categories">
                                <strong>
                  קטגוריות נפוצות
                  <!-- {{ "FOOTER.MainCategories" | translate }} -->
                </strong>
                            </a>
                        </h4>
                    </div>

                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion" *ngIf="main_categories">
                        <div class="card-body pt-1">
                            <div class="row">
                                <div class="col-md-12 col-12 mt-1">
                                    <p class="mb-2">
                                        <a routerLink="/products/Therapists" class="text-dark font-montserrat-semi-bold">
                                            <!-- {{ "MENU.Therapists" | translate }} -->
                                            מטפלים
                                        </a>
                                    </p>
                                    <p class="mb-2">
                                        <a routerLink="/products/Parents" class="text-dark font-montserrat-semi-bold">
                                            <!-- {{ "MENU.Parents" | translate }} -->
                                            הורים
                                        </a>
                                    </p>
                                    <p class="mb-2">
                                        <a routerLink="/products/Schools" class="text-dark font-montserrat-semi-bold">
                                            <!-- {{ "MENU.Schools" | translate }} -->
                                            בתי ספר
                                        </a>
                                    </p>
                                    <p class="mb-2">
                                        <a routerLink="/products/Community Center" class="text-dark font-montserrat-semi-bold">
                                            <!-- {{ "MENU.CommunityCenter" | translate }} -->
                                            מרכזים קהילתיים

                                        </a>
                                    </p>
                                    <p class="mb-2">
                                        <a routerLink="/products/Baby (0-12 months)" class="text-dark font-montserrat-semi-bold">
                                            <!-- {{ "MENU.Baby" | translate }} -->
                                            תינוקות 0-3
                                        </a>
                                    </p>
                                    <p class="mb-2">
                                        <a routerLink="/products/Toys" class="text-dark font-montserrat-semi-bold">
                                            <!-- {{ "MENU.Toys" | translate }} -->
                                            משחקים
                                        </a>
                                    </p>
                                    <p class="mb-2">
                                        <a routerLink="/products/Outdoor Toy" class="text-dark font-montserrat-semi-bold">
                                            <!-- {{ "MENU.OutdoorToys" | translate }} -->

                                            מתקני חצר‎
                                        </a>
                                    </p>
                                    <p class="mb-2">
                                        <a routerLink="/products/Furniture" class="text-dark font-montserrat-semi-bold">
                                            <!-- {{ "MENU.Furniture" | translate }} -->
                                            ריהוט
                                        </a>
                                    </p>
                                    <p class="mb-2">
                                        <a routerLink="/products/Offers & Sales" class="text-dark font-montserrat-semi-bold">

                                            <!-- {{ "MENU.OffersSale" | translate }} -->
                                            SALE
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 p-0">
                <div class="card bg-transparent border-left-0 border-right-0 border-top-0">
                    <div class="card-header bg-transparent border-bottom-0" id="headingOne">
                        <h4 class="mb-0 icon-toggle">
                            <a class="a-collapse-footer" data-toggle="collapse" data-target="#collapseOne" [attr.aria-expanded]="any_questions" aria-controls="collapseOne" (click)="any_questions = !any_questions">
                                <strong>
                  
                  <!-- {{ "FOOTER.AnyQuestions" | translate }} -->
                  יש לכם שאלה?
                </strong>
                            </a>
                        </h4>
                    </div>

                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion" *ngIf="any_questions">
                        <div class="card-body pt-1">
                            <div class="row">
                                <div class="col-md-12 col-12 mt-1">
                                    <hr class="d-none d-sm-block d-md-none" />

                                    <p class="mb-2">
                                        <a href="#" class="text-dark font-montserrat-semi-bold">

                                            <!-- {{ "FOOTER.Address" | translate }}: -->
                                            כתובת תכלת מרדכי, ירושלים 9439623
                                        </a>
                                    </p>
                                    <p class="mb-2">
                                        <a href="#" class="text-dark font-montserrat-semi-bold">

                                            <!-- {{ "FOOTER.Email" | translate }} -->
                                            דואל: info@playfunction.co.il
                                        </a>
                                    </p>
                                    <p class="mb-2">
                                        <a href="#" class="text-dark font-montserrat-semi-bold">

                                            <!-- {{ "FOOTER.PhoneNumber" | translate }} -->
                                            02-6506262
                                        </a>
                                    </p>
                                    <p class="mb-2">
                                        <a href="#" class="text-dark font-montserrat-semi-bold">
                                            <!-- {{ "FOOTER.Mobile" | translate }} -->
                                            054-84-32-454
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-6 col-sm-6 mt-4">
                <p class="mb-2">
                    <a (click)="open()" class="text-dark font-montserrat-semi-bold" *ngIf="!isUserLoggedIn"><img src="assets/images/svg-icons/001-user.svg" alt="signin" width="15px" class="mr-1" />
                        <!-- {{ "NAVBAR.signIn" | translate }} -->
                        התחברות / הרשמה
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/account/wishlist" class="text-dark my-2 font-montserrat-semi-bold" *ngIf="isUserLoggedIn"><img src="assets/images/svg-icons/002-heart.svg" alt="signin" width="15px" class="mr-1" />
                        <!-- Wish list -->
                        <!-- {{ "NAVBAR.Wishlist" | translate }} -->

                        רשימת המשאלות שלי
                    </a>
                </p>
                <p class="mb-2">
                    <a (click)="onRouteing(1)" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/003-price-tag.svg" alt="signin" width="15px" class="mr-1" />
                        <!-- Pricing Request -->
                        <!-- {{ "NAVBAR.PricingRequest" | translate }} -->

                        בקשה להצעת מחיר
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/help" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/004-question.svg" alt="signin" width="15px" class="mr-1" />
                        <!-- {{ "NAVBAR.Help" | translate }} -->

                        עזרה
                        <!-- Help -->
                    </a>
                </p>
                <p class="mb-2">
                    <a (click)="logout()" style="cursor: pointer;" class="text-dark my-2 font-montserrat-semi-bold" *ngIf="isUserLoggedIn"><img src="assets/images/svg-icons/001-user.svg" alt="Logout" width="15px" />
                        <!-- Logout -->
                        התנתקות
                        <!-- {{ "NAVBAR.Logout" | translate }} -->
                    </a>
                </p>
            </div>

            <div class="col-md-6 col-6 col-sm-6 mt-4">
                <p class="mb-2">
                    <a routerLink="/aboutUs" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/006-blog.svg" alt="aboutUs" width="15px" class="mr-1" /> About Us
                        <!-- Blog -->
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/contactUs" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/006-blog.svg" alt="contactUs" width="15px" class="mr-1" /> Contact Us
                        <!-- Blog -->
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/projects" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/012-business-and-finance.svg" alt="signin" width="15px" class="mr-1" />
                        <!-- {{ "NAVBAR.Projects" | translate }} -->
                        פרויקטים
                        <!-- Projects -->
                    </a>
                </p>
                <p class="mb-2">
                    <a routerLink="/blog" class="text-dark my-2 font-montserrat-semi-bold"><img src="assets/images/svg-icons/006-blog.svg" alt="signin" width="15px" class="mr-1" />
                        <!-- {{ "NAVBAR.Blog" | translate }} -->
                        בלוג
                        <!-- Blog -->
                    </a>
                </p>
            </div>

            <div class="col-12 col-sm-6 col-md-3 mt-4 text-center text-md-right">
                <hr class="d-none d-sm-block d-md-none" />
                <p class="text-dark font-montserrat-bold mb-2 ml-md-2 d-none d-md-block d-sm-block">
                    <strong>
            <!-- {{ "FOOTER.LetsSocialize" | translate }} -->

            עקבו אחרינו
          </strong>
                </p>
                <a href="#" class="tw">
                    <div class="social-circle">
                        <i class="fa fa-twitter"></i>
                    </div>
                </a>

                <a href="https://www.instagram.com/playfunction/?hl=en" target="_blank" class="ig">
                    <div class="social-circle">
                        <i class="fa fa-instagram"></i>
                    </div>
                </a>

                <a href="https://youtu.be/NSEu1arldDU" target="_blank" class="yt">
                    <div class="social-circle">
                        <i class="fa fa-youtube"></i>
                    </div>
                </a>

                <a href="https://www.facebook.com/pages/category/Toy-Store/Play-Function-164896593950153/" target="_blank" class="fb">
                    <div class="social-circle">
                        <i class="fa fa-facebook"></i>
                    </div>
                </a>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <hr />
            </div>

            <div class="col-md-6 col-12">
                <p class="mb-1 text-center text-md-right mt-md-1 font14">
                    <a href="#" class="text-black text-sm">

                        <!-- {{ "FOOTER.Copyright" | translate }} -->
                        זכויות יוצרים
                    </a>
                    |
                    <a href="#" class="text-black text-sm">
                        <!-- {{ "FOOTER.TermsAndConditions" | translate }} -->
                        תנאים
                    </a>
                    |
                    <a href="#" class="text-black text-sm">
                        <!-- {{ "FOOTER.PrivacyPolicy" | translate }} -->
                        מדיניות פרטיות
                    </a>
                    <!-- |
          <a href="#" class="text-black text-sm"
            >{{ "FOOTER.PoweredBy" | translate }}</a
          > -->
                </p>
            </div>

            <div class="col-md-6 col-12">
                <div class="d-flex pull-right mb-2">
                    <div class="">
                        <img src="assets/images/ssl.png" alt="ssl" class="mr-1 img-fluid" />
                    </div>
                    <div>
                        <img src="assets/images/pci.png" alt="pci" class="mr-1 img-fluid" />
                    </div>
                    <div>
                        <img src="assets/images/credit-guard.png" alt="Credit Guard" class="mr-1 img-fluid" />
                    </div>

                    <div>
                        <img src="assets/images/ms-logo.png" alt="Mastecard" class="mr-1 img-fluid" />
                    </div>

                    <div>
                        <img src="assets/images/amercian exp.png" alt="Americal Express" class="mr-1 img-fluid" />
                    </div>

                    <div>
                        <img src="assets/images/paypal.png" alt="paypal" class="mr-1 img-fluid" />
                    </div>
                </div>
                <p class="pull-right mb-1"></p>
            </div>
        </div>
    </div>
</section>