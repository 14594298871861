import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from "@angular/core";
import * as _ from "underscore";

@Component({
  selector: "app-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductListComponent implements OnInit {
  @Input() list;
  @Input() totalRecords;
  @Input() title;
  @Input() loading;
  productList;

  currentlyShowingFromProduct: number = 1;
  currentlyShowingToProduct: number;
  currentPage;
  pageSize: number = 10;
  fromPage = 1;
  toPage = 10;
  constructor() {}

  ngOnInit() {}
  ngOnChanges() {
    this.productList = this.list;
    this.getCountRecords(this.pageSize);
  }

  getCountRecords(size) {
    if (this.list) {
      this.pageSize = size;
      this.productList = this.list.slice(0, size);
      this.currentlyShowingToProduct = this.productList.length;
    }
  }

  getAllProducts() {
    this.pageSize = this.list.length;
    this.productList = this.list.slice(0, this.list.length);
    this.currentlyShowingToProduct = this.list.length;
  }

  pageChanged(e) {
    this.currentPage = e;
    const startIndex = (this.currentPage - 1) * +this.pageSize;
    const endIndex = Math.min(
      startIndex * 1 + (this.pageSize - 1) * 1,
      this.totalRecords - 1
    );
    this.productList = this.list.slice(startIndex, endIndex + 1);
    this.currentlyShowingFromProduct = startIndex + 1;
    this.currentlyShowingToProduct = endIndex + 1;
  }
}
