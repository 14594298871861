import { Injectable, Inject } from "@angular/core";
import { HttpWrapperService } from "./http-wrapper.service";
import { Subject, Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: "root"
})
export class UserService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpWrapperService,
    private cookieService: CookieService
  ) {}


  emailSubscription(email: any) {
   return this.http.get('email_subscription?email='+email)
  }

  private subject = new Subject<any>();

  setUserDetails(name) {
    this.subject.next({ fullName: name, isLoggedIn: this.checkLogin() });
  }

  getUserDetails(): Observable<any> {
    return this.subject.asObservable();
  }

  login(data) {
    const url = `user.login`;
    return this.http.post(url, data);
  }

  checkLogin() {
    //var allCookies = this.document.cookie.split(";");
    if (localStorage.getItem("sid")) return true;
    return false;
  }

  logOut(data) {
    return this.http.post(`user.logout`, data);
  }

  getCurrentUser() {
    const body = {
      userId: localStorage.getItem("sid"),
      fullName: localStorage.getItem("fullName"),
      userName: localStorage.getItem("userName")
    };
    return body;
  }

  forgotPassword(data) {
    const url = `user.forgot_password`;
    return this.http.post(url, data);
  }

  updatePassword(data) {
    return this.http.post(`update_password`, data);
  }

  registerUser(data) {
    const url = `user.registration`;
    return this.http.post(url, data);
  }

  getUserQuote() {
    return localStorage.getItem("quote_id");
  }

  getGuestSession() {
    return localStorage.getItem("guest_session_id");
  }

  getCurrentProposal() {
    return localStorage.getItem("proposal_id");
  }
  clearUserProposal() {
    return localStorage.setItem("proposal_id", null);
  }

  clearUserQuoteId() {
    return localStorage.setItem("quote_id", null);

  }

  getLocalWishList() {
    return localStorage.getItem("wishlist");
  }

  getPaymentStatus(token) {
    return this.http.get(
      `get_payment_status?token=${token}`
    );
  }
}
