<!-- Mobile and Tablet View -->

<div class="container-fluid d-block d-sm-block d-md-none mobile-padding">
    <div class="row fix-header">
        <div class="col-12 col-sm-12 text-md-center px-2">
            <div class="d-flex justify-content-between">
                <div class="mt-4_5 mr-sm-5 ml-2">
                    <svg class="icon icon-bars mr-2 ml-sm-0 mr-sm-4" (click)="toggleSideNav()" aria-hidden="true">
                      <use xlink:href="#019-menu"></use>
                  </svg>
                </div>

                <div class=" mr-sm-4 mr-md-3">
                    <a routerLink="/">
                        <img src="assets/images/logo_new.png" class="img-fluid logo" alt="logo" />
                    </a>
                </div>

                <div class="mt-4_5 mt-sm-4_5 mr-2">
                    <div class="">
                        <a class="text-dark" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                            <svg class="icon icon-search mx-1">
                              <use xlink:href="#007-search"></use>
                          </svg>
                        </a>
                        <!-- <app-cart-list></app-cart-list> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-first"></div>
        <div class="col-second text-md-center px-2">
            <app-cart-list></app-cart-list>
        </div>

        <div class="col-md-12">
            <div id="collapseExample" [ngbCollapse]="!isCollapsed">
                <div class="card border-0">
                    <div class="card-body p-1">


                        <div class="input-group w-300 search-box-container search-box-auto-complete">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-white border-darkblue border-right-0 search-box-prepend" id="search-icon"><a (click)="search()" class="mt-2"><span
                                          class="lnr lnr-magnifier fa-1x icon-color"></span></a>
                                </span>
                            </div>
                            <div class="ng-autocomplete">
                                <ng-autocomplete [data]="searchItem" [searchKeyword]="keyword" (selected)="selectEvent($event)" (inputChanged)="onChangeSearch($event)" (inputFocused)="onFocused($event)" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" [(ngModel)]="searchText"
                                    (keyup.enter)="selectEvent($event)" placeholder="הקלד שם או קוד מוצר לחיפוש">
                                </ng-autocomplete>

                                <ng-template #itemTemplate let-item>
                                    <a (click)="onProductDetail(item.item_code,item.item_name)" [innerHTML]="item.item_name"></a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Mobile and Tablet View -->

<!-- Desktop View -->

<div class="container mb-2 d-none d-sm-none d-md-block">
    <div class="row fix-header">
        <div class="col-md-4">
            <div class="d-flex justify-content-start" [ngClass]="{ hb_cart_info: currentLang === 'hb' }">
                <app-cart-list></app-cart-list>
            </div>
        </div>

        <div class="col-md-4 text-center">
            <a routerLink="/"><img src="assets/images/logo_new.png" class="img-fluid" alt="logo" />
            </a>
            <p class="text-center mb-0 text-red">מכוונים למשלוח ישיר בין 24-72 שעות</p>
        </div>
        <div class="col-4">
            <div class="input-group mt-md-4_5 w-300 search-box-container search-box-auto-complete mr-3">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-white border-darkblue border-right-0 search-box-prepend" id="search-icon"><a (click)="search()" class="mt-2"><span
                              class="lnr lnr-magnifier fa-1x icon-color"></span></a>
                    </span>
                </div>
                <div class="ng-autocomplete">
                    <ng-autocomplete [data]="searchItem" [searchKeyword]="keyword" (selected)="selectEvent($event)" (inputChanged)="onChangeSearch($event)" (inputFocused)="onFocused($event)" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" [(ngModel)]="searchText"
                        (keyup.enter)="search()" placeholder="הקלד שם או קוד מוצר לחיפוש">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                        <a (click)="onProductDetail(item.item_code,item.english_name)" [innerHTML]="item.item_name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <nav class="custom-navbar navbar navbar-light navbar-expand-lg mainmenu">
        <!--navbar-dark bg-primary -->
        <div class="container-fluidd">
            <div class="collapse navbar-collapse border-top border-bottom" id="main_nav">
                <ul class="navbar-nav main-menu" id="id22" *ngIf="menu">
                    <ng-container *ngFor="let item of menu; let i = index">

                        <li class="nav-item dropdown menu-area" [ngClass]="item.title === 'הורים' || item.title === 'מטפלים' || item.title === 'מוסדות חינוכיים' ? 'navbar-border text-lightblue f-weight-bold' : ''">
                            <a (mouseover)="mouseover(0)" class="dropdown-toggle font-montserrat-bold highlight" [class]="item.childClass" [ngClass]="{'text-red':item.title==='SALE', 'dropdown-toggle': item.level_2?.length > 0 ,
        'text-lightblue':item.title === 'הורים' ||  item.title === 'מטפלים' ||item.title === 'מוסדות חינוכיים'
          
      }" id="navbarDropdown" (click)="goToPage(item.title,item.category_id)" [routerLink]="
        item.has_child
          ? null
          : [
              '/products',
              item.title,
              {
                l1: item.title,
                c1: item.category_id
              }
            ]
      ">{{ item.title }} <i class="" aria-hidden="true"></i>
                              <span [ngClass]="
          item.has_child &&
          (item.title === 'הורים' ||
            item.title === 'מטפלים' ||
            item.title === 'מוסדות חינוכיים')
            ? 'fa fa-chevron-down mx-1 has-arrow1 '
            : item.has_child
            ? 'fa fa-chevron-down mx-1 has-arrow2'
            : ''
        "></span>
                          </a>
                            <ul class="dropdown-menu mega-area container" *ngIf="item?.has_child">


                                <div class="row megaArea-row">



                                    <div class="col-3 right-Mainmenu">
                                        <li (mouseover)="mouseover(j)"  (click)="l2?.has_child ? true:clicke()" *ngFor="let l2 of item.level_2; let j = index" class="has-megasubmenu open-to-left">
                                            <a style="font-size: medium;" class="dropdown-item" [routerLink]="
  l2.has_child
    ? null
    : [
        '/products',
        l2.category_id,
        {
      
          c1: item.category_id,
     
          c2: l2.category_id
        }                          ]
" [ngClass]="{ 'dropdown-toggle': item.level_2[ik]?.length > 0 }">
                                              {{ l2.title }}
                                          </a>
                                        </li>
                                    </div>

                                    <!--  -->
                                    <div class="col-9 left-Mainmenu">
                                        <div class="row justify-content-start ">


                                            
                                            <div>
                                                <button (click)="clicke()" [routerLink]=" [
                                                '/products',
                                                item.level_2[ik]?.category_id,
                                                {
                                             
                                                  c1: item.category_id,
                                             
                                                  c2: item.level_2[ik]?.category_id
                                                }                          ]
                                        " style="padding: 0 !important;margin: 0 !important;" class="rounded-pill btn text-uppercase btn-my-cart" >הצג הכל</button>
                                                <!-- <a >הצג הכל</a> -->
                                            </div>
                                            <div class="headers_loop col-4 text-height" *ngFor="let l3 of item.level_2[ik]?.level_3;let k = index" [style.display]="l3.has_child > 0 ? 'block' : 'none'">


                                                <div class="headers" *ngIf="l3.level_4?.length > 0">
                                                    <div  (click)="clicke()" class="column-header" >
                                                        <a class="mainHeading" [ngClass]="{ 'dropdown-toggle': l3.level_3?.length > 0 }" [routerLink]="
                      l3.has_child
                        ? [
                        '/products',
                        l3.category_id,
                        {
                            c1:item.category_id,
                            c2:item.level_2[ik].category_id,
                            c3:l3.category_id
                        }]
                        : [
                            '/products',
                            l3.category_id,
                            {
                          
                              c1: item.category_id,
                           
                              c2: item.level_2[ik].category_id,
                          
                              c3: l3.category_id
                            }  
                          ]
                    ">{{ l3.title }}</a>
                                                    </div>
                                                </div>

                                                <div class="hasChild" *ngFor="let l4 of l3?.level_4">

                                                    <a (click)="clicke()" class="main-subLink" [routerLink]="[
                '/products',
                l4.category_id,
                  {
                  
                    c1: item.category_id,
                  
                    c2: item.level_2[ik]?.category_id,
                  
                    c3: l3.category_id,
                 
                    c4: l4.category_id
                  } 
              ]">{{ l4.title }}</a>

                                                </div>




                                            </div>
                                            <div class="headers_loop col-4 text-height">
                                                <div class="headers" *ngFor="let l33 of item.level_2[ik]?.level_3">
                          

                                                    <a (click)="clicke()" class="noChild" *ngIf="l33.has_child == 0" [routerLink]="[
                    '/products',
                    l33.category_id,
                    {
                  
                      c1: item.category_id,
                  
                      c2: item.level_2[ik]?.category_id,
                   
                      c3: l33.category_id
                    }  
                  ]">{{l33.title}}</a>
                                                </div>
                                            </div>


                                            <!--  -->
                                            <!-- <div class="text-height" *ngFor="let l3 of item.level_2[ik].level_3;let k = index" [style.display]="l3.has_child > 0 ? 'none' : 'block'">
                                              <a class="noChild"> {{ l3.title }}</a>
                                          </div> -->
                                            <!--  -->
                                        </div>

                                    </div>
                                </div>

                            </ul>
                        </li>

                    </ng-container>
                </ul>
            </div>
        </div>
    </nav>
</div>