import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-product-filter",
  templateUrl: "./product-filter.component.html",
  styleUrls: ["./product-filter.component.css"]
})
export class ProductFilterComponent implements OnInit {
  @Input() filter;
  constructor() {}

  ngOnInit() {
  
  }
}
