<div class="row">
    <div class="col-10">
      <div class="text-md-right text-center">
        <p  class="mb-4">
          <strong>
            דיניות משלוחים
          </strong>

        </p>
        <p  class="mb-4">
            המוצרים השונים יסופקו באמצעות חברת שליחויות חיצונית. מוצרים שיסופקו יישלחו בהתאם
            לזמן האספקה הנהוג
            זמן אספקה מוגדר באתר לכל מוצר ולכל הזמנה הינו עד 7 ימי עסקים. הזמן הסביר של שליחות
            עד הבית הינו עד 3 ימי עסקים מלמחרת יום התשלום. בדר&quot;כ האספקה מתבצעת לאחר יום
            עסקים אחד בלבד ולרוב חברת השליחויות עומדת ביעד הזמן הסביר, אך יחד עם זאת מדובר
            בשירות חיצוני וייתכנו עיכובים מעבר לזמן הנקוב
            מובהר כי, במהלך מכירה מיוחדת/תקופות מבצעים, לרבות בלאק פריידיי, שופינג איי אל, וכיוצא
            בזה, זמן האספקה עשוי להתארך ולארוך 14 ימי עסקים.
           
        </p>
        <p>
            <strong>
                צרו איתנו קשר לגבי שירות איסוף עצמי מהסניף שלנו בירושלים
            ​
            </strong>
       
        </p>
        <p>
            <strong>
                (עלות שליחות עד הבית מסובסדת ל 19.90 ש&quot;ח)
            
            </strong>
        
        </p>
        <p>
            <strong>
                החברה רשאית לעדכן את תעריפי המשלוחים מעת לעת ובלא צורך בהודעה מוקדמת.
            </strong>
        </p>
      </div>
      </div>
      </div>
      
