export class Pagination{
    pageSize:number;
    page_no:number;
    scroll:number;
    isAll:boolean;
    constructor(obj:Pagination){
        this.pageSize=obj.pageSize;
    this.page_no=obj.page_no;
    this.scroll=obj.scroll;
    this.isAll=obj.isAll
    }
}