import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { WhyBuyFromUsComponent } from "./components/why-buy-from-us/why-buy-from-us.component";
import { BrandWorkComponent } from "./components/brand-work/brand-work.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import * as storeComponents from "./store-components";
import { NgxPaginationModule } from "ngx-pagination";
import * as productInfoComponents from "./product-info";
import { OwlModule } from "ngx-owl-carousel";
import { Ng5SliderModule } from "ng5-slider";
import { BreadCumComponent } from "app/layout/components/bread-cum/bread-cum.component";
import * as fromPipe from "./pipes";
import { CartListComponent } from "app/account/cart-list/cart-list.component";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import {
  SwiperModule,
  SwiperConfigInterface,
  SWIPER_CONFIG
} from "ngx-swiper-wrapper";
import { TranslateModule } from "@ngx-translate/core";
import { SvgSpriteIconComponent } from './components/svg-sprite-icon/svg-sprite-icon.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto"
};

const commonModule = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgbModule,
  NgxPaginationModule,
  MatProgressSpinnerModule,
  AutocompleteLibModule
];
const commonComponents = [
  WhyBuyFromUsComponent,
  BrandWorkComponent,
  SpinnerComponent,
  storeComponents.components,
  productInfoComponents.components,
  BreadCumComponent,
  CartListComponent,
  SvgSpriteIconComponent
];
@NgModule({
  imports: [
    ...commonModule,
    OwlModule,
    Ng5SliderModule,
    SwiperModule,
    TranslateModule
  ],
  exports: [
    ...commonModule,
    ...commonComponents,
    storeComponents.components,
    productInfoComponents.components,
    fromPipe.pipes,
    SwiperModule,
    TranslateModule,
    SvgSpriteIconComponent
  ],

  declarations: [commonComponents, fromPipe.pipes],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})
export class SharedModule {}
