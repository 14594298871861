import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GeneralService } from "@core/services/general.service";

@Component({
  selector: "app-meet-experts-details",
  templateUrl: "./meet-experts-details.component.html",
  styleUrls: ["./meet-experts-details.component.css"]
})
export class MeetExpertsDetailsComponent implements OnInit {
  expertName: any;
  expertData: any;
  homeBackExpert = "< חזרה לדף המומחים";
  constructor(
    private route: ActivatedRoute,
    private generalService: GeneralService
  ) {
    this.route.params.subscribe(param => {
   
      this.expertName = param.expert;
    });
  }

  ngOnInit() {
    this.generalService.getExpert(this.expertName).subscribe((res: any) => {
      this.expertData = res.message.data;
    });
  }
}
