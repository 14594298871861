import { Component, OnInit } from "@angular/core";
import { GeneralService } from "@core/services/general.service";

@Component({
  selector: "app-help-faq",
  templateUrl: "./help-faq.component.html",
  styleUrls: ["./help-faq.component.css"]
})
export class HelpFaqComponent implements OnInit {
  faqList: any;

  constructor(private generalService: GeneralService) {}

  ngOnInit() {
    this.generalService.getFaq().subscribe((res: any) => {
      this.faqList = res.message;
    });
  }
}
