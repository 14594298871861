import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { BASEURL } from "app/app.config";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import {
  DomSanitizer,
  SafeHtml,
  SafeStyle,
  SafeScript,
  SafeUrl,
  SafeResourceUrl,
} from "@angular/platform-browser";

@Component({
  selector: "app-item-media",
  templateUrl: "./item-media.component.html",
  styleUrls: ["./item-media.component.css"],
})
export class ItemMediaComponent implements OnInit {
  @Input() itemDetails;
  @Output() newItemEvent = new EventEmitter<any>();
  isDisplayVideo = false;
  currentVideoLink;
  baseURL = BASEURL;
  disabled;
  @ViewChild("zoomViewer") zoomViewer;
  zoomImage;
  itemImages = [];
  currentImage;
  isYoutubeVideo: boolean;
  isZoom: boolean;
  item_icons=[];
  constructor(private sanitizer: DomSanitizer) {}

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: "horizontal",
    slidesPerView: 4,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    pagination: false,
  };
  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.itemDetails) {
      //
      this.currentImage = this.itemDetails.image;
      this.itemImages = this.itemDetails.item_media;
      this.item_icons=this.itemDetails.item_icons;
      // this.itemImages.unshift({ thumbnail: this.itemDetails.image, video: "" });
      this.zoomImage = `${this.baseURL}${this.itemDetails.image}`;
      if (this.itemImages.length === 1) {
        // this.disabled = true;
      }
    }
  }

  clickImage(item) {
    if (item.type === "Image") {
      this.isDisplayVideo = false;
  
      this.currentImage = item.link;
      this.zoomImage = `${this.baseURL}${item.link}`;
    } else {
      this.isDisplayVideo = true;

      const file = item.link;
      let videoid = file.match(
        /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
      );
    

      if (!videoid) {
        this.isYoutubeVideo = false;
        this.currentVideoLink = this.sanitizer.bypassSecurityTrustUrl(
          `${this.baseURL}${item.link}`
        );
      } else {
        this.isYoutubeVideo = true;
        this.currentVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(
          item.link
        );
      }

  

      //
    }
  }

  public onMouseMove(e) {
 
    if (window.innerWidth >= 1170) {
      var data={
        isZoom:true,
        path:this.baseURL+this.currentImage
      }
      
      this.newItemEvent.emit(data);
      let image, offsetX, offsetY, x, y, zoomer;
      image = e.currentTarget;
      offsetX = e.offsetX;
      offsetY = e.offsetY;
      x = (offsetX / image.offsetWidth) * 100;
      y = (offsetY / image.offsetHeight) * 100;
	  if( this.zoomViewer !== undefined) {
		  if (this.zoomImage) {
			zoomer = this.zoomViewer.nativeElement.children[0];
		  }
	  }
      if (zoomer) {
        zoomer.style.backgroundPosition = x + "% " + y + "%";
        zoomer.style.display = "block";
        zoomer.style.height = image.height + "px";
        zoomer.style.width = image.width + "px";
      }
    }
  }

  // event when mouse clicked to zoom the image
  public onMouseLeave(event) {
    var data={
      isZoom:false,
      path:this.baseURL+this.currentImage
    }
    this.newItemEvent.emit(data);
    // this.isZoom=false;
	if( this.zoomViewer !== undefined) {
    	this.zoomViewer.nativeElement.children[0].style.display = "none";
	}
  }

  public onIndexChange(index: number): void {}

  public onSwiperEvent(event: string): void {}
}
