<nav id="sidebar" style="height: 100%;overflow: auto;" class="navbar-light bg-light" [ngClass]="{ hidden: sideNavService.hideSideNav }">
  <ng-container *ngIf="level === 'zero'">
    <ul class="navbar-nav">
      <h6 class="text-lightblue ml-3 mb-4">All Categories</h6>
      <li class="nav-item nav-item-dark p-3" *ngFor="let item of menu; let i = index"
        (click)="findLevel('one', i, item.title,item.has_child,item?.category_id)" [ngClass]="
          item.title === 'SALE'
            ? 'text-red'
            : '' ||
              item.title === 'הורים' ||
              item.title === 'מטפלים' ||
              item.title === 'מוסדות חינוכיים'
            ? 'bg-sidebar-nav f-weight-bold'
            : ''
        ">
        {{ item.title }}

        <span [ngClass]="
            item.has_child &&
            (item.title === 'הורים' ||
              item.title === 'מטפלים' ||
              item.title === 'מוסדות חינוכיים')
              ? 'fa fa-chevron-right mt-1 has-arrow1 float-left'
              : item.has_child
              ? 'fa fa-chevron-right mt-1 has-arrow2 float-left'
              : ''
          "></span>
      </li>
    </ul>
  </ng-container>

  <ng-container *ngIf="level === 'one'">
    <ul class="navbar-nav">
      <h6 class="text-lightblue ml-3" (click)="findAllCategoriesLevel('zero')">
        All Categories
      </h6>
      <span class="small text-secondary ml-3 mb-4"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
        {{ levelOneTitle }}</span>
      <li class="nav-item nav-item-dark p-3" *ngFor="let data of menu[levelOneIndex].level_2; let i = index"
        (click)="findLevel('two', i, data.title,data.has_child,data?.category_id)"
        [routerLink]="data.has_child == 0 ? ['/products',data?.category_id,{c1:levelOnecategory,c2:levelTwocategory}] : ''">
        {{ data.title }}
      </li>
    </ul>
  </ng-container>

  <ng-container *ngIf="level === 'two'">
    <ul class="navbar-nav">
      <h6 class="text-lightblue ml-3" (click)="findAllCategoriesLevel('zero')">
        All Categories
      </h6>
      <span class="small text-secondary ml-3 mb-4">
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        {{ levelTwoTitle }}
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        {{ levelOneTitle }}
      </span>
      <li class="nav-item nav-item-dark p-3" *ngFor="
          let data of menu[levelOneIndex].level_2[levelTwoIndex].level_3;
          let i = index
        " (click)="findLevel('three', i, data.title,data.has_child,data.category_id)"
        [routerLink]="data.has_child == 0 ? ['/products',data.category_id,{c1:levelOnecategory,c2:levelTwocategory,c3:data.category_id}] : ''">
        {{ data.title }}
      </li>
    </ul>  
  </ng-container>

  <ng-container *ngIf="level === 'three'">
    <ul class="navbar-nav">
      <h6 class="text-lightblue ml-3" (click)="findAllCategoriesLevel('zero')">
        All Categories
      </h6>
      <span class="small text-secondary ml-3 mb-4">
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        {{ levelThreeTitle }}
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        {{ levelTwoTitle }}
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        {{ levelOneTitle }}
      </span>
      <li class="nav-item nav-item-dark p-3" *ngFor="
          let data of menu[levelOneIndex].level_2[levelTwoIndex].level_3[
            levelThreeIndex
          ].level_4
        ">
        <div (click)="findLevel('three', i, data.title,data.has_child,data.category_id)"
          [routerLink]="data.has_child == 0 ? ['/products',data.category_id,{c1:levelOnecategory,c2:levelTwocategory,c3:levelThreecategory,c4:data.category_id}] : ''">
          {{
          data.title }}</div>
      </li>
    </ul>
  </ng-container>


  <!-- <ul class="navbar-nav d-none">
    <li class="nav-item nav-item-blue active">
      <a
        class="nav-link font-montserrat-bold text-white"
        href="javascript:void(0)"
        (click)="goToPage('Therapist')"
        >מטפלים
        <i class="fa fa-chevron-right float-left mt-1" aria-hidden="true"></i
      ></a>
    </li>
    <li class="nav-item nav-item-blue">
      <a
        class="nav-link font-montserrat-bold text-white"
        href="javascript:void(0)"
        (click)="goToPage('Parents')"
        >הורים
        <i class="fa fa-chevron-right float-left mt-1" aria-hidden="true"></i
      ></a>
    </li>
    <li class="nav-item nav-item-blue">
      <a
        class="nav-link font-montserrat-bold text-white"
        href="javascript:void(0)"
        (click)="goToPage('School')"
        >מוסדות חינוכיים
        <i class="fa fa-chevron-right float-left mt-1" aria-hidden="true"></i
      ></a>
    </li>

    <li class="nav-item nav-item-white">
      <a
        class="nav-link font-montserrat-bold"
        href="javascript:void(0)"
        (click)="goToPage('Baby (0-12months)')"
        >תינוקות 0-3</a
      >
    </li>

    <li class="nav-item nav-item-white">
      <a
        class="nav-link font-montserrat-bold"
        href="javascript:void(0)"
        (click)="goToPage('Toys')"
        >משחקים</a
      >
    </li>

    <li class="nav-item nav-item-white">
      <a
        class="nav-link font-montserrat-bold"
        href="javascript:void(0)"
        (click)="goToPage('Outdoor Toys')"
        >מתקני חצר</a
      >
    </li>

    <li class="nav-item nav-item-white">
      <a
        class="nav-link font-montserrat-bold"
        href="javascript:void(0)"
        (click)="goToPage('Furniture')"
        >ריהוט</a
      >
    </li>
    <li class="nav-item nav-item-white">
      <a
        class="nav-link font-montserrat-bold text-red"
        href="javascript:void(0)"
        (click)="goToPage('Offers & Sale')"
        >SALE</a
      >
    </li>
  </ul> -->

  <!-- <ul class="navbar-nav">
    <ng-container *ngFor="let item of menu; let i = index">
      <li class="nav-item nav-item-blue active" [ngClass]="
              item.title === 'הורים' ||
              item.title === 'מטפלים' ||
              item.title === 'מוסדות חינוכיים'
                ? 'bg-sidebar-nav f-weight-bold'
                : ''
            ">
        <a class="nav-link font-montserrat-bold " [ngClass]="item.childClass" id="navbarDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)=goToPage(item.title) [routerLink]="
        item.has_child
          ? null
          : [
              '/products',
              item.title,
              {
                l1: item.title
              }
            ]
      ">{{ item.title }}
          <span [ngClass]="
      item.has_child &&
      (item.title === 'הורים' ||
        item.title === 'מטפלים' ||
        item.title === 'מוסדות חינוכיים')
        ? 'fa fa-chevron-right mt-1 has-arrow1 float-left'
        : item.has_child
        ? 'fa fa-chevron-right mt-1 has-arrow2 float-left'
        : ''
    "></span>
        </a>
      </li>
    </ng-container>

  </ul> -->
</nav>