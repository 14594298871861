import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  Renderer2,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoginComponent } from "app/layout/containers/auth/login/login.component";
import { UserService } from "@core/services/user.service";
import { Subscription } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  subscription: Subscription;
  isUserLoggedIn: boolean;
  userData: { userId: string; fullName: string; userName: string };
  userDisplayName = this.userService.getCurrentUser().fullName;
  currentLanguage;
  currentRoute: any;
  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private cookieService: CookieService,
    private router: Router,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    this.translate.addLangs(["hb","en"]);

    if (localStorage.getItem("language")) {
      localStorage.setItem("language", "hb");
      this.translate.setDefaultLang(localStorage.getItem("language"));
      this.translate.use(localStorage.getItem("language"));
      this.currentLanguage = localStorage.getItem("language");
    } else {
      const browserLang = "hb"; //translate.getBrowserLang();
      this.currentLanguage = browserLang;
      translate.setDefaultLang("hb");
      translate.use(browserLang.match(/en|hb/) ? browserLang : "hb");
      localStorage.setItem("language", 'hb');
    }

    if (this.currentLanguage === "hb") {
      this.renderer.addClass(this.document.body, "rtl");
    } else {
      this.renderer.removeClass(this.document.body, "rtl");
    }

    this.subscription = this.userService
      .getUserDetails()
      .subscribe((message) => {
        this.isUserLoggedIn = message.isLoggedIn;
        this.userDisplayName = message.fullName;
      });
  }

  switchLanguage(language: string) {
    // this.translate.use(language);
    this.currentLanguage = language;
    this.translate.setDefaultLang(this.currentLanguage);
    this.translate.use(this.currentLanguage);
    localStorage.setItem("language", this.currentLanguage);
    if (language === "hb") {
      this.renderer.addClass(this.document.body, "rtl");
    } else {
      this.renderer.removeClass(this.document.body, "rtl");
    }

    // if (localStorage.getItem("language")) {
  

    // } else {
    //   this.translate.setDefaultLang("en");
    //   this.translate.use("en");
    //   localStorage.setItem("language", "en");
    // }
  }

  ngOnInit() {
    this.currentRoute = this.router.url;
    this.userData = this.userService.getCurrentUser();
    this.isUserLoggedIn = this.userService.checkLogin();
    this.switchLanguage('hb');
  }

  onRouteing(val){
    this.router.navigate(['/account/proposal-list']);
  }

  open() {
    const modalRef = this.modalService.open(LoginComponent);
    modalRef.componentInstance.type = "main";
    modalRef.componentInstance.routing = this.currentRoute;
  }
  
  logout() {
    const formData = new FormData();
    formData.append("usr", this.userData.userName);
    this.userService.logOut(formData).subscribe(
      (res: any) => {
        this.router.navigate["/"];
        localStorage.clear();
        this.cookieService.deleteAll();
        alert(res.message);
        window.location.href = "";
      },
      (error) => {
        this.router.navigate["/"];
        localStorage.clear();
        window.location.href = "";
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
