<div class="row">
  <div class="col-md-12 mt-4 mb-4">
    <h2 class="text-center font-montserrat-regular">
      <!-- {{ "LANDING.Brands" | translate }} -->
      מותגים מובילים 
    </h2>
  </div>
  <div class="col-md-12">
    <section class="customer-logos">
      <owl-carousel #owlElement [options]="carouselOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
        <div class="item" *ngFor="let image of images; let i = index">
          <img class="slide-image" src="{{ image.image }}" />
          <div class="slide-text"></div>
        </div>
      </owl-carousel>
    </section>
  </div>
</div>