import { Injectable } from "@angular/core";
import { ProductService } from "./product.service";
import { UserService } from "./user.service";
import { AlertService } from "./alert.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class CartService {
  constructor(
    private productService: ProductService,
    private userService: UserService,
    private alertService: AlertService,
    private router: Router
  ) { }

  addToCart(productDetails, type, totQty = null) {
    const body = {
      item_code: productDetails.item_code,
      qty: totQty ? totQty : 1,
    };


    const frmData = new FormData();
    frmData.append("items", JSON.stringify(body));
    if (type === "proposal") {
      frmData.append("is_proposal", "1");
      const currentProposal = this.userService.getCurrentProposal();
      if (currentProposal && currentProposal !== "null") {
        // if (this.userService.checkLogin()) {
          frmData.append("quote_id", currentProposal);
        // }else{
        //   const guestSessionId = this.userService.getGuestSession();
        //   frmData.append("guest_session_id", guestSessionId);
        // }
        this.productService.updateCart(frmData).subscribe((res) => {
          this.alertService.success("המוצר נוסף בהצלחה להצעת המחיר ");//Added To Proposal
          this.productService.updateProposal({ data: res, isCheck: true });
        });
      } else {
        if (this.userService.checkLogin()) {
        }else{
          const guestSessionId = this.userService.getGuestSession();
          frmData.append("guest_session_id", guestSessionId);
        }
        this.productService.addToCart(frmData).subscribe((res: any) => {
          localStorage.setItem("proposal_id", res.message.quote_id);
          this.alertService.success("המוצר נוסף בהצלחה להצעת המחיר "); //Added To Proposal
          this.productService.updateProposal({ data: res, isCheck: true });
        });
      }
    } else {
      const currentQuote = this.userService.getUserQuote();
      if (currentQuote && currentQuote !== "null") {
        if (this.userService.checkLogin()) {
          frmData.append("quote_id", currentQuote);
        }else{
          // if(this.userService.getUserQuote()){
            frmData.append("quote_id", currentQuote);
          // }else{
          //   const guestSessionId = this.userService.getGuestSession();
          //   frmData.append("guest_session_id", guestSessionId);
          // }
        }
        this.productService.updateCart(frmData).subscribe((res) => {
          this.alertService.success("המוצר נוסף בהצלחה לסל הקניות שלך"); //Added To Cart
          this.productService.updateListFn({ data: res, isCheck: true });
        });
      } else {
        if (this.userService.checkLogin()) {
        }else{
          const guestSessionId = this.userService.getGuestSession();
          frmData.append("guest_session_id", guestSessionId);
        }
        this.productService.addToCart(frmData).subscribe((res: any) => {
          localStorage.setItem("quote_id", res.message.quote_id);
          this.alertService.success("המוצר נוסף בהצלחה לסל הקניות שלך"); //Added To Cart
          this.productService.updateListFn({ data: res, isCheck: true });
        });
      }
    }
  }

  updateCartQty(
    updateType,
    updateQty,
    cartItem,
    item,
    currentIndex,
    type = null,
    actionType = null
  ) {
    // const newQty = updateType
    //   ? updateQty + 1
    //   : updateQty > 1
    //   ? updateQty - 1
    //   : 1;
    let newQty = updateType == 0 ? -1 : 1;
    // cartItem.items[currentIndex].qty = item.qty+ newQty;
    const body = {
      item_code: item.item_code,
      qty: newQty,
    };

    const frmData = new FormData();
    frmData.append("items", JSON.stringify(body));
    if (type === "proposal") {
      if (this.userService.checkLogin()) {
        const currentQuote =
        actionType === "edit"
          ? cartItem.quote_id
          : this.userService.getCurrentProposal();
      frmData.append("quote_id", currentQuote);
      }else{
        if(this.userService.getUserQuote()){
          const currentQuote =
          actionType === "edit"
            ? cartItem.quote_id
            : this.userService.getCurrentProposal();
        frmData.append("quote_id", currentQuote);
        }
        // const guestSessionId = this.userService.getGuestSession();
        // frmData.append("guest_session_id", guestSessionId);
      }
    } else {
      // if (this.userService.checkLogin()) {
        const currentQuote = this.userService.getUserQuote();
        frmData.append("quote_id", currentQuote);
      // }else{
      //   const guestSessionId = this.userService.getGuestSession();
      //   frmData.append("guest_session_id", guestSessionId);
      // }
    }
    // if (newQty >= 1) {
    this.productService.updateCart(frmData).subscribe((res) => {
      if (type === "proposal") {
        this.alertService.success("המוצר נוסף בהצלחה להצעת המחיר "); // Added To Proposal
        this.productService.updateProposal({ data: res, isCheck: true });
      } else {
        this.alertService.success("המוצר נוסף בהצלחה לסל הקניות שלך"); // Added To cart
        this.productService.updateListFn({ data: res, isCheck: true });
      }
    });
    // }
  }

  removeCart(cartItem, item, index, type) {
    const formData = new FormData();
    if (type === "Proposal") {
      const currentQuote = this.userService.getCurrentProposal();
      formData.append("quote_id", currentQuote);
    } else {
      const currentQuote = this.userService.getUserQuote();
      formData.append("quote_id", currentQuote);
    }
    formData.append("item_code", item.item_code);
    this.productService.removeCart(formData).subscribe((res) => {
      this.alertService.success("המוצר הוסר מסל הקניות"); // Removed From Cart
      // this.router.navigate([''])
      cartItem.items.splice(index, 1);
      if (type === "Proposal") {
        if (cartItem.items.length <= 0) {
          // this.userService.clearUserQuoteId();
          this.productService.updateProposal({ data: res, isCheck: false });
        } else {
          this.productService.updateProposal({ data: res, isCheck: true });
        }
      }else{
        if (cartItem.items.length <= 0) {
          // this.userService.clearUserQuoteId();
          this.productService.updateListFn({ data: res, isCheck: false });
        } else {
          this.productService.updateListFn({ data: res, isCheck: true });
        }
      }
    });
  }


}
