import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { CategoryService } from "@core/services/category.service";
import { AccountService } from "@core/services/account.service";
/*import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";*/
import { Location } from '@angular/common'
import { json } from "express";
import { Pagination } from "@shared/model/pagination.model";

@Component({
  selector: "app-item-list",
  templateUrl: "./item-list.component.html",
  styleUrls: ["./item-list.component.scss"]
})
export class ItemListComponent implements OnInit {
  isback: boolean=false;
  scroll: number;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    let paginationArray: Array<any> = JSON.parse(localStorage.getItem('paginationArray'));
    let currentPage=paginationArray.pop()
    
    if(currentPage==undefined){
    this.router.navigate(['/'])
    }
    localStorage.setItem('paginationArray',JSON.stringify(paginationArray));
    paginationArray = JSON.parse(localStorage.getItem('paginationArray'));
    this.currentPage = paginationArray[paginationArray.length-1].page_no;
    this.config.currentPage =  this.currentPage;
    this.pageSize=paginationArray[paginationArray.length-1].pageSize
    this.sendPage(this.currentPage);
    this.currentPage_num=this.currentPage;
    const startIndex = (this.currentPage - 1) * +this.pageSize;
    const endIndex = Math.min(
      startIndex * 1 + (this.pageSize - 1) * 1,
      this.totalRecords - 1
    );
    this.productList = this.list.slice(startIndex, endIndex + 1);

  }
  @ViewChild('paginating') paginating: ElementRef<HTMLElement>;
  @Input() list;
  @Input() totalRecords;
  @Input() title;
  @Input() loading;
  @Output() scrollTo: EventEmitter<any> = new EventEmitter();
  @Output() itemType = new EventEmitter();
  pagination: Pagination = new Pagination({ page_no: 1, pageSize: 10, scroll: 0, isAll: false })
  productList;

  itemTypeArr = [
    {
      value: "new_item",
      label: "חדש" //New In
    },
    { value: "award_wining", label: "משחקים זוכי פרסים" }, // Award Winning
    { value: "best_seller", label: "רבי מכר" }, // Best Seller
    { value: "sales_item", label: "במבצע" } // On Sales
  ];

  currentlyShowingFromProduct: number = 1;
  currentlyShowingToProduct: number = 12;
  currentPage = 1;
  currentPage_num = 1;
  pageSize: number = 12;
  fromPage = 1;
  toPage = 10;
  viewType: string = "grid";
  cPage = 1;
  config = {
    itemsPerPage: 12,
    currentPage: 1,
    totalItems: 100
  };
  pageSize1: any;
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private category: CategoryService,
    private accountService: AccountService) {
  }

  onItems(val) {

  }

  ngAfterViewInit() {  /*Added By Ashwin - 31/5/2022*/
    this.route.params.subscribe((param) => {
      if (!param.page_num) {
        if (this.pageSize == this.list?.length) {
          this.pageSize = 12;
        }
     

        let paginationArray: Array<any> = JSON.parse(localStorage.getItem('paginationArray'));
     let pageCurrent:Pagination=paginationArray.pop();
        // paginationArray.push(new Pagination({isAll:false,page_no:1,pageSize:12,scroll:0}))
     
        localStorage.setItem('paginationArray',JSON.stringify(paginationArray));
        this.pageSize =pageCurrent==undefined ? 12:pageCurrent.pageSize;
        this.currentPage =pageCurrent==undefined ? 1:pageCurrent.page_no;
        this.currentPage_num =pageCurrent==undefined ? 1:pageCurrent.page_no;
        this.config.currentPage = pageCurrent==undefined ? 1:pageCurrent.page_no;
      
        this.sendPage(this.currentPage);
        const startIndex = (this.currentPage - 1) * +this.pageSize;
        const endIndex = Math.min(
          startIndex * 1 + (this.pageSize - 1) * 1,
          this.totalRecords - 1
        );
        this.productList = this.list.slice(startIndex, endIndex + 1);

if(pageCurrent!=undefined){
  this.scroll=pageCurrent.scroll;
  
  this.scrollTo.emit(pageCurrent==undefined?0: pageCurrent.scroll)
//window.scrollTo(0, pageCurrent.scroll);
}else{
  this.scroll=0;
  paginationArray.push(new Pagination({isAll:false,page_no:1,pageSize:12,scroll:0}))
  localStorage.setItem('paginationArray',JSON.stringify(paginationArray));
}
        // this.location.go(this.router.url+";page_num="+this.currentPage+";scroll=0");
      }
      
      if (param.page_num) {
        if (param.page_num == 'All') {
          this.getAllProductsOnBackButton();

        } else {
          if (this.pageSize == this.list?.length) {
            this.pageSize = 12;
          }
          let paramsObject = { ...param.keys, ...param };     
          this.currentPage = param.page_num;
          this.currentPage_num = param.page_num;
          this.config.currentPage = param.page_num;
          this.sendPage(this.currentPage);
          const startIndex = (this.currentPage - 1) * +this.pageSize;
          const endIndex = Math.min(
            startIndex * 1 + (this.pageSize - 1) * 1,
            this.totalRecords - 1
          );
          this.productList = this.list.slice(startIndex, endIndex + 1);
          
        }
      }
    });
  }

  ngOnInit() {

    if (localStorage.getItem('totalList') != null && localStorage.getItem('totalList') != undefined) {
      this.list = JSON.parse(localStorage.getItem("totalList"));
    }

    if (localStorage.getItem('pageChange') != null && localStorage.getItem('pageChange') != undefined) {
      this.currentPage = +JSON.parse(localStorage.getItem('pageChange'));

      this.totalRecords = +JSON.parse(localStorage.getItem('totalRecords'));
      this.pageSize = JSON.parse(localStorage.getItem('pageSize'));
      let list = JSON.parse(localStorage.getItem('productList'));

      // let el: HTMLElement = this.paginating.nativeElement;
      // el.click();
      this.pageChanged('e', +this.pageSize, +this.currentPage, list);
      window.scroll(0, 0)
      setInterval(() => localStorage.removeItem('pageChange'), 3000);
      localStorage.removeItem('totalRecords');
      localStorage.removeItem('pageSize');
      localStorage.removeItem('productList');
    }
    this.productList = this.list;
    this.pageSize = JSON.parse(localStorage.getItem('pageSize'));
    this.pageSize1 = JSON.parse(localStorage.getItem('pageSize'));
    this.currentPage = +JSON.parse(localStorage.getItem('pageChange'));
    localStorage.removeItem('pageSize');
    this.getCountRecords(this.pageSize);
  }

  getCategory() {
    let body

    if (this.title === 'Community center') {
      body = {
        community_center: true
      }
    }

    if (this.title === 'Gift ideas') {
      body = {
        gift_ideas: true
      }
    }

    this.category.getAllCategoryItemA(body).subscribe((res => {


    }))
  }


  ngOnChanges() {

    this.productList = this.list;
  }

  getCountRecords(size) {
    
    if (this.pageSize1 != size) {
      this.currentPage_num = 1;
      this.pageSize1 = size;
      let paginationArray: Array<any> = JSON.parse(localStorage.getItem('paginationArray'));
      paginationArray.push(new Pagination({isAll:false,page_no:1,pageSize:size,scroll:0}))
        localStorage.setItem('paginationArray',JSON.stringify(paginationArray));

    }
    window.scroll(0,this.scroll)
    // let url= this.router.url.split(';');
    // let url1="";
    // let j=-1;
    // let z=-1;
    // let isFound=false;
    // for(let k=0; k < url.length;k++){
    //  if(url[k].includes('page_num=Al')){
    //   j=k;
    //   let isFound=true;
    //  }
    //  if(url[k].includes("pageSize=")){

    //  }
    // }

    // for(let i=0;i<url.length;i++){
    //   if(i==j || z==i){

    //   }else{
    //     url1=url1==""? url[i]:url1+";"+url[i];
    //   }

    // }

    // this.pageSize =this.pageSize==null ? 1:this.pageSize
    //   url1=url1+";pageSize="+this.pageSize;


    // this.location.go(url1)

    localStorage.setItem('pageSize', JSON.stringify(size));
    if (this.list) {
      this.pageSize = size;
      this.productList = this.productList.slice(0, size);
    }
  }

  getAllProducts() {
    this.currentPage_num = 1; /*Added By Ashwin - 24/6/2022*/
    this.pageSize = this.list.length;
    this.productList = this.list.slice(0, this.list.length);
    //this.currentPage_num=1;
    // this.location.go(this.router.url+";page_num=All;scroll=0");

    let isdebuger = false;
    let j = -1;
    let q = -1;
    //  let url= this.router.url.split(';');
    //  for(let i=0;i<url.length;i++){
    // if(url[i].includes('page_num')){
    //   isdebuger=true;
    //   j=i;
    // }
    //  }
    //   let url1="";
    //  for(let k=0; k < url.length;k++){
    //   if(k==j || q==k){
    //   }else{
    //     url1= url1 == "" ? url[k]:url1+";"+url[k];
    //   }
    //  }
    // 
    //  if(isdebuger){
    //   this.router.navigateByUrl(url1+";page_num=All;scroll=0");

    //  }else{
    //   this.router.navigateByUrl(url1+";page_num=All;scroll=0");
    //  }


    //this.location.go(this.router.url+";page_num=All");
  }

  getAllProductsOnBackButton() {

    this.currentPage_num = 1; /*Added By Ashwin - 24/6/2022*/
    this.pageSize = this.list.length;
    this.productList = this.list.slice(0, this.list.length);
    //santosh

    localStorage.setItem('totalList', JSON.stringify(this.productList));
  }

  pageChanged(event, pz, p, list?) {
    console.log("santosh zanak" + JSON.stringify(new Pagination({ page_no: Number(p), isAll: false, scroll: 0, pageSize: pz })));
    let paginationArray: Array<any> = JSON.parse(localStorage.getItem('paginationArray'))
    console.log(paginationArray)
    paginationArray.push(new Pagination({ page_no: Number(p), isAll: false, scroll: 0, pageSize: pz }));
    localStorage.setItem("paginationArray", JSON.stringify(paginationArray))
    this.location.go(this.router.url);
    this.currentPage = +p;
    this.sendPage(p);
    const startIndex = (p - 1) * +pz;
    const endIndex = Math.min(
      startIndex * 1 + (pz - 1) * 1,
      this.totalRecords - 1
    );

    if (list) {
      // this.list = list;

    }
    if (!(localStorage.getItem('totalList') != null && localStorage.getItem('totalList') != undefined)) {
      localStorage.setItem('totalList', JSON.stringify(this.list));
    }
    this.productList = this.list.slice(startIndex, endIndex + 1);
    this.currentlyShowingFromProduct = startIndex + 1;
    this.currentlyShowingToProduct = endIndex + 1;

    // return true;

    /*Added By Ashwin - 31/5/2022*/
    //const url = new URL(this.router.url);
    //url.searchParams.set('page', this.currentPage+"");
    //const state = { 'page_num': this.currentPage, 'user_id': 5 }
    //window.history.pushState(state, 'Page :'+this.currentPage, this.router.url+"/?page="+this.currentPage+"");
    this.currentPage_num = this.currentPage;
    this.config.currentPage = this.currentPage;
    this.config.totalItems = this.totalRecords;
    /*if(this.currentPage > 1) {
  	
      this.location.go(this.router.url+";page_num="+this.currentPage);
    }*/

    this.route.params['page_num'] = this.currentPage;
    // let isdebuger=false;  
    // let j=-1;
    // let q=-1;
    //  let url= this.router.url.split(';');
    //  for(let i=0;i<url.length;i++){
    // if(url[i].includes('page_num')){
    //   isdebuger=true;
    //   j=i;
    // }
    // if(url[i].includes('scroll')){
    //    q=i;
    //    isdebuger=true;
    // }
    //  }
    //   let url1="";

    //  for(let k=0; k < url.length;k++){
    //   if(k==j || q==k){
    //   }else{
    //     url1=url1==""? url1+";"+url[k]:url1+url[k];
    //   }
    //  }

    //  if(isdebuger){
    //   this.location.go(url1+";page_num="+this.currentPage+";scroll=0");
    //  }else{
    //   this.location.go(this.router.url+";page_num="+this.currentPage);
    //  }


  }

  sendPage(pageNo) {
    this.accountService.updatePage(pageNo);
  }

  onChangeType(e) {
    this.itemType.emit(e);
  }
  viewTypeFn(type) {
    this.viewType = type === 1 ? "list" : "grid";

  }
}
